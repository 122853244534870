import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AddFleetModal = ({ isOpen, onClose, onAddFleet }) => {
  const [fleetName, setFleetName] = useState("");
  const [attributes, setAttributes] = useState([{ name: "", value: "" }]);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleFleetNameChange = (e) => {
    setFleetName(e.target.value);
  };

  const handleAttributeChange = (index, field, value) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[index][field] = value;
    setAttributes(updatedAttributes);
  };

  const addAttribute = () => {
    setAttributes([...attributes, { name: "", value: "" }]);
  };

  const removeAttribute = (index) => {
    const updatedAttributes = attributes.filter((_, i) => i !== index);
    setAttributes(updatedAttributes);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!fleetName.trim()) {
      newErrors.fleetName = "Fleet Name is required";
    }
    attributes.forEach((attr, index) => {
      if (attr.name.trim() && !attr.value.trim()) {
        newErrors[`value-${index}`] = "Value is required when name is provided";
      }
      if (!attr.name.trim() && attr.value.trim()) {
        newErrors[`name-${index}`] = "Name is required when value is provided";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddFleet = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = {
        fleetName,
        attributes: Object.fromEntries(
          attributes
            .filter((attr) => attr.name.trim() && attr.value.trim())
            .map((attr) => [attr.name, attr.value]),
        ),
      };

      try {
        const result = await onAddFleet(formData);
        if (result.success) {
          setFleetName("");
          setAttributes([{ name: "", value: "" }]);
          setErrors({});
          setErrorMessage("");
          onClose();
          // Redirect to the new fleet's page
          const fleetId = JSON.parse(result.fleetId).replace(/^"(.*)"$/, "$1");
          navigate(`/fleets/${fleetId}`);
        } else {
          setErrorMessage(result.error.message);
        }
      } catch (error) {
        setErrorMessage(
          "An error occurred while adding the fleet. Please try again.",
        );
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div
        className="bg-white rounded-lg w-full max-w-2xl relative z-50"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="p-6 flex flex-col h-full">
          <h2 className="text-2xl font-bold mb-6" id="modal-headline">
            Add Fleet
          </h2>
          <form onSubmit={handleAddFleet} className="flex flex-col flex-grow">
            <div className="space-y-6 flex-grow">
              <div>
                <label
                  htmlFor="fleetName"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Fleet Name
                </label>
                <input
                  type="text"
                  id="fleetName"
                  name="fleetName"
                  value={fleetName}
                  onChange={handleFleetNameChange}
                  className={`w-full border rounded-md px-3 py-2 ${errors.fleetName ? "border-red-500" : "border-gray-300"}`}
                />
                {errors.fleetName && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.fleetName}
                  </p>
                )}
              </div>

              <div className="bg-gray-50 p-4 rounded-md flex-grow">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Attributes
                </label>
                <div className="min-h-[100px] max-h-60 overflow-y-auto">
                  {attributes.map((attr, index) => (
                    <div key={index} className="flex space-x-2 mb-2">
                      <input
                        type="text"
                        placeholder="Attribute Name"
                        value={attr.name}
                        onChange={(e) =>
                          handleAttributeChange(index, "name", e.target.value)
                        }
                        className={`flex-1 border rounded-md px-3 py-2 ${errors[`name-${index}`] ? "border-red-500" : "border-gray-300"}`}
                      />
                      <input
                        type="text"
                        placeholder="Attribute Value"
                        value={attr.value}
                        onChange={(e) =>
                          handleAttributeChange(index, "value", e.target.value)
                        }
                        className={`flex-1 border rounded-md px-3 py-2 ${errors[`value-${index}`] ? "border-red-500" : "border-gray-300"}`}
                      />
                      <button
                        type="button"
                        onClick={() => removeAttribute(index)}
                        className="px-2 py-2 text-gray-500 hover:text-red-500"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  ))}
                </div>
                {errors[`name-${attributes.length - 1}`] && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors[`name-${attributes.length - 1}`]}
                  </p>
                )}
                {errors[`value-${attributes.length - 1}`] && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors[`value-${attributes.length - 1}`]}
                  </p>
                )}
                <button
                  type="button"
                  onClick={addAttribute}
                  className="mt-2 px-4 py-2 bg-gray-200 text-gray-600 rounded-md hover:bg-gray-300 flex items-center"
                >
                  <FaPlus className="mr-2" /> Add Attribute
                </button>
              </div>
            </div>

            {errorMessage && (
              <p className="text-red-500 text-sm mt-4 mb-4">{errorMessage}</p>
            )}

            <div className="mt-6 flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 rounded-md border border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 rounded-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Add Fleet
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddFleetModal;
