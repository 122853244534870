import {
  type DatapointMap,
  convertToTimeseries,
  datapointsToMap,
  generateIdealBinSize,
  useDataApi,
} from "api/data";
import { type SiteResponse, usePlacesApi } from "api/ingestion/places.ts";
import {
  type SimulationResponse,
  useSimulationsApi,
} from "api/ingestion/simulations.ts";
import { useThingsApi } from "api/ingestion/things.ts";
import {
  DeviceDetail,
  EnergyCard,
  NetworkMap,
  SkyportsChargerStatus,
} from "components";
import { PlaceAlerts } from "components/alerts/PlaceAlerts.tsx";
import { HorizontalTimeseriesChart } from "components/charts/horizontalTimeSeries.tsx";
import {
  SelectedDeviceProvider,
  useSelectedDevice,
} from "context/SelectedDeviceContext.tsx";
import {
  SelectedSimulationProvider,
  SelectedSimulationSelector,
  useSelectedSimulation,
} from "context/SelectedSimulationContext.tsx";
import {
  SelectedSiteLevelProvider,
  useSelectedSiteLevel,
} from "context/SelectedSiteLevelContext.tsx";
import {
  TimeRangeSelector,
  useSelectedTimeRange,
} from "context/SelectedTimeRangeContext.tsx";

import type React from "react";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";

import type { Thing } from "api/ingestion/things";
import ButtonComponent from "../../components/uikit/button.tsx";
import { useAuth } from "../../context/AuthContext.tsx";
import AddDeviceModal from "../devices/AddDeviceModal";
import { EditSiteModal } from "./EditSiteModal";
import FilteredDevices from "./filteredDevices.tsx";
import { TopologyOverview } from "./topology.tsx";

type DeviceGroup = {
  [key: string]: Thing[];
};

const Tile = ({
  className = "",
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={`bg-white rounded-md shadow border border-gray90 ${className}`}
    >
      {children}
    </div>
  );
};

const DeviceDetailWrapper = () => {
  // allows the use of the selected device context
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  return (
    <DeviceDetail
      selectedDevice={selectedDevice}
      setSelectedDevice={setSelectedDevice}
      placeType="site"
    />
  );
};

const PageContent = ({ siteId }: { siteId: string }) => {
  const [site, setSite] = useState<SiteResponse | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceTypes, setDeviceTypes] = useState<string[]>([]);
  const [devices, setDevices] = useState<DeviceGroup>({});
  const [summaryStats, setSummaryStats] = useState<DatapointMap>({});
  const [allMeterTimeseries, setAllMeterTimeseries] =
    useState<Timeseries | null>(undefined);
  const [isDownloadingAggregateData, setIsDownloadingAggregateData] =
    useState(false);

  const { simulationId, simulation } = useSelectedSimulation();

  const { user, permissions } = useAuth();
  const { editSite, getSiteDetail, addSite, deleteSite } = usePlacesApi();
  const { getSiteAggregateReport, getTimeseriesForSite, getSummaryForSite } =
    useDataApi();
  const { getThingsFromSite, getThingTypes } = useThingsApi();

  // TODO: Hooks need to be cleaned up as well as all of data pulling
  const { start, end } = useSelectedTimeRange();

  // TODO: Data pulling has no dependencies so needs to be seperated out
  // biome-ignore lint/correctness/useExhaustiveDependencies: only re-run when partnerId changes, adding getSummaryForSite, getTimeseriesForSite, getThingsFromSite to the dependency array causes a re-render loop
  useEffect(() => {
    if (!user) return;

    // Reset all timeseries
    if (simulationId === null) {
      setAllMeterTimeseries(undefined);
    }

    // Pulling all events from API
    Promise.all([getSiteDetail(siteId), getThingTypes()])
      .then(([siteData, types]) => {
        setSite(siteData);
        setDeviceTypes(types);
      })
      .catch(console.error);

    const [binValue, binUnit] = generateIdealBinSize(start, end);
    getTimeseriesForSite(siteId, start, end, binUnit, binValue, simulationId)
      .then(convertToTimeseries)
      .then(setAllMeterTimeseries)
      .catch(console.error);

    // TODO: this code should be re-evaluated
    getThingsFromSite(siteId)
      .then((devices) => {
        // group by device.thingType
        const groupedDevices = devices.reduce((acc, device) => {
          acc[device.thingType] = (acc[device.thingType] ?? []).concat(device);
          return acc;
        }, {});
        setDevices(groupedDevices);
      })
      .catch((err) => console.error(err));

    getSummaryForSite(siteId, start, end, simulationId)
      .then(datapointsToMap)
      .then(setSummaryStats)
      .catch(console.error);
  }, [start, end, siteId, user?.partnerId, simulationId]);

  // TODO: we should fix this
  const energyCardStats = [
    {
      value: summaryStats.fwd?.value.toFixed(0) ?? null,
      units: "kWh",
      label: "Drawn from Grid",
      trend: 0,
    },
    {
      value: null,
      units: "kWh",
      label: "Dispensed to Vehicles",
      trend: 0,
    },
    {
      value: summaryStats.stored?.value.toFixed(0) ?? null,
      units: "kWh",
      label: "Currently Stored",
      trend: 0,
    },
    {
      value: null,
      units: "kWh",
      label: "Forecasted remaining Demand",
      trend: 0,
    },
  ];

  const handleDownloadAggregateReport = async () => {
    setIsDownloadingAggregateData(true);
    try {
      await getSiteAggregateReport(siteId, start, end, "h", 1, simulationId);
    } catch (error) {
      alert(`Download failed: ${error}`);
      console.error("Download failed:", error);
    } finally {
      setIsDownloadingAggregateData(false);
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleDeviceAdded = (result: { success: boolean }) => {
    if (result.success) {
      getThingsFromSite(siteId).then((devices) => {
        const groupedDevices = devices.reduce((acc, device) => {
          acc[device.thingType] = (acc[device.thingType] ?? []).concat(device);
          return acc;
        }, {});
        setDevices(groupedDevices);
      });
      return { success: true };
    }
    return { success: false, error: result.error };
  };

  const handleSaveSite = async (formData) => {
    const result = await editSite(siteId, formData);
    if (result.success) {
      setSite({ ...site, ...formData });
      return true;
    }
    console.error("Failed to update site:", result.error);
    return false;
  };

  const handleDeleteSite = async () => {
    try {
      const result = await deleteSite(siteId);
      if (result.success) {
        navigate("/sites");
      } else {
        console.error("Failed to delete site:", result.error);
        alert("Failed to delete site. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting site:", error);
      alert("An error occurred while deleting the site. Please try again.");
    }
  };

  // Can flow from prev 3 stats combined
  // const finalNetData = netData.filter(point => parseFloat(point.x) > lowerHourBound && parseFloat(point.x) < upperHourBound);

  // TODO: Aggregate the energy data to calculate the energy card stats
  // No grid energy drawn in raw battery stats, aggregate may not be pulled from events at all

  // TODO: For this demo, we can also calculate the chargerStates from aggregated energy data, dispensed coming from finalUsageData and scheduled remaining from schedule

  const alerts = (
    <PlaceAlerts
      placeType={"site"}
      placeId={siteId}
      partnerId={user?.partnerId}
      start={start}
      end={end}
    />
  );
  const stats = <EnergyCard energyCardStats={energyCardStats} />;
  const chargerStatus = <SkyportsChargerStatus devices={devices} />;
  const gridEnergyDraw = (
    <HorizontalTimeseriesChart timeseries={allMeterTimeseries} />
  );

  const renderActionButtons = () => {
    if (permissions.includes("write:ingest_things")) {
      return (
        <div className="flex items-center gap-2">
          <ButtonComponent.Pill
            iconBefore={<FaEdit />}
            onClick={() => setIsEditModalOpen(true)}
          >
            Edit Site
          </ButtonComponent.Pill>
          <ButtonComponent.Pill onClick={handleOpenModal}>
            + Add Device
          </ButtonComponent.Pill>
        </div>
      );
    }
    return null;
  };

  if (!site) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-full">
      <div className="grow h-screen overflow-y-scroll">
        <div className="flex pt-4 pb-3 justify-between">
          <div className="text-heading1 text-space50 flex items-center">
            <div className="flex items-center">
              <NavLink to="/sites">Sites</NavLink>
              <span className="mx-1">/</span>
              <span>{site.siteName}</span>
            </div>
            <div className="ml-4">{renderActionButtons()}</div>
          </div>
          <div className="flex items-center gap-2">
            <ButtonComponent.Pill
              onClick={handleDownloadAggregateReport}
              disabled={isDownloadingAggregateData}
            >
              {isDownloadingAggregateData
                ? "Downloading..."
                : "Download Aggregate Report"}
            </ButtonComponent.Pill>
            <SelectedSimulationSelector />
            <TimeRangeSelector />
          </div>
        </div>
        <hr />
        <div className="my-4">
          <h2 className="text-xl font-semibold">{site.siteName}</h2>
          <span className="text-gray-600">{site.address}</span>
          <br />
        </div>

        {simulationId !== null && (
          <div className="my-4 bg-blue90 p-4 rounded-md">
            <p className="font-bold">Current Simulation: {simulationId}</p>
            <p>
              {simulation.startDate.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
              {" - "}
              {simulation.endDate?.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </div>
        )}

        <div className="flex space-x-4 mb-4 h-[300px]">
          <Tile className="w-1/3 p-4">{alerts}</Tile>
          <Tile className="w-2/3">
            <NetworkMap
              latitude={Number.parseFloat(site.latitude)}
              longitude={Number.parseFloat(site.longitude)}
              {...devices}
            />
          </Tile>
        </div>

        <div className="flex space-x-4 mb-4 grow-0">
          <Tile className="w-full">{gridEnergyDraw}</Tile>
          {/* <Tile className="w-1/4">
            <LevelGroupDetails site={site} />
          </Tile> */}
        </div>

        <div className="flex space-x-4 mb-4">
          <Tile className="w-full">{stats}</Tile>
        </div>

        <SelectedSiteLevelProvider>
          <SiteTopology siteId={site.siteId} />
        </SelectedSiteLevelProvider>

        <div className="flex space-x-4 mb-4">
          <Tile className="w-full">{chargerStatus}</Tile>
        </div>
      </div>

      <EditSiteModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        site={site}
        onSave={handleSaveSite}
        onDelete={handleDeleteSite}
      />

      <AddDeviceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onDeviceAdded={handleDeviceAdded}
      />

      <DeviceDetailWrapper />
    </div>
  );
};

const SiteDetail = () => {
  const { siteId } = useParams();
  const [simulations, setSimulations] = useState<SimulationResponse[]>([]);
  const { getSimulationsForPlace } = useSimulationsApi();

  // biome-ignore lint/correctness/useExhaustiveDependencies: adding getSimulationsForPlace to the dependency array causes a re-render loop
  useEffect(() => {
    getSimulationsForPlace(siteId, "site").then(setSimulations);
  }, [siteId]);

  return (
    <SelectedDeviceProvider>
      <SelectedSimulationProvider simulations={simulations}>
        <PageContent siteId={siteId} />
      </SelectedSimulationProvider>
    </SelectedDeviceProvider>
  );
};

export default SiteDetail;

const SiteTopology = ({ siteId }: { siteId: string }) => {
  const { selectedSiteLevel } = useSelectedSiteLevel();
  return (
    <div className="flex space-x-4 mb-4">
      <Tile className="flex-grow">
        <TopologyOverview siteId={siteId} />
      </Tile>
      {selectedSiteLevel && (
        <Tile className="w-1/3">
          <FilteredDevices siteId={siteId} />
        </Tile>
      )}
    </div>
  );
};
