import { Sidebar } from "components";
import { useAuth } from "context/AuthContext";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  Dashboard,
  DeviceDetail,
  DeviceList,
  DevicesPage,
  FleetDetail,
  LocationSelection,
  Login,
  NoAccessPage,
  OrganizationPage,
  ResponsePage,
  ScopeSelection,
  Settings,
  SiteDetail,
  ThirdParty,
  Transaction,
} from "pages";

import { SelectedTimeRangeProvider } from "context/SelectedTimeRangeContext";
import React from "react";
import { Route, Routes } from "react-router-dom";

const NonProdEnvironmentWarning = () => {
  const env = process.env.REACT_APP_ENV;

  if (env === "production") {
    return null;
  }

  if (env === "staging") {
    return (
      <div className="fixed bottom-0 text-center w-full text-space80 p-2">
        Staging Environment - Demo Use Only
      </div>
    );
  }

  return (
    <div className="fixed bottom-0 text-center w-full text-space80 p-2">
      Dev Environment - Demo Use Only
    </div>
  );
};

const App = () => {
  const { isLoading, token } = useAuth();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!token)
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    );

  return (
    <SelectedTimeRangeProvider>
      <div className="flex flex-row w-screen bg-white">
        <Sidebar />
        <div className="min-h-screen grow min-w-0">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/sites"
              element={<LocationSelection locationType="site" />}
            />
            <Route path="/sites/:siteId" element={<SiteDetail />} />
            <Route
              path="/fleets"
              element={<LocationSelection locationType="fleet" />}
            />
            <Route path="/fleets/:fleetId" element={<FleetDetail />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/transaction" element={<Transaction />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/alerts" element={<NoAccessPage />} />
            <Route path="/devices" element={<DevicesPage />} />
            <Route path="/devices/:deviceId" element={<DeviceDetail />} />
            <Route path="/deviceList" element={<DeviceList />} />
            <Route path="/marketplace" element={<NoAccessPage />} />
            <Route path="/billing" element={<NoAccessPage />} />
            <Route path="/organization" element={<OrganizationPage />} />
            <Route path="/thirdparty" element={<ThirdParty />} />
            <Route
              path="/:providerName/customerscopeselection"
              element={<ScopeSelection />}
            />
            <Route path="/response/" element={<ResponsePage />} />
          </Routes>
        </div>
        <NonProdEnvironmentWarning />
      </div>
    </SelectedTimeRangeProvider>
  );
};

export default App;
