import type { SimulationResponse } from "api/ingestion/simulations";

import { createContext, useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as ChevronDown } from "images/icons/chevron.down.svg";
import ButtonComponent from "../components/uikit/button";

type SelectedSimulationContextType = {
  simulationId: string | null;
  simulation: SimulationResponse | null;
  simulations: SimulationResponse[];
  setSimulationId: (simulationId: string | null) => void;
};

const SelectedSimulationContext = createContext<
  SelectedSimulationContextType | undefined
>(undefined);

export const useSelectedSimulation = () => {
  const context = useContext(SelectedSimulationContext);
  if (!context) {
    throw new Error(
      "useSelectedSimulation must be used within a SelectedSimulationProvider",
    );
  }
  return context;
};

export const SelectedSimulationProvider: React.FC = ({
  simulations,
  children,
}: {
  simulations: SimulationResponse[];
  children: React.ReactNode;
}) => {
  const [simulationId, setSimulationId] = useState<string | null>(null);
  const simulation = simulations.find((s) => s.simulationId === simulationId);

  return (
    <SelectedSimulationContext.Provider
      value={{
        simulationId,
        simulation,
        simulations,
        setSimulationId,
      }}
    >
      {children}
    </SelectedSimulationContext.Provider>
  );
};

export const SelectedSimulationSelector = () => {
  const { simulations, simulationId, setSimulationId } =
    useSelectedSimulation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      !event.target.closest(".MuiPickersPopper-root") // Check if the click is inside the DateTimePicker
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <ButtonComponent.Pill
        onClick={toggleMenu}
        iconAfter={<ChevronDown />}
        className={`${simulationId !== null ? "bg-blue50 text-white" : "border border-space80 hover:bg-gray95 text-space70"}`}
      >
        {simulationId !== null ? `Simulation ${simulationId}` : "Live Data"}
      </ButtonComponent.Pill>
      <div
        className={`origin-top-right w-[250px] absolute right-0 py-1 mt-2 border border-gray95 rounded-md shadow-lg bg-white divide-y focus:outline-none z-20 ${isMenuOpen ? "" : "hidden"}`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <ButtonComponent.Menu
          onClick={() => setSimulationId(null)}
          className={`${simulationId === null ? "bg-blue90" : "hover:bg-gray95"}`}
        >
          <p className="font-bold">Live Data</p>
        </ButtonComponent.Menu>
        {simulations.map((simulation) => (
          <ButtonComponent.Menu
            key={simulation.simulationId}
            className={`${simulationId === simulation.simulationId ? "bg-blue90" : "hover:bg-gray95"}`}
            onClick={() => setSimulationId(simulation.simulationId)}
          >
            <p className="font-bold">{simulation.simulationId}</p>
            <p className="text-xs text-space70">
              {simulation.startDate.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
              {" - "}
              {simulation.endDate?.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
              })}
            </p>
          </ButtonComponent.Menu>
        ))}
      </div>
    </div>
  );
};
