interface ButtonComponentProps
  extends React.ComponentPropsWithoutRef<"button"> {
  // Custom props can be added here
  children: React.ReactNode; // Required children prop
}

// Base Button Component
function ButtonComponent({
  className = "",
  children,
  ...props
}: ButtonComponentProps) {
  return (
    <button {...props} className={`${className}`}>
      {children}
    </button>
  );
}

function PillButton({
  className = "",
  children,
  iconBefore,
  iconAfter,
  ...props
}: ButtonComponentProps & {
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
}) {
  return (
    <ButtonComponent
      {...props}
      className={`px-3.5 py-2 rounded-full border border-space80 justify-end items-center gap-1 cursor-pointer hover:bg-gray95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex text-space70 ${className}`}
    >
      {iconBefore && <div className="text-xs">{iconBefore}</div>}
      <div className="text-xs font-medium leading-[14px]">{children}</div>
      {iconAfter && <div className="text-xs">{iconAfter}</div>}
    </ButtonComponent>
  );
}

function MenuButton({
  className = "",
  children,
  ...props
}: ButtonComponentProps) {
  return (
    <ButtonComponent
      {...props}
      className={`px-4 w-full py-3 text-xs text-left ${className}`}
    >
      {children}
    </ButtonComponent>
  );
}

function OptionButton({
  className = "",
  children,
  ...props
}: ButtonComponentProps) {
  return (
    <ButtonComponent {...props} className={`p-2 w-full text-xs ${className}`}>
      {children}
    </ButtonComponent>
  );
}

type ButtonVariant = "outline" | "filled";
type ButtonStyle = "default" | "primary" | "danger";

function DefaultButton({
  className = "",
  children,
  variant = "outline",
  buttonStyle = "default",
  iconBefore,
  iconAfter,
  ...props
}: ButtonComponentProps & {
  variant: ButtonVariant;
  buttonStyle: ButtonStyle;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
}) {
  const variantStyles = {
    outline: {
      default: "border border-gray-300 text-gray-700 hover:bg-gray-50",
      primary: "border border-blue-300 text-blue-600 hover:bg-blue-50",
      danger: "border border-red-300 text-red-600 hover:bg-red-50",
    },
    filled: {
      default:
        "border border-transparent bg-gray-600 text-white hover:bg-gray-700",
      primary:
        "border border-transparent bg-blue-600 text-white hover:bg-blue-700",
      danger:
        "border border-transparent bg-red-600 text-white hover:bg-red-700",
    },
  };

  return (
    <ButtonComponent
      {...props}
      className={`px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${
        buttonStyle === "danger" ? "focus:ring-red-500" : "focus:ring-blue-500"
      } ${variantStyles[variant][buttonStyle]} flex items-center justify-center ${className}`}
    >
      {iconBefore && <div>{iconBefore}</div>}
      {children}
      {iconAfter && <div>{iconAfter}</div>}
    </ButtonComponent>
  );
}

// Attach variants to the main component
ButtonComponent.Pill = PillButton;
ButtonComponent.Menu = MenuButton;
ButtonComponent.Option = OptionButton;
ButtonComponent.Default = DefaultButton;

export default ButtonComponent;
