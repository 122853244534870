// spectra/src/context/SelectedDeviceContext.tsx
import type { DeviceResponse } from "api/NewIngestionApi.ts";

import { createContext, useContext, useState } from "react";

type SelectedDeviceContextType = {
  selectedDevice: DeviceResponse | null;
  setSelectedDevice: (device: DeviceResponse | null) => void;
};

const SelectedDeviceContext = createContext<
  SelectedDeviceContextType | undefined
>(undefined);

export const useSelectedDevice = () => {
  const context = useContext(SelectedDeviceContext);
  if (!context) {
    throw new Error(
      "useSelectedDevice must be used within a SelectedDeviceProvider",
    );
  }
  return context;
};

export const SelectedDeviceProvider: React.FC = ({ children }) => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceResponse | null>(
    null,
  );

  return (
    <SelectedDeviceContext.Provider
      value={{ selectedDevice, setSelectedDevice }}
    >
      {children}
    </SelectedDeviceContext.Provider>
  );
};
