import { useIngestionApi } from "api";
import { useAuth } from "context/AuthContext";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ResponsePage = () => {
  const location = useLocation();
  const [status, setStatus] = useState("validating");

  const { user } = useAuth();
  const { validateTokenFlow } = useIngestionApi();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("code");
    if (!user) return;

    if (token) {
      validateTokenFlow(token, user.partnerId).then((result) => {
        if (result.isValid) {
          setStatus("success");
        } else {
          setStatus("error");
          console.error(result.error);
        }
      });
    } else {
      setStatus("error");
    }
  }, [location, user?.partnerId]);

  const renderContent = () => {
    switch (status) {
      case "validating":
        return <p>Please wait, validating token...</p>;
      case "success":
        return <p>Successfully connected. You may close this page.</p>;
      case "error":
        return <p>Error occurred during token validation. Please try again.</p>;
      default:
        return null;
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">{renderContent()}</div>
    </div>
  );
};

export default ResponsePage;
