import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import {
  type Role,
  type UserInformation,
  useIdentityApi,
} from "api/ingestion/identity";

import { useEffect, useState } from "react";

import {
  EditableTextField,
  PrimaryButton,
  RoleSelector,
  SecondaryButton,
} from "./shared";

type UpdateableUserInformation = {
  email: string;
  firstName: string;
  lastName: string;
  roleIds: string[];
};

const EditForm = ({
  userInformation,
  availableRoles,
  userRoles,
  onSave,
}: {
  userInformation: UserInformation;
  availableRoles: Role[];
  userRoles: Role[];
  onSave: (newInfo: UpdateableUserInformation) => void;
}) => {
  const [info, setInfo] = useState<UpdateableUserInformation>({
    email: userInformation.email,
    firstName: userInformation.firstName,
    lastName: userInformation.lastName,
    roleIds: userRoles.map((role) => role.id),
  });

  return (
    <>
      <div className="mt-4">
        <div className="flex flex-col gap-2">
          <img
            src={userInformation.picture}
            alt="User"
            className="w-16 h-16 rounded-full"
          />
          <EditableTextField
            label="Email"
            value={info.email}
            onChange={(value) => setInfo({ ...info, email: value })}
          />
          <EditableTextField
            label="First Name"
            value={info.firstName}
            minLength={1}
            onChange={(value) => setInfo({ ...info, firstName: value })}
          />
          <EditableTextField
            label="Last Name"
            value={info.lastName}
            minLength={1}
            onChange={(value) => setInfo({ ...info, lastName: value })}
          />
          <RoleSelector
            roles={availableRoles}
            selected={info.roleIds}
            onChange={(value) => setInfo({ ...info, roleIds: value })}
          />
        </div>

        <div className="border-t border-gray90 pt-5 text-xs text-space70">
          <p>Created at: {userInformation.createdAt}</p>
          <p>Updated at: {userInformation.updatedAt}</p>
          <p>User ID: {userInformation.userId}</p>
          <p>
            Last login from {userInformation.lastIpAddress} at{" "}
            {userInformation.lastLogin}
          </p>
        </div>
      </div>
      <PrimaryButton onClick={() => onSave(info)}>Save Changes</PrimaryButton>
    </>
  );
};

export const EditUserModal = ({
  userId,
  open,
  setOpen,
}: {
  userId: string | null;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const {
    getUserDetails,
    getRoles,
    updateUserDetails,
    getRolesForUser,
    updateEmailForUser,
    addRolesForUser,
    deleteRolesForUser,
  } = useIdentityApi();

  const [errors, setErrors] = useState<string[]>([]);
  const [ready, setReady] = useState(false);

  const [userInformation, setUserInformation] =
    useState<UserInformation | null>(null);
  const [roles, setRoles] = useState<Role[] | null>(null);
  const [userRoles, setUserRoles] = useState<Role[] | null>(null);

  useEffect(() => {
    if (!userId) return;

    const fetchData = async () => {
      await getUserDetails(userId).then(setUserInformation);
      await getRoles().then(setRoles);
      await getRolesForUser(userId).then(setUserRoles);
      setReady(true);
    };

    fetchData();
  }, [userId]);

  const addError = (error: string) => {
    setErrors((prev) => [...prev, error]);
  };

  const handleSaveChanges = async (newInfo: UpdateableUserInformation) => {
    if (!userId) return;

    if (newInfo.email !== userInformation.email) {
      await updateEmailForUser(userId, newInfo.email).catch((error) => {
        addError("Failed to update email: " + error.message);
      });
    }

    if (
      newInfo.firstName !== userInformation.firstName ||
      newInfo.lastName !== userInformation.lastName
    ) {
      await updateUserDetails(userId, {
        firstName: newInfo.firstName,
        lastName: newInfo.lastName,
      }).catch((error) => {
        addError("Failed to update name: " + error.message);
      });
    }

    const oldRoleIds = new Set(userRoles?.map((role) => role.id));
    const newRoleIds = new Set(newInfo.roleIds);
    if (oldRoleIds !== newRoleIds) {
      const rolesToAdd = Array.from(newRoleIds).filter(
        (roleId) => !oldRoleIds.has(roleId),
      );
      const rolesToRemove = Array.from(oldRoleIds).filter(
        (roleId) => !newRoleIds.has(roleId),
      );

      if (rolesToAdd.length > 0) {
        await addRolesForUser(userId, rolesToAdd).catch((error) => {
          addError("Failed to add roles: " + error.message);
        });
      }
      if (rolesToRemove.length > 0) {
        await deleteRolesForUser(userId, rolesToRemove).catch((error) => {
          addError("Failed to remove roles: " + error.message);
        });
      }
    }

    if (errors.length === 0) {
      handleClose();
    }
  };

  const handleClose = () => {
    setUserInformation(null);
    setUserRoles(null);
    setErrors([]);
    setReady(false);
    setOpen(false);
  };

  const closeButton = (
    <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
  );

  const content =
    errors.length > 0 ? (
      <div className="flex flex-col gap-4 pt-4">
        <div className="text-center font-semibold text-md text-red40">
          Error
        </div>
        {errors.map((error, index) => (
          <div className="text-center text-sm text-red40" key={index}>
            {error}
          </div>
        ))}
        {closeButton}
      </div>
    ) : ready ? (
      <>
        <EditForm
          userInformation={userInformation}
          availableRoles={roles}
          userRoles={userRoles}
          onSave={handleSaveChanges}
        />
        {closeButton}
      </>
    ) : (
      <div className="mt-5">
        <div className="text-center text-space50 pt-4">Loading...</div>
        {closeButton}
      </div>
    );

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {content}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
