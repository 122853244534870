import { useAuthFetch } from "context/AuthContext";
import { type Dayjs, dayjs } from "utils/dayjs";

export const formatDate = (day: Dayjs) => {
  return day.format("YYYY-MM-DD HH:mm:ss.SSS");
};

const getBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return "https://data.spectra.api.aerovy.com";
    case "staging":
      return "https://data.spectra.api.staging.aerovy.com";
    case "development":
      return "https://data.spectra.api.dev.aerovy.com";
    default:
      return "/data";
  }
};

const getFilenameFromResponse = (
  response: Response,
  siteId: string,
  simulationId: string | null,
  start: string,
  end: string,
): string => {
  const contentDisposition = response.headers.get("Content-Disposition") || "";
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  return matches && matches[1]
    ? matches[1].replace(/['"]/g, "")
    : `site_${siteId}_${formatDate(start)}_${formatDate(end)}${
        simulationId ? "_sim" : ""
      }_aggregate_report.csv`;
};

const downloadBlob = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const BASE_URL = getBaseURL();

export type Datapoint = {
  type: string;
  unit: string;
  value: number;
};

export type DataResponse = {
  time: string; // in format 2024-03-19T12:00:00+00:00
  dataPoints: Datapoint[];
};

export type ThingManufacturerModels = {
  [thingType: string]: {
    [manufacturer: string]: string[];
  };
};

export const useDataApi = () => {
  const { authFetch } = useAuthFetch();

  const getDataForThing = async (
    placeType: string,
    placeId: string,
    thingId: string,
    start: Dayjs,
    end: Dayjs,
    binUnit = "h",
    binValue = 1,
    simulationId: string | null = null,
  ): Promise<DataResponse[]> => {
    const startFmtd = formatDate(start);
    const endFmtd = formatDate(end);

    let params = [
      `startTime=${encodeURIComponent(startFmtd)}`,
      `endTime=${encodeURIComponent(endFmtd)}`,
      `binUnit=${encodeURIComponent(binUnit)}`,
      `binValue=${encodeURIComponent(binValue)}`,
    ].join("&");

    if (simulationId) {
      params += `&simulationId=${encodeURIComponent(simulationId)}`;
    }

    return await authFetch(
      `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}?${params}`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getAlertsForThing = async (
    placeType: string,
    placeId: string,
    thingId: string,
    start: Dayjs,
    end: Dayjs,
    simulationId: string | null = null,
  ): Promise<DataResponse[]> => {
    const startFmtd = formatDate(start);
    const endFmtd = formatDate(end);

    let params = [
      `startTime=${encodeURIComponent(startFmtd)}`,
      `endTime=${encodeURIComponent(endFmtd)}`,
    ].join("&");

    if (simulationId) {
      params += `&simulationId=${encodeURIComponent(simulationId)}`;
    }

    return await authFetch(
      `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/alerts?${params}`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getAlertsForPlace = async (
    placeType: string,
    placeId: string,
    start: Dayjs,
    end: Dayjs,
    simulationId: string | null = null,
  ): Promise<DataResponse[]> => {
    const startFmtd = formatDate(start);
    const endFmtd = formatDate(end);

    let params = [
      `startTime=${encodeURIComponent(startFmtd)}`,
      `endTime=${encodeURIComponent(endFmtd)}`,
    ].join("&");

    if (simulationId) {
      params += `&simulationId=${encodeURIComponent(simulationId)}`;
    }

    return await authFetch(
      `${BASE_URL}/${placeType}/${placeId}/alerts?${params}`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getSummaryForThing = async (
    placeType: string,
    placeId: string,
    thingId: string,
    start: Dayjs,
    end: Dayjs,
    simulationId: string | null = null,
  ): Promise<Datapoint[]> => {
    const startFmtd = formatDate(start);
    const endFmtd = formatDate(end);

    let params = [
      `startTime=${encodeURIComponent(startFmtd)}`,
      `endTime=${encodeURIComponent(endFmtd)}`,
    ].join("&");

    if (simulationId) {
      params += `&simulationId=${encodeURIComponent(simulationId)}`;
    }

    return await authFetch(
      `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/summary?${params}`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getSummaryForPlace = async (
    placeType: string,
    placeId: string,
    start: Dayjs,
    end: Dayjs,
    simulationId: string | null = null,
  ): Promise<Datapoint[]> => {
    const startFmtd = formatDate(start);
    const endFmtd = formatDate(end);

    let params = [
      `startTime=${encodeURIComponent(startFmtd)}`,
      `endTime=${encodeURIComponent(endFmtd)}`,
    ].join("&");

    if (simulationId) {
      params += `&simulationId=${encodeURIComponent(simulationId)}`;
    }

    return await authFetch(
      `${BASE_URL}/${placeType}/${placeId}/summary?${params}`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  // usually has net, fwd, max, rev
  const getSummaryForSite = async (
    siteId: string,
    start: Dayjs,
    end: Dayjs,
    simulationId: string | null = null,
  ): Promise<Datapoint[]> => {
    return getSummaryForPlace("site", siteId, start, end, simulationId);
  };

  const getSummaryForFleet = async (
    fleetId: string,
    start: Dayjs,
    end: Dayjs,
    simulationId: string | null = null,
  ): Promise<Datapoint[]> => {
    return getSummaryForPlace("fleet", fleetId, start, end, simulationId);
  };

  const getSummaryForThings = async (
    siteId: string,
    thingIds: string[],
    start: Dayjs,
    end: Dayjs,
    simulationId: string | null = null,
  ): Promise<DataResponse> => {
    const startFmtd = formatDate(start);
    const endFmtd = formatDate(end);

    let params = [
      `startTime=${encodeURIComponent(startFmtd)}`,
      `endTime=${encodeURIComponent(endFmtd)}`,
    ].join("&");

    if (simulationId) {
      params += `&simulationId=${encodeURIComponent(simulationId)}`;
    }

    return await authFetch(
      `${BASE_URL}/site/${siteId}/things/summary?${params}`,
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(thingIds),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getTimeseriesForSite = async (
    siteId: string,
    start: Dayjs,
    end: Dayjs,
    binUnit = "h",
    binValue = 1,
    simulationId: string | null = null,
  ): Promise<DataResponse[]> => {
    const startFmtd = formatDate(start);
    const endFmtd = formatDate(end);

    let params = [
      `startTime=${encodeURIComponent(startFmtd)}`,
      `endTime=${encodeURIComponent(endFmtd)}`,
      `binUnit=${encodeURIComponent(binUnit)}`,
      `binValue=${encodeURIComponent(binValue)}`,
    ].join("&");

    if (simulationId) {
      params += `&simulationId=${encodeURIComponent(simulationId)}`;
    }

    const url = `${BASE_URL}/site/${siteId}/timeseries?${params}`;

    try {
      const res = await authFetch(url, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        console.error("Response not ok:", {
          status: res.status,
          statusText: res.statusText,
        });
        throw new Error(`HTTP error, status: ${res.status}`);
      }

      const text = await res.text();

      if (!text) {
        console.warn("Empty response received");
        return [];
      }

      try {
        const data = JSON.parse(text);
        return data;
      } catch (parseError) {
        console.error("JSON parse error:", parseError);
        console.error("Raw response:", text);
        throw new Error("Invalid JSON response");
      }
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  };

  const getTimeseriesForFleet = async (
    fleetId: string,
    start: Dayjs,
    end: Dayjs,
    binUnit = "h",
    binValue = 1,
    simulationId: string | null = null,
  ): Promise<DataResponse[]> => {
    const startFmtd = formatDate(start);
    const endFmtd = formatDate(end);

    let params = [
      `startTime=${encodeURIComponent(startFmtd)}`,
      `endTime=${encodeURIComponent(endFmtd)}`,
      `binUnit=${encodeURIComponent(binUnit)}`,
      `binValue=${encodeURIComponent(binValue)}`,
    ].join("&");

    if (simulationId) {
      params += `&simulationId=${encodeURIComponent(simulationId)}`;
    }

    const url = `${BASE_URL}/fleet/${fleetId}/timeseries?${params}`;

    try {
      const res = await authFetch(url, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        console.error("Response not ok:", {
          status: res.status,
          statusText: res.statusText,
        });
        throw new Error(`HTTP error, status: ${res.status}`);
      }

      const text = await res.text();

      if (!text) {
        console.warn("Empty response received");
        return [];
      }

      try {
        const data = JSON.parse(text);
        return data;
      } catch (parseError) {
        console.error("JSON parse error:", parseError);
        console.error("Raw response:", text);
        throw new Error("Invalid JSON response");
      }
    } catch (error) {
      console.error("Fetch error:", error);
      throw error;
    }
  };

  const getSiteAggregateReport = async (
    siteId: string,
    start: string,
    end: string,
    binUnit = "h",
    binValue = 1,
    simulationId: string | null = null,
  ): Promise<void> => {
    const params = new URLSearchParams({
      startTime: formatDate(start),
      endTime: formatDate(end),
      binUnit,
      binValue: binValue.toString(),
    });

    if (simulationId) {
      params.set("simulationId", encodeURIComponent(simulationId));
    }

    try {
      const response = await authFetch(
        `${BASE_URL}/site/${siteId}/aggregate?${params}`,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error, status: ${response.status}`);
      }

      const filename = getFilenameFromResponse(
        response,
        siteId,
        simulationId,
        start,
        end,
      );
      const blob = await response.blob();

      downloadBlob(blob, filename);
    } catch (error) {
      console.error("Error downloading site aggregate:", error);
      throw error;
    }
  };

  const getFleetAggregateReport = async (
    fleetId: string,
    start: string,
    end: string,
    binUnit = "h",
    binValue = 1,
    simulationId: string | null = null,
  ): Promise<void> => {
    const params = new URLSearchParams({
      startTime: formatDate(start),
      endTime: formatDate(end),
      binUnit,
      binValue: binValue.toString(),
    });

    if (simulationId) {
      params.set("simulationId", encodeURIComponent(simulationId));
    }

    try {
      const response = await authFetch(
        `${BASE_URL}/fleet/${fleetId}/aggregate?${params}`,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error, status: ${response.status}`);
      }

      const filename = getFilenameFromResponse(
        response,
        fleetId,
        simulationId,
        start,
        end,
      );
      const blob = await response.blob();

      downloadBlob(blob, filename);
    } catch (error) {
      console.error("Error downloading fleet aggregate:", error);
      throw error;
    }
  };

  const getThingPropertyKeys = async (): Promise<Record<string, string[]>> => {
    const url = `${BASE_URL}/thing-properties`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingManufacturerModels =
    async (): Promise<ThingManufacturerModels> => {
      const url = `${BASE_URL}/thing-manufacturer-models`;
      return await authFetch(url, {
        method: "GET",
        headers: {
          Accept: "*/*",
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error, status: ${res.status}`);
        }
        return res.json();
      });
    };

  const getThingLastEventTime = async (
    placeType: string,
    placeId: string,
    thingId: string,
  ): Promise<string> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/latestEventTime`;

    try {
      const response = await authFetch(url, {
        method: "GET",
        headers: {
          accept: "*/*",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error, status: ${response.status}`);
      }

      const rawText = await response.text();

      // Handle empty responses
      if (!rawText || rawText.trim() === "") {
        return "";
      }

      try {
        return JSON.parse(rawText);
      } catch (parseError) {
        console.warn("Failed to parse lastEventTime:", {
          deviceId: thingId,
          rawText,
          error: parseError,
        });
        return "";
      }
    } catch (error) {
      console.error("Error getting thing last event time:", error);
      throw error;
    }
  };

  return {
    getDataForThing,
    getAlertsForThing,
    getAlertsForPlace,
    getSummaryForThing,
    getSummaryForSite,
    getSummaryForFleet,
    getSummaryForThings,
    getTimeseriesForSite,
    getTimeseriesForFleet,
    getSiteAggregateReport,
    getFleetAggregateReport,
    getThingPropertyKeys,
    getThingManufacturerModels,
    getThingLastEventTime,
  };
};

// convert to dict response
export type Timeseries = {
  start: string;
  end: string;
  types: string[];
  units: string[];
  values: TimeseriesValueWithStart[];
  summary: TimeseriesValue;
};

export type TimeseriesValue = {
  [key: string]: number;
};

export type TimeseriesValueWithStart = { time: string } & TimeseriesValue;

export type DatapointMap = { [key: string]: Datapoint };
export const datapointsToMap = (datapoints: Datapoint[]): DatapointMap => {
  return datapoints.reduce((acc, curr) => {
    acc[curr.type] = curr;
    return acc;
  }, {});
};

export const mergeDatapoints = (datapoints: Datapoint[]): TimeseriesValue => {
  return datapoints.reduce((acc, curr) => {
    acc[curr.type] = curr.value;
    return acc;
  }, {});
};

export const filterArrayOnlyUniqueKey = <T>(arr: T[], key: string): T[] => {
  const seen = new Set();
  return arr.filter((e) => {
    if (seen.has(e[key])) {
      console.warn("Duplicate timestamp", e[key]);
      return false;
    }
    seen.add(e[key]);
    return true;
  });
};

export const convertToTimeseries = (
  points: DataResponse[],
): Timeseries | null => {
  if (points.length === 0) {
    return null;
  }

  const start = points[0].time;
  const end = points[points.length - 1].time;
  const types = points[0].dataPoints.map((dp) => dp.type);
  const units = points[0].dataPoints.map((dp) => dp.unit);

  const values = filterArrayOnlyUniqueKey(points, "time").flatMap((p) => {
    const date = dayjs(p.time).toDate();
    return {
      ...mergeDatapoints(p.dataPoints),
      time: date,
    };
  });

  const summary = values.reduce((acc, v) => {
    // sum up all the values with the same key name
    Object.keys(v).forEach((key) => {
      acc[key] = (acc[key] || 0) + v[key];
    });
    return acc;
  }, {});

  return { start, end, types, units, values, summary };
};

export const generateIdealBinSize = (
  start: Dayjs,
  end: Dayjs,
): [number, string] => {
  // generates "ideal" bins for a given timeframe.
  // Shoot for 50-150 datapoints given the window

  const weeks = end.diff(start, "week");

  if (weeks <= 1) {
    return [1, "h"];
  } else if (weeks <= 2) {
    return [4, "h"];
  } else if (weeks <= 4) {
    return [8, "h"];
  }

  const months = end.diff(start, "month");

  if (months <= 3) {
    return [1, "d"];
  } else if (months <= 6) {
    return [2, "d"];
  }

  const years = end.diff(start, "year");

  if (years <= 1) {
    return [7, "d"];
  } else if (years <= 3) {
    return [14, "d"];
  } else {
    return [1, "m"];
  }
};
