import type { FleetResponse, SiteResponse } from "api/ingestion/places.ts";
import type { Thing } from "api/ingestion/thing.ts";
import { DeviceTile } from "components";

import { useEffect, useState } from "react";

type DeviceType = {
  thingType: string;
  devices: Thing[];
};

type Place = {
  placeId: string;
  placeName: string;
  placeType: string;
  address?: string;
  deviceTypes: DeviceType[];
};

const DeviceTypeInformation = ({
  thingType,
  devices,
}: {
  thingType: string;
  devices: Thing[];
}) => (
  <div className="flex flex-col gap-2">
    <p className="text-heading3 text-space50">{thingType}</p>
    <div className="flex flex-wrap gap-4">
      {devices.map((device) => (
        <DeviceTile device={device} key={device.thingName} />
      ))}
    </div>
  </div>
);

const PlaceSection = ({
  placeName,
  placeType,
  address,
  deviceTypes,
}: Place) => (
  <div className="mb-6">
    <div className="mb-2">
      <p className="text-heading2 text-space50">{placeName}</p>
      <div className="flex gap-2 items-center">
        <span className="text-caption text-space70 uppercase">{placeType}</span>
        {address && (
          <>
            <span className="text-space70">•</span>
            <span className="text-caption text-space70">{address}</span>
          </>
        )}
      </div>
    </div>
    <div className="flex flex-col p-4 gap-4 elevation-1 rounded-md">
      {deviceTypes.length > 0 ? (
        deviceTypes.map((deviceType) => (
          <DeviceTypeInformation {...deviceType} key={deviceType.thingType} />
        ))
      ) : (
        <p className="text-gray-500 text-sm">No devices found</p>
      )}
    </div>
  </div>
);

const DevicesByPlace = ({
  sites,
  fleets,
  deviceTypes,
  devices,
}: {
  sites: SiteResponse[];
  fleets: FleetResponse[];
  deviceTypes: string[];
  devices: Thing[];
}) => {
  const [groupedPlaces, setGroupedPlaces] = useState<Place[]>([]);

  useEffect(() => {
    const groupDevicesByType = (placeDevices: Thing[]) => {
      return Object.values(
        placeDevices.reduce(
          (acc, device) => {
            if (!acc[device.thingType]) {
              acc[device.thingType] = {
                thingType: device.thingType,
                devices: [],
              };
            }
            acc[device.thingType].devices.push(device);
            return acc;
          },
          {} as Record<string, DeviceType>,
        ),
      ).sort((a, b) => a.thingType.localeCompare(b.thingType));
    };

    const places: Place[] = [
      ...sites.map((site) => ({
        placeId: site.siteId,
        placeName: site.siteName,
        placeType: "site",
        address: site.address,
        deviceTypes: groupDevicesByType(
          devices.filter(
            (d) => d.placeType === "Site" && d.placeId === site.siteId,
          ),
        ),
      })),
      ...fleets.map((fleet) => ({
        placeId: fleet.fleetId,
        placeName: fleet.fleetName,
        placeType: "fleet",
        deviceTypes: groupDevicesByType(
          devices.filter(
            (d) => d.placeType === "Fleet" && d.placeId === fleet.fleetId,
          ),
        ),
      })),
    ].sort((a, b) => {
      if (a.placeType !== b.placeType) {
        return a.placeType === "site" ? -1 : 1;
      }
      return a.placeName.localeCompare(b.placeName);
    });
    setGroupedPlaces(places);
  }, [sites, fleets, deviceTypes, devices]);

  return (
    <div className="flex flex-col">
      {groupedPlaces.length > 0 ? (
        groupedPlaces.map((place) => (
          <PlaceSection key={place.placeId} {...place} />
        ))
      ) : (
        <div className="p-4 text-gray-500">No devices found</div>
      )}
    </div>
  );
};

export { DevicesByPlace };
