import { useAuthFetch } from "context/AuthContext";
import { type Dayjs, dayjs } from "utils/dayjs";

const getBaseURL = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return "https://spectra.api.aerovy.com";
    case "staging":
      return "https://spectra.api.staging.aerovy.com";
    case "development":
      return "https://spectra.api.dev.aerovy.com";
    default:
      return "/enterprise";
  }
};

export const BASE_URL = getBaseURL();

export const formatDate = (day: Dayjs) => {
  return day.format("YYYY-MM-DD HH:mm:ss.SSS");
};

export type MonitorBase = {
  monitorId: string; // "68bdf107-b4d2-4d63-961d-82456e07075b",
  monitorVersion: number; // 0,
  resourceId: string; // "6ed6b55a-1692-4e35-bed0-0dba9256a2e7",
  resourceType: string; // "THING.SwapStation",
  time: string; // "2025-02-19T22:00:04.035",
  eventId: string; // "3943f093-757f-494f-863a-e9dd576dd0e2",
};

export type MonitorEvent = MonitorBase & {
  metrics: string[];
  frequency: string;
  lookbackPeriod: string;
  monitorState: string;
  monitorDataState: string;
  monitorVariables: Record<string, number>;
};

export type AlertEvent = MonitorBase & {
  alertId: string; // "d433c792-078a-4c3f-bde3-d066ff04ad7c",
  alertState: string; // "WARNING",
  alertSeverity: string; // "0",
  alertMessage: string; // "Test Message",
  condition: string; // "avg >= 19.0 * property_swapStationBoxCount",
  conditionEvaluation: boolean; // true,
  integrationIds: string[]; // ["test_id"],
};

export type MonitorData = {
  monitorEvent: MonitorEvent;
  alertEvents: AlertEvent[];
};

export const useEnterpriseApi = () => {
  const { authFetch } = useAuthFetch();

  const getSwapStationsData = async (partnerId: string): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/data?partnerId=${encodeURIComponent(partnerId)}`,
      {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getSwapStationData = async (
    partnerId: string,
    swapStationId: string,
    placeId: string,
  ): Promise<any> => {
    const params = new URLSearchParams({
      partnerId,
      placeId,
    });

    return await authFetch(
      `${BASE_URL}/swap-stations/data/${swapStationId}?${params}`,
      {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getSwapStationBatteries = async (
    partnerId: string,
    swapStationId: string,
    placeId: string,
  ): Promise<any> => {
    const params = new URLSearchParams({
      partnerId,
      placeId,
    });

    return await authFetch(
      `${BASE_URL}/swap-stations/data/${swapStationId}/batteries?${params}`,
      {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getBatteryState = async (
    partnerId: string,
    swapStationId: string,
    placeId: string,
    batteryManufacturerId: string,
  ): Promise<any> => {
    const params = new URLSearchParams({
      partnerId,
      placeId,
    });

    return await authFetch(
      `${BASE_URL}/swap-stations/data/${swapStationId}/battery/${batteryManufacturerId}?${params}`,
      {
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const rentBattery = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
    swapStationDoorId: string,
    batteryManufacturerId: string,
  ): Promise<any> => {
    return await authFetch(`${BASE_URL}/swap-stations/control/rent`, {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        swapStationPartnerId,
        swapStationThingId,
        swapStationManufacturerId,
        swapStationPlaceId,
        swapStationDoorId,
        batteryManufacturerId,
      }),
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const returnBattery = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
    swapStationDoorId: string,
    batteryManufacturerId: string,
  ): Promise<any> => {
    return await authFetch(`${BASE_URL}/swap-stations/control/return`, {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        swapStationPartnerId,
        swapStationThingId,
        swapStationManufacturerId,
        swapStationPlaceId,
        swapStationDoorId,
        batteryManufacturerId,
      }),
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const checkTransactionStatus = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationPlaceId: string,
    transactionId: string,
  ): Promise<any> => {
    const response = await authFetch(
      `${BASE_URL}/swap-stations/control/status`,
      {
        method: "POST",
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          swapStationPartnerId,
          swapStationThingId,
          swapStationPlaceId,
          transactionId,
        }),
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error, status: ${response.status}`);
    }

    return response.json();
  };

  const confirmControl = async (
    controlName: "rent" | "return" | "swap",
    transactionId: string,
    recordId: string,
    swapStationPartnerId: string,
    swapStationManufacturerId: string,
    swapStationThingId: string,
    swapStationPlaceId: string,
  ): Promise<any> => {
    return await authFetch(
      `${BASE_URL}/swap-stations/control/${controlName}/confirm`,
      {
        method: "POST",
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          transactionId,
          recordId,
          swapStationPartnerId,
          swapStationManufacturerId,
          swapStationThingId,
          swapStationPlaceId,
        }),
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const swapBattery = async (
    swapStationPartnerId: string,
    swapStationManufacturerId: string,
    swapStationThingId: string,
    swapStationPlaceId: string,
    swapStationReturnDoorId: string,
    emptyBatteryManufacturerId: string,
    fullBatteryManufacturerId: string,
    fullBatteryDoorId: string,
  ): Promise<any> => {
    return await authFetch(`${BASE_URL}/swap-stations/control/swap`, {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        swapStationPartnerId,
        swapStationManufacturerId,
        swapStationThingId,
        swapStationPlaceId,
        swapStationReturnDoorId,
        emptyBatteryManufacturerId,
        fullBatteryManufacturerId,
        fullBatteryDoorId,
      }),
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const rebootSwapStation = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
  ): Promise<any> => {
    return await authFetch(`${BASE_URL}/swap-stations/control/reboot`, {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        swapStationPartnerId,
        swapStationThingId,
        swapStationManufacturerId,
        swapStationPlaceId,
      }),
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const refreshSwapStation = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
  ): Promise<any> => {
    return await authFetch(`${BASE_URL}/swap-stations/data/refresh`, {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        swapStationPartnerId,
        swapStationThingId,
        swapStationManufacturerId,
        swapStationPlaceId,
      }),
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const openDoors = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
    swapStationDoorId: string[],
  ): Promise<any> => {
    return await authFetch(`${BASE_URL}/swap-stations/control/doors/open`, {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        swapStationPartnerId,
        swapStationThingId,
        swapStationManufacturerId,
        swapStationPlaceId,
        swapStationDoorId,
      }),
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res;
    });
  };

  const openAllDoors = async (
    swapStationPartnerId: string,
    swapStationThingId: string,
    swapStationManufacturerId: string,
    swapStationPlaceId: string,
    maxDoorId: number,
  ): Promise<any> => {
    return await authFetch(`${BASE_URL}/swap-stations/control/doors/open/all`, {
      method: "POST",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        swapStationPartnerId,
        swapStationThingId,
        swapStationManufacturerId,
        swapStationPlaceId,
        maxDoorId,
      }),
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res;
    });
  };

  const getMonitorData = async (
    placeType: string,
    placeId: string,
    partnerId: string,
    startTime: Dayjs,
    endTime: Dayjs,
    resourceId?: string,
    monitorId?: string,
  ): Promise<MonitorData[]> => {
    const params = new URLSearchParams({
      partnerId,
      startTime: formatDate(startTime),
      endTime: formatDate(endTime),
      ...(resourceId && { resourceId }),
      ...(monitorId && { monitorId }),
    });

    return await authFetch(
      `${BASE_URL}/v1/monitors/data/${placeType}/${placeId}?${params}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  return {
    getSwapStationsData,
    getSwapStationData,
    getSwapStationBatteries,
    getBatteryState,
    rentBattery,
    returnBattery,
    swapBattery,
    checkTransactionStatus,
    confirmControl,
    rebootSwapStation,
    refreshSwapStation,
    openDoors,
    openAllDoors,
    getMonitorData,
  };
};
