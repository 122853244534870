import { ReactComponent as DownTrendIcon } from "images/icons/trend/down.svg";
import { ReactComponent as NominalTrendIcon } from "images/icons/trend/nominal.svg";
import { ReactComponent as UpTrendIcon } from "images/icons/trend/up.svg";
import React from "react";

interface TrendProps {
  percent: number;
  decimalPlaces?: number;
}

export const Trend = ({ percent, decimalPlaces = 0 }: TrendProps) => {
  const percentage = percent * 100;

  const getTrendIcon = (percentage: number) => {
    if (percentage >= 1) return <UpTrendIcon />;
    if (percentage <= -1) return <DownTrendIcon />;
    return <NominalTrendIcon />;
  };

  const getTrendColor = (percentage: number) => {
    if (percentage >= 1) return "bg-green90 text-green40";
    if (percentage <= -1) return "bg-red90 text-red40";
    return "border border-grey95 text-grey50";
  };

  if (percentage < 1 && percentage > -1) {
    return (
      <div
        className={`px-2 py-1.5 ${getTrendColor(percentage)} rounded justify-start items-center gap-1 flex`}
      >
        <div className="text-caption font-bold uppercase">&ndash;</div>
      </div>
    );
  }

  return (
    <div
      className={`px-2 py-1.5 ${getTrendColor(percentage)} rounded justify-start items-center gap-1 flex`}
    >
      <div className="text-caption font-bold uppercase">
        {Math.abs(percentage).toFixed(decimalPlaces)}%
      </div>
      <div className="text-caption uppercase">{getTrendIcon(percentage)}</div>
    </div>
  );
};
