import { type DatapointMap, datapointsToMap, useDataApi } from "api/data";
import type { Thing } from "api/ingestion/things";
import { useSelectedDevice } from "context/SelectedDeviceContext.tsx";
import { useSelectedSimulation } from "context/SelectedSimulationContext.tsx";
import { useSelectedTimeRange } from "context/SelectedTimeRangeContext.tsx";
import { typeToLabel } from "utils/typeToLabel";

import { useEffect, useMemo, useState } from "react";

import { ReactComponent as BatteriesIcon } from "images/icons/batteries.svg";
import { ReactComponent as ChargersIcon } from "images/icons/chargers.svg";
import { ReactComponent as MeterIcon } from "images/icons/meter.svg";
import { ReactComponent as NoConnectionIcon } from "images/icons/no-connection.svg";

import { useAuth } from "../../context/AuthContext";

enum SupportedDeviceType {
  Charger = 0,
  Meter = 1,
  Battery = 2,
  SwapStation = 3,
}
const getDeviceType = (thingType: string): SupportedDeviceType => {
  if (thingType === "Charger") {
    return SupportedDeviceType.Charger;
  }

  if (thingType === "Meter") {
    return SupportedDeviceType.Meter;
  }

  if (thingType === "Battery") {
    return SupportedDeviceType.Battery;
  }

  if (thingType === "SwapStation") {
    return SupportedDeviceType.SwapStation;
  }

  throw new Error("Unsupported device type");
};

enum Color {
  Blue = "blue",
  Space = "space",
  Green = "green",
  Yellow = "yellow",
  Red = "red",
  Gray = "gray",
  Low = "low",
  Medium = "medium",
  High = "high",
  Disconnected = "disconnected",
}

const colorToTailwindText = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "text-blue20";
    case Color.Space:
      return "text-space20";
    case Color.Green:
      return "text-green20";
    case Color.Yellow:
      return "text-yellow20";
    case Color.Red:
      return "text-red20";
    case Color.Gray:
      return "text-gray20";
    case Color.Low:
      return "text-blue10";
    case Color.Medium:
      return "text-blue10";
    case Color.High:
      return "text-blue10";
    case Color.Disconnected:
      return "text-gray60";
  }
};

const colorToTailwindBackground = (color: Color) => {
  switch (color) {
    case Color.Blue:
      return "bg-blue80";
    case Color.Space:
      return "bg-space80";
    case Color.Green:
      return "bg-green80";
    case Color.Yellow:
      return "bg-yellow80";
    case Color.Red:
      return "bg-red80";
    case Color.Gray:
      return "bg-gray97";
    case Color.Low:
      return "bg-blue90";
    case Color.Medium:
      return "bg-blue80";
    case Color.High:
      return "bg-blue70";
    case Color.Disconnected:
      return "bg-gray90";
  }
};

const percentageToColor = (percentage: number) => {
  if (Number.isNaN(percentage)) {
    return Color.Gray;
  }

  if (percentage < 0.2) {
    return Color.Low;
  }

  if (percentage < 0.5) {
    return Color.Medium;
  }

  return Color.High;
};

export const ProgressBar = ({
  value,
  percentage,
  unit,
  label,
  colorOverride = null,
  digits = 0,
}: {
  value: number;
  percentage: number;
  unit: string;
  label: string;
  colorOverride?: Color | null;
  digits?: number;
}) => {
  const formattedValue = value.toLocaleString("en-US", {
    maximumFractionDigits: digits,
  });

  const color = colorOverride ?? percentageToColor(percentage);

  // HACK: make the tailwind compiler to recognize that we need to generate these colors
  const backgroundColor = colorToTailwindBackground(color);
  const textColor = colorToTailwindText(color);

  return (
    <div className="relative w-full">
      <div className="z-0 absolute top-0 left-0 bottom-0 right-0">
        <div
          className={`h-full ${backgroundColor}`}
          style={{ width: `${Math.min(percentage, 1) * 100}%` }}
        />
      </div>
      <div className="z-10 relative px-4 py-1 justify-between items-center flex">
        <div className={`${textColor} justify-start items-center gap-1 flex`}>
          <div className="text-body">{formattedValue}</div>
          <div className="text-[10px]">{unit}</div>
        </div>
        <div className="text-right text-[10px] capitalize">{label}</div>
      </div>
    </div>
  );
};

const Icon = ({ device }: { device: Thing }) => {
  switch (device.thingType) {
    case "Charger":
      return <ChargersIcon />;
    case "Battery":
      return <BatteriesIcon />;
    case "Meter":
      return <MeterIcon />;
    default:
      return null;
  }
};

const THIRTY_MINUTES = 30 * 60 * 1000;

export const DeviceTile = ({ device }: { device: Thing }) => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const { start, end } = useSelectedTimeRange();
  const { simulationId } = useSelectedSimulation();
  const { user } = useAuth();
  const { getSummaryForThing, getThingLastEventTime } = useDataApi();

  const [stats, setStats] = useState<DatapointMap>({});
  const [statsLoading, setStatsLoading] = useState(true);
  const [lastEventTime, setLastEventTime] = useState<string | null>(null);

  const isDisconnected = useMemo(() => {
    if (!lastEventTime || lastEventTime === "") return true;
    try {
      const date = new Date(lastEventTime);
      if (Number.isNaN(date.getTime())) return true;

      const timeElapsed = Math.abs(new Date().getTime() - date.getTime());
      return timeElapsed > THIRTY_MINUTES;
    } catch (e) {
      console.error("Error parsing date:", lastEventTime, e);
      return true;
    }
  }, [lastEventTime]);

  useEffect(() => {
    if (!user) return;

    const fetchStats = async () => {
      setStatsLoading(true);
      try {
        const response = await getSummaryForThing(
          device.placeType ?? "site",
          device.siteId,
          device.thingId,
          start,
          end,
          simulationId,
        );
        const mappedData = datapointsToMap(response);
        setStats(mappedData);
      } catch (error) {
        console.error(
          `Unable to fetch stats for device ${device.thingId}`,
          error,
        );
        setStats({});
      } finally {
        setStatsLoading(false);
      }
    };

    fetchStats();
  }, [device, start, end, user?.partnerId, simulationId]);

  useEffect(() => {
    if (!user) return;

    const fetchLastEventTime = async () => {
      try {
        const time = await getThingLastEventTime(
          device.placeType ?? "site",
          device.siteId,
          device.thingId,
        );
        setLastEventTime(time);
      } catch (error) {
        console.error("Error fetching last event time:", {
          deviceId: device.thingId,
          deviceName: device.thingName,
          error,
        });
        setLastEventTime(null);
      }
    };

    fetchLastEventTime();
  }, [device, user]);

  const StatsSkeleton = () => (
    <div className="w-full pb-2 flex-col justify-start items-start gap-px flex">
      {[...Array(3)].map((_, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: technically doesnt matter what order this comes out to
        <div key={index} className="relative w-full px-4 py-1">
          <div className="justify-start items-center gap-1 flex">
            <div className="h-5 bg-gray-200 w-16 animate-pulse" />
            <div className="h-4 bg-gray-200 w-8 animate-pulse" />
          </div>
        </div>
      ))}
    </div>
  );

  const header = (
    <div className="flex w-full px-2 pt-2 flex-col">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 justify-start items-center">
          <Icon device={device} />
          <div className="shrink">
            <div className="text-footnote text-space50 break-words whitespace-normal max-w-[120px]">
              {device.thingName}
            </div>
            <div className="text-detail text-space70 break-words whitespace-normal max-w-[120px]">
              {device.thingType}
            </div>
          </div>
        </div>
        {isDisconnected && <NoConnectionIcon className="w-4 h-4" />}
      </div>
      {/* Status? */}
      {/* Alerts */}
    </div>
  );

  // TODO: figure out %
  const statsView = statsLoading ? (
    <StatsSkeleton />
  ) : Object.values(stats).length > 0 ? (
    <div className="w-full pb-2 flex-col justify-start items-start gap-px flex">
      {Object.values(stats).map((stat) => (
        <ProgressBar
          key={stat.type}
          value={stat.value ?? "-"}
          percentage={stat.value / 100}
          unit={stat.unit}
          label={typeToLabel(stat.type)}
          colorOverride={isDisconnected ? Color.Disconnected : undefined}
        />
      ))}
    </div>
  ) : (
    <div
      className={`w-full pb-2 flex-col justify-start items-start gap-px flex ${isDisconnected ? "opacity-30" : ""}`}
    >
      <ProgressBar value={"-"} percentage={0} unit="" label="No data" />
    </div>
  );

  return (
    <div
      className={`bg-white rounded-md elevation-1 cursor-pointer hover:-translate-y-0.5 focus:translate-y-0.5 transition-colors transition-transform transition-shadow hover:shadow-md w-[160px] flex-col justify-start items-start gap-2 flex ${
        selectedDevice === device
          ? "outline outline-2 outline-offset-2 outline-blue50"
          : ""
      }`}
      onClick={() => {
        setSelectedDevice(selectedDevice === device ? null : device);
      }}
    >
      {header}
      {statsView}
    </div>
  );
};

export default DeviceTile;
