import { useThingsApi } from "api/ingestion/things";

import { useCallback, useState } from "react";

import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";
import { ReactComponent as EditIcon } from "images/icons/edit.svg";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import { ReactComponent as SortAscIcon } from "images/icons/sort.asc.svg";
import { ReactComponent as SortDescIcon } from "images/icons/sort.desc.svg";
import { ReactComponent as SortIcon } from "images/icons/sort.svg";

import { useAuth } from "../../context/AuthContext.tsx";
import type { Certificate } from "../types/certificate";
import { DeleteCertificateModal } from "./DeleteCertificateModal";

interface CertificateTableProps {
  certificates: Certificate[];
  placeType: string;
  placeId: string;
  thingId: string;
  onCreateClick: () => void;
  onViewPolicy: (
    placeType: string,
    siteId: string,
    thingId: string,
    policy: string,
  ) => void;
  onEditPolicy: () => void;
  onRefresh?: () => void;
}

export const CertificateTable: React.FC<CertificateTableProps> = ({
  certificates,
  placeType,
  placeId,
  thingId,
  onCreateClick,
  onViewPolicy,
  onEditPolicy,
  onRefresh,
}) => {
  const { deleteCertificate } = useThingsApi();
  const [isDeleting, setIsDeleting] = useState<string | null>(null);
  const [certificateToDelete, setCertificateToDelete] = useState<string | null>(
    null,
  );
  const [localCertificates, setLocalCertificates] = useState(certificates);

  const [sortConfig, setSortConfig] = useState<{
    key: "id" | "expiration";
    direction: "asc" | "desc";
  }>({ key: "id", direction: "asc" });

  useState(() => {
    setLocalCertificates(certificates);
  }, [certificates]);

  const { permissions } = useAuth();

  const handleSort = (key: "id" | "expiration") => {
    setSortConfig({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  const handleViewPolicy = useCallback(
    (policy: string) => {
      onViewPolicy(placeType, placeId, thingId, policy);
    },
    [placeType, placeId, thingId, onViewPolicy],
  );

  const handleDeleteClick = (certId: string) => {
    setCertificateToDelete(certId);
  };

  const handleModalClose = () => {
    setCertificateToDelete(null);
  };

  const handleDeleteConfirm = async (certId: string) => {
    setIsDeleting(certId);
    try {
      if (!placeType || !placeId || !thingId || !certId) {
        throw new Error("Missing required parameters for certificate deletion");
      }

      const result = await deleteCertificate(
        placeType,
        placeId,
        thingId,
        certId,
      );

      if (result.success) {
        setLocalCertificates((prev) =>
          prev.filter((cert) => cert.id !== certId),
        );
        onRefresh?.();
        alert("Certificate deleted successfully");
      } else {
        const errorMessage = result.error || "Failed to delete certificate";
        console.error("Delete certificate error:", {
          error: result.error,
          certId,
          placeType,
          placeId,
          thingId,
        });
        alert(errorMessage);
      }
    } catch (error) {
      console.error("Delete certificate error:", {
        error,
        certId,
        placeType,
        placeId,
        thingId,
      });
    } finally {
      setIsDeleting(null);
      setCertificateToDelete(null);
    }
  };

  const sortedData = [...localCertificates].sort((a, b) => {
    const direction = sortConfig.direction === "asc" ? 1 : -1;

    switch (sortConfig.key) {
      case "id":
        return direction * a.id.localeCompare(b.id);
      case "expiration":
        return (
          direction *
          (new Date(a.expirationDate).getTime() -
            new Date(b.expirationDate).getTime())
        );
      default:
        return 0;
    }
  });

  const getSortIcon = (key: "id" | "expiration") => {
    if (sortConfig.key !== key) {
      return <SortIcon />;
    }
    return sortConfig.direction === "asc" ? <SortAscIcon /> : <SortDescIcon />;
  };

  const truncateCertId = (certId: string): string => {
    const id = certId.split("/").pop() || certId;
    return `${id.slice(0, 6)}...${id.slice(-6)}`;
  };

  const renderActionButtons = () => {
    if (permissions.includes("write:ingest_things")) {
      return (
        <div className="flex justify-end mb-4">
          <button
            onClick={onEditPolicy}
            className="flex items-center gap-2 px-4 py-1.5 mr-4 text-blue-700 bg-white border border-blue-700 rounded hover:bg-blue-50"
          >
            <EditIcon />
            <span>Edit Policy</span>
          </button>
          <button
            onClick={onCreateClick}
            className="flex items-center gap-2 px-4 py-1.5 text-blue-700 bg-white border border-blue-700 rounded hover:bg-blue-50"
          >
            <span>+</span>
            <span>New Certificate</span>
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {renderActionButtons()}
      <div className="border-t border-[#EAEAEB] pt-4">
        <table className="w-full">
          <thead>
            <tr className="text-left">
              <th className="pb-2">
                <button
                  onClick={() => handleSort("id")}
                  className="flex items-center gap-1 text-gray-700"
                >
                  Certificate ID
                  {getSortIcon("id")}
                </button>
              </th>
              <th className="pb-2">
                <button
                  onClick={() => handleSort("expiration")}
                  className="flex items-center gap-1 text-gray-700"
                >
                  Expiration Date
                  {getSortIcon("expiration")}
                </button>
              </th>
              <th className="pb-2"></th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item) => (
              <tr key={item.id} className="border-t border-gray-100">
                <td
                  className="py-3 text-blue-700 cursor-pointer hover:underline"
                  onClick={() => handleViewPolicy(item.policy)}
                  title="View Policy Details"
                >
                  {truncateCertId(item.id)}
                </td>
                <td className="py-3">{item.expirationDate}</td>
                <td className="py-3 text-right">
                  <button
                    className="text-red-400 hover:text-red-600 rounded-full p-1 disabled:opacity-50 disabled:cursor-not-allowed"
                    onClick={() => handleDeleteClick(item.id)}
                    disabled={isDeleting === item.id}
                    title="Delete Certificate"
                  >
                    {isDeleting === item.id ? <LoadingIcon /> : <DeleteIcon />}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <DeleteCertificateModal
        certId={certificateToDelete}
        onClose={handleModalClose}
        onDelete={handleDeleteConfirm}
        placeType={placeType}
        siteId={placeId}
        thingId={thingId}
      />
    </div>
  );
};
