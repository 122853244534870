import { useThingsApi } from "api/ingestion/things.ts";

import { useState } from "react";

import { ReactComponent as CertificateIcon } from "images/icons/certificate.svg";
import { CertificateTable } from "../certificates/CertificateTable";
import { CreateCertificateModal } from "../certificates/CreateCertificateModal";
import { DeleteCertificateModal } from "../certificates/DeleteCertificateModal";
import { PolicyDetailsModal } from "../certificates/PolicyDetailsModal";
import { RootCertificateSection } from "../certificates/RootCertificateSection";
import { useCertificates } from "../certificates/useCertificates";
import { usePolicy } from "../certificates/usePolicy";

const SecurityTab: React.FC<SecurityTabProps> = ({
  placeType,
  siteId,
  thingId,
}) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedCertForDelete, setSelectedCertForDelete] = useState<
    string | null
  >(null);

  const {
    certificates,
    isLoading,
    error,
    certificateDetails,
    setCertificateDetails,
    isGenerating,
    handleCreateCertificate,
    refreshCertificates,
  } = useCertificates(placeType, siteId, thingId);

  const { getDefaultPolicy } = useThingsApi();
  const {
    policyDetails,
    selectedPolicy,
    handleViewPolicy,
    handleDeleteResource,
    handleUpdatePolicy,
    setPolicyDetails,
  } = usePolicy();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-gray-600">Loading...</div>
      </div>
    );
  }

  const handleCloseCertificateModal = () => {
    setIsCreateModalOpen(false);
    setCertificateDetails(null);
  };

  const handleEditPolicy = async () => {
    try {
      const policy = await getDefaultPolicy(placeType, siteId, thingId);
      handleViewPolicy(placeType, siteId, thingId, "");
    } catch (error) {
      console.error("Error fetching default policy:", error);
    }
  };

  return (
    <div className="p-4 elevation-1 rounded-md">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}

      {certificates.length === 0 ? (
        <div className="flex justify-center py-8">
          <button
            onClick={() => setIsCreateModalOpen(true)}
            disabled={isGenerating}
            className="flex items-center gap-2 px-4 py-2 bg-blue-700 text-white rounded hover:bg-blue-800 
              disabled:opacity-50 disabled:cursor-not-allowed
              focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            <CertificateIcon />
            Enable MQTT via Certificate
          </button>
        </div>
      ) : (
        <CertificateTable
          certificates={certificates}
          placeType={placeType}
          placeId={siteId}
          thingId={thingId}
          onCreateClick={() => setIsCreateModalOpen(true)}
          onViewPolicy={handleViewPolicy}
          onEditPolicy={handleEditPolicy}
          onRefresh={refreshCertificates} // Use the refresh function here
        />
      )}

      <CreateCertificateModal
        isOpen={isCreateModalOpen}
        onClose={handleCloseCertificateModal}
        thingId={thingId}
        onGenerate={handleCreateCertificate}
        isGenerating={isGenerating}
        certificateDetails={certificateDetails}
      />

      <DeleteCertificateModal
        certId={selectedCertForDelete}
        onClose={() => setSelectedCertForDelete(null)}
        onDelete={(certId) => {
          setSelectedCertForDelete(null);
        }}
        placeType={placeType}
        siteId={siteId}
        thingId={thingId}
      />

      <PolicyDetailsModal
        policy={policyDetails}
        onClose={() => setPolicyDetails(null)}
        onDelete={(type, value) =>
          handleDeleteResource(placeType, siteId, thingId, type, value)
        }
        thingId={thingId}
        currentPolicy={selectedPolicy}
        onUpdate={(updatedPolicy) =>
          handleUpdatePolicy(placeType, siteId, thingId, updatedPolicy)
        }
      />

      {certificates.length > 0 && <RootCertificateSection />}
    </div>
  );
};

export default SecurityTab;
