import type { Role } from "api/ingestion/identity";

import type React from "react";

export const SecondaryButton = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <button
      type="button"
      data-autofocus
      onClick={onClick}
      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      {children}
    </button>
  );
};

export const PrimaryButton = ({
  children,
  disabled = false,
  onClick,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="mt-4 inline-flex w-full justify-center rounded-md bg-blue50 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue40 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue50 disabled:bg-gray-400"
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const EditableTextField = ({
  label,
  value,
  minLength,
  onChange,
}: {
  label: string;
  value: string;
  minLength?: number;
  onChange: (value: string) => void;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="mb-4">
      <label
        htmlFor={label}
        className="block text-sm font-medium text-gray-700 pb-2"
      >
        {label}
      </label>
      <input
        type="text"
        id={label}
        name={label}
        value={value}
        onChange={handleChange}
        className="w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue50"
      />
      {minLength && value?.length < minLength && (
        <div className="text-xs text-red40 mt-2">
          Must be at least {minLength} character(s) long.
        </div>
      )}
    </div>
  );
};

export const RoleSelector = ({
  roles,
  selected,
  onChange,
}: {
  roles: Role[];
  selected: string[];
  onChange: (value: string[]) => void;
}) => {
  const handleChange = (roleId: string, checked: boolean) => {
    const selectedSet = new Set(selected);
    if (checked) {
      selectedSet.add(roleId);
    } else {
      selectedSet.delete(roleId);
    }
    onChange(Array.from(selectedSet));
  };

  return (
    <div className="divide-y divide-gray90">
      {roles.map((role) => (
        <div
          className="relative flex items-start pb-4 pt-3.5"
          key={role.roleId}
        >
          <div className="min-w-0 flex-1 text-sm leading-6">
            <label htmlFor="comments" className="font-medium text-gray-900">
              {role.name}
            </label>
            <p id="comments-description" className="text-gray-500">
              {role.description}
            </p>
          </div>
          <div className="ml-3 flex h-6 items-center">
            <input
              id="comments"
              name="comments"
              type="checkbox"
              aria-describedby="comments-description"
              className="h-4 w-4 rounded border-gray-300 text-blue50 focus:ring-blue50"
              checked={selected?.includes(role.id)}
              onChange={(e) => handleChange(role.id, e.target.checked)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
