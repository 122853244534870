// Used to store the auth0 token and user info
import { useAuth0 } from "@auth0/auth0-react";

import type React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type Auth0UserType = {
  partnerId: string;
  name: string; // email
  nickname: string;
  org_id: string;
  picture: string;
  sub: string;
  updated_at: string;
  userId: string;
};

type AuthContextType = {
  isLoading: boolean;
  token: string | null;
  user: Auth0UserType | null;
  permissions: string[];
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { isLoading, user, isAuthenticated, getAccessTokenSilently, logout } =
    useAuth0();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const getAccessToken = useCallback(async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: "organize",
        scope:
          "read:data_places read:data_things read:ingest_places read:ingest_things write:ingest_places write:ingest_things read:ingest_integrations write:ingest_integrations read:ingest write:ingest read:ingest_admin write:ingest_admin read:ingest_fleets write:ingest_fleets read:data_fleets",
      },
    });
    setAccessToken(token);

    // Decode JWT and extract permissions
    const decodedToken = decodeJwt(token);
    if (decodedToken && decodedToken.permissions) {
      setPermissions(decodedToken.permissions);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!isAuthenticated) return;
    getAccessToken();
  }, [getAccessToken, isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        user: user as Auth0UserType | null,
        isLoading,
        token: accessToken,
        permissions,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthFetch = (url: string, options: RequestInit) => {
  const { token } = useAuth();

  const authFetch = async (url: string, options: RequestInit) => {
    if (!token) throw new Error("No auth token available");

    const headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };

    return fetch(url, { ...options, headers });
  };

  return { authFetch };
};

// Simple function to decode JWT without using external libraries
const decodeJwt = (token: string): any => {
  try {
    const [header, payload, signature] = token.split(".");
    return JSON.parse(atob(payload));
  } catch (e) {
    console.error("Failed to decode JWT", e);
    return null;
  }
};
