import { useDataApi } from "api/data";
import { useThingsApi } from "api/ingestion/things";

import type React from "react";
import { useEffect, useState } from "react";

import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";
import { ReactComponent as WarningIcon } from "images/icons/warning.svg";

interface PropertyChanges {
  updates: Record<string, string>;
  deletes: string[];
}

const EditDeviceModal = ({
  isOpen,
  onCloseEditModal,
  onDeviceEdited,
  device,
  placeType,
  placeId,
  onCloseDeviceDetail,
}) => {
  const [formData, setFormData] = useState({
    thingName: "",
    thingDescription: "",
    address: "",
    thingManufacturerId: "",
    longitude: "",
    latitude: "",
    altitude: "",
    integrationId: "",
    levelId: "",
    primaryGroup: "",
    manufacturer: "",
    model: "",
    isFirstParty: false,
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [availableProperties, setAvailableProperties] = useState<
    string[] | null
  >(null);
  const [properties, setProperties] = useState<Record<string, string>>({});
  const [propertyChanges, setPropertyChanges] = useState<PropertyChanges>({
    updates: {},
    deletes: [],
  });

  const { getThingPropertyKeys, getThingManufacturerModels } = useDataApi();
  const { editThing, editThingPrimaryGroup, deleteThing } = useThingsApi();

  const [newPropertyKey, setNewPropertyKey] = useState("");
  const [newPropertyValue, setNewPropertyValue] = useState("");

  const [attributes, setAttributes] = useState<Record<string, string>>({});
  const [attributeChanges, setAttributeChanges] = useState<PropertyChanges>({
    updates: {},
    deletes: [],
  });
  const [newAttributeKey, setNewAttributeKey] = useState("");
  const [newAttributeValue, setNewAttributeValue] = useState("");

  const [manufacturerModels, setManufacturerModels] =
    useState<ThingManufacturerModels>({});
  const [availableManufacturers, setAvailableManufacturers] = useState<
    string[]
  >([]);
  const [availableModels, setAvailableModels] = useState<string[]>([]);

  useEffect(() => {
    if (device) {
      setFormData({
        thingName: device.thingName || "",
        thingDescription: device.thingDescription || "",
        address: device.address || "",
        thingManufacturerId: device.thingManufacturerId || "",
        longitude: device.longitude ?? "",
        latitude: device.latitude ?? "",
        altitude: device.altitude ?? "",
        integrationId: device.integrationId || "",
        levelId: device.levelId || "",
        primaryGroup: device.primaryGroup || "",
        manufacturer: device.manufacturer || "",
        model: device.model || "",
        isFirstParty: device.isFirstParty || false,
        placeType: placeType,
      });
      setProperties(device.properties || {});
      setAttributes(device.attributes || {});

      getThingPropertyKeys().then((keys) => {
        setAvailableProperties(keys[device.thingType] || []);
      });

      getThingManufacturerModels().then((data) => {
        setManufacturerModels(data);
        if (device.thingType && data[device.thingType]) {
          setAvailableManufacturers(Object.keys(data[device.thingType] || {}));
        }
      });
    }
  }, [device, placeType]);

  useEffect(() => {
    if (isOpen && device) {
      if (device.properties) {
        setProperties(device.properties);
      }
      if (device.attributes) {
        setAttributes(device.attributes);
      }

      setPropertyChanges({
        updates: {},
        deletes: [],
      });
      setAttributeChanges({
        updates: {},
        deletes: [],
      });
    }
  }, [isOpen, device]);

  useEffect(() => {
    if (
      device?.thingType &&
      formData.manufacturer &&
      manufacturerModels[device.thingType]
    ) {
      setAvailableModels(
        manufacturerModels[device.thingType][formData.manufacturer] || [],
      );
    } else {
      setAvailableModels([]);
    }
  }, [device?.thingType, formData.manufacturer, manufacturerModels]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.thingName) {
      newErrors.thingName = "Thing Name is required";
      valid = false;
    }

    if (!formData.thingDescription) {
      newErrors.thingDescription = "Description is required";
      valid = false;
    }

    if (formData.latitude === "") {
      newErrors.latitude = "Latitude is required";
      valid = false;
    } else {
      const lat = Number.parseFloat(formData.latitude);
      if (isNaN(lat) || lat < -90 || lat > 90) {
        newErrors.latitude = "Latitude must be between -90 and 90";
        valid = false;
      }
    }

    if (formData.longitude === "") {
      newErrors.longitude = "Longitude is required";
      valid = false;
    } else {
      const lng = Number.parseFloat(formData.longitude);
      if (isNaN(lng) || lng < -180 || lng > 180) {
        newErrors.longitude = "Longitude must be between -180 and 180";
        valid = false;
      }
    }

    if (formData.altitude === "") {
      newErrors.altitude = "Altitude is required";
      valid = false;
    } else {
      const alt = Number.parseFloat(formData.altitude);
      if (isNaN(alt)) {
        newErrors.altitude = "Altitude must be a number";
        valid = false;
      }
    }

    if (placeType === "Site" && !formData.levelId) {
      newErrors.levelId = "Level ID is required for Site devices";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handlePropertyChange = (key: string, value: string) => {
    setProperties((prev) => ({
      ...prev,
      [key]: value,
    }));

    setPropertyChanges((prev) => ({
      ...prev,
      updates: {
        ...prev.updates,
        [key]: value,
      },
    }));
  };

  const handleDeleteProperty = (key: string) => {
    if (
      !window.confirm(
        `Are you sure you want to delete the property "${key}"?\nChanges to properties may impact metric calculations.`,
      )
    ) {
      return;
    }

    setProperties((prev) => {
      const newProps = { ...prev };
      delete newProps[key];
      return newProps;
    });

    setPropertyChanges((prev) => ({
      updates: {
        ...prev.updates,
        ...(delete prev.updates[key] && {}),
      },
      deletes: [...new Set([...prev.deletes, key])],
    }));
  };

  const handleAddProperty = () => {
    if (!newPropertyKey || !newPropertyValue) {
      return;
    }

    setProperties((prev) => ({
      ...prev,
      [newPropertyKey]: newPropertyValue,
    }));

    setPropertyChanges((prev) => ({
      ...prev,
      updates: {
        ...prev.updates,
        [newPropertyKey]: newPropertyValue,
      },
    }));

    setNewPropertyKey("");
    setNewPropertyValue("");
  };

  const handleAttributeChange = (key: string, value: string) => {
    setAttributes((prev) => ({
      ...prev,
      [key]: value,
    }));

    setAttributeChanges((prev) => ({
      ...prev,
      updates: {
        ...prev.updates,
        [key]: value,
      },
    }));
  };

  const handleDeleteAttribute = (key: string) => {
    if (
      !window.confirm(`Are you sure you want to delete the attribute "${key}"?`)
    ) {
      return;
    }

    setAttributes((prev) => {
      const newAttrs = { ...prev };
      delete newAttrs[key];
      return newAttrs;
    });

    setAttributeChanges((prev) => ({
      updates: {
        ...prev.updates,
        ...(delete prev.updates[key] && {}),
      },
      deletes: [...new Set([...prev.deletes, key])],
    }));
  };

  const handleAddAttribute = () => {
    setErrorMessage("");

    if (!newAttributeKey && !newAttributeValue) {
      return;
    }

    if (!newAttributeKey) {
      setErrorMessage("Attribute key is required when value is provided");
      return;
    }
    if (!newAttributeValue) {
      setErrorMessage("Attribute value is required when key is provided");
      return;
    }

    if (Object.keys(attributes).includes(newAttributeKey)) {
      setErrorMessage("Attribute key already exists");
      return;
    }

    setAttributes((prev) => ({
      ...prev,
      [newAttributeKey]: newAttributeValue,
    }));

    setAttributeChanges((prev) => ({
      ...prev,
      updates: {
        ...prev.updates,
        [newAttributeKey]: newAttributeValue,
      },
    }));

    setNewAttributeKey("");
    setNewAttributeValue("");
    setErrorMessage("");
  };

  const handleNewAttributeKeyChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewAttributeKey(e.target.value);
    setErrorMessage("");
  };

  const handleNewAttributeValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewAttributeValue(e.target.value);
    setErrorMessage("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage("");

    if (!validateForm()) {
      return;
    }

    try {
      const updatedProperties = {
        ...properties,
        ...propertyChanges.updates,
      };

      const updatedAttributes = {
        ...attributes,
        ...attributeChanges.updates,
      };

      const requestBody = {
        ...formData,
        properties: updatedProperties,
        propertiesToDelete: propertyChanges.deletes,
        attributes: updatedAttributes,
        attributesToDelete: attributeChanges.deletes,
      };

      const thingResult = await editThing(
        placeType,
        placeId,
        device.thingId,
        requestBody,
      );

      if (!thingResult.success) {
        setErrorMessage(thingResult.error);
        return;
      }

      if (
        formData.primaryGroup &&
        formData.primaryGroup !== device.primaryGroup
      ) {
        const groupResult = await editThingPrimaryGroup(
          placeType,
          placeId,
          device.thingId,
          formData.primaryGroup,
        );

        if (!groupResult.success) {
          setErrorMessage(groupResult.error);
          return;
        }
      }

      await onDeviceEdited();
      onCloseEditModal();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleDelete = async () => {
    if (
      !window.confirm(
        `Are you sure you want to delete this device?\nThis action cannot be undone.`,
      )
    ) {
      return;
    }

    try {
      const result = await deleteThing(placeType, placeId, device.thingId);

      if (!result.success) {
        setErrorMessage(result.error);
        return;
      }

      onCloseEditModal();
      onCloseDeviceDetail();
      await onDeviceEdited();
      onClose();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[700px] max-h-[90vh] overflow-y-auto mx-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Edit Device</h2>
          <button
            type="button"
            onClick={handleDelete}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700"
          >
            Delete Device
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label
                className="block text-sm font-medium text-gray-700 mb-1"
                htmlFor="thingName"
              >
                Name*
              </label>
              <input
                type="text"
                name="thingName"
                id="thingName"
                value={formData.thingName}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.thingName ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.thingName && (
                <p className="text-red-500 text-xs mt-1">{errors.thingName}</p>
              )}
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700 mb-1"
                htmlFor="thingDescription"
              >
                Description*
              </label>
              <input
                type="text"
                name="thingDescription"
                id="thingDescription"
                value={formData.thingDescription}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.thingDescription ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.thingDescription && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.thingDescription}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <label
                className="block text-sm font-medium text-gray-700 mb-1"
                htmlFor="latitude"
              >
                Latitude*
              </label>
              <input
                type="number"
                name="latitude"
                id="latitude"
                value={formData.latitude}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.latitude ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.latitude && (
                <p className="text-red-500 text-xs mt-1">{errors.latitude}</p>
              )}
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700 mb-1"
                htmlFor="longitude"
              >
                Longitude*
              </label>
              <input
                type="number"
                name="longitude"
                id="longitude"
                value={formData.longitude}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.longitude ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.longitude && (
                <p className="text-red-500 text-xs mt-1">{errors.longitude}</p>
              )}
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700 mb-1"
                htmlFor="altitude"
              >
                Altitude*
              </label>
              <input
                type="number"
                name="altitude"
                id="altitude"
                value={formData.altitude}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.altitude ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.altitude && (
                <p className="text-red-500 text-xs mt-1">{errors.altitude}</p>
              )}
            </div>
          </div>

          <div>
            <label
              className="block text-sm font-medium text-gray-700 mb-1"
              htmlFor="address"
            >
              Address
            </label>
            <input
              type="text"
              name="address"
              id="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2 text-sm border-gray-300"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-1"
              htmlFor="thingManufacturerId"
            >
              Thing Manufacturer ID
            </label>
            <input
              type="text"
              name="thingManufacturerId"
              id="thingManufacturerId"
              value={formData.thingManufacturerId}
              onChange={handleChange}
              className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.thingManufacturerId ? "border-red-500" : ""
              }`}
            />
            {errors.thingManufacturerId && (
              <p className="text-red-500 text-xs italic">
                {errors.thingManufacturerId}
              </p>
            )}
          </div>

          {placeType === "Site" && (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="levelId"
              >
                Level ID*
              </label>
              <input
                type="text"
                name="levelId"
                id="levelId"
                value={formData.levelId}
                onChange={handleChange}
                className={`border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                  errors.levelId ? "border-red-500" : ""
                }`}
              />
              {errors.levelId && (
                <p className="text-red-500 text-xs italic">{errors.levelId}</p>
              )}
            </div>
          )}

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-1"
              htmlFor="primaryGroup"
            >
              Primary Group
            </label>
            <input
              type="text"
              name="primaryGroup"
              id="primaryGroup"
              value={formData.primaryGroup}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2 text-sm"
            />
          </div>

          <div className="grid grid-cols-8 gap-4 mb-4">
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                First Party
              </label>
              <select
                name="isFirstParty"
                value={formData.isFirstParty.toString()}
                onChange={(e) => {
                  const isFirstParty = e.target.value === "true";
                  handleChange({
                    target: {
                      name: "isFirstParty",
                      value: isFirstParty,
                    },
                  });
                  if (isFirstParty) {
                    setFormData((prev) => ({
                      ...prev,
                      manufacturer: "",
                      model: "",
                    }));
                  }
                }}
                className="w-full border rounded px-3 py-2 text-sm"
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>

            <div className="col-span-3">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Manufacturer
              </label>
              {formData.isFirstParty &&
              device?.thingType &&
              manufacturerModels[device.thingType] &&
              Object.keys(manufacturerModels[device.thingType]).length > 0 ? (
                <select
                  name="manufacturer"
                  value={formData.manufacturer}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2 text-sm"
                >
                  <option value="">Select Manufacturer</option>
                  {availableManufacturers.map((manufacturer) => (
                    <option key={manufacturer} value={manufacturer}>
                      {manufacturer}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  name="manufacturer"
                  value={formData.manufacturer}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2 text-sm"
                />
              )}
            </div>

            <div className="col-span-3">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Model
              </label>
              {formData.isFirstParty ? (
                <select
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2 text-sm disabled:bg-gray-100 disabled:text-gray-500"
                  disabled={!formData.manufacturer}
                >
                  <option value="">Select Model</option>
                  {availableModels.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2 text-sm"
                />
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Properties
            </label>
            <div className="border rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[45%]">
                      Property
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Value
                    </th>
                    <th className="px-4 py-2 w-16"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(properties).map(([key, value]) => (
                    <tr key={key}>
                      <td className="px-4 py-2 text-sm text-gray-900">{key}</td>
                      <td className="px-4 py-2">
                        <input
                          type="text"
                          value={value}
                          onChange={(e) =>
                            handlePropertyChange(key, e.target.value)
                          }
                          className="w-full border px-2 py-1 text-sm"
                        />
                      </td>
                      <td className="px-4 py-2 text-center">
                        <button
                          type="button"
                          onClick={() => handleDeleteProperty(key)}
                          className="text-red-600"
                        >
                          <DeleteIcon className="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td className="px-4 py-2">
                      <select
                        value={newPropertyKey}
                        onChange={(e) => setNewPropertyKey(e.target.value)}
                        className="w-full border px-2 py-1 text-sm"
                        disabled={!availableProperties}
                      >
                        <option value="">
                          {!availableProperties
                            ? "Loading..."
                            : availableProperties.length === 0
                              ? "No properties"
                              : "Select Property"}
                        </option>
                        {availableProperties
                          ?.filter(
                            (key) => !Object.keys(properties).includes(key),
                          )
                          .map((key) => (
                            <option key={key} value={key}>
                              {key}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td className="px-4 py-2">
                      <input
                        type="text"
                        value={newPropertyValue}
                        onChange={(e) => setNewPropertyValue(e.target.value)}
                        placeholder="Value"
                        className="w-full border px-2 py-1 text-sm"
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <button
                        type="button"
                        onClick={handleAddProperty}
                        disabled={!newPropertyKey || !newPropertyValue}
                        className="px-3 py-1 bg-blue-600 text-white rounded text-sm disabled:bg-gray-300"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Attributes
            </label>
            <div className="border rounded-lg overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[45%]">
                      Attribute
                    </th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Value
                    </th>
                    <th className="px-4 py-2 w-16"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.entries(attributes).map(([key, value]) => (
                    <tr key={key}>
                      <td className="px-4 py-2 text-sm text-gray-900">{key}</td>
                      <td className="px-4 py-2">
                        <input
                          type="text"
                          value={value}
                          onChange={(e) =>
                            handleAttributeChange(key, e.target.value)
                          }
                          className="w-full border px-2 py-1 text-sm"
                        />
                      </td>
                      <td className="px-4 py-2 text-center">
                        <button
                          type="button"
                          onClick={() => handleDeleteAttribute(key)}
                          className="text-red-600"
                        >
                          <DeleteIcon className="h-4 w-4" />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td className="px-4 py-2">
                      <input
                        type="text"
                        value={newAttributeKey}
                        onChange={handleNewAttributeKeyChange}
                        placeholder="Enter attribute name"
                        className="w-full border px-2 py-1 text-sm"
                      />
                    </td>
                    <td className="px-4 py-2">
                      <input
                        type="text"
                        value={newAttributeValue}
                        onChange={handleNewAttributeValueChange}
                        placeholder="Value"
                        className="w-full border px-2 py-1 text-sm"
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <button
                        type="button"
                        onClick={handleAddAttribute}
                        disabled={!newAttributeKey || !newAttributeValue}
                        className="px-3 py-1 bg-blue-600 text-white rounded text-sm disabled:bg-gray-300"
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {(Object.keys(propertyChanges.updates).length > 0 ||
            propertyChanges.deletes.length > 0) && (
            <div className="text-red-600 text-sm flex items-center gap-2">
              <WarningIcon className="h-4 w-4" />
              Warning: Changes to properties may impact metric calculations
            </div>
          )}

          {errorMessage && (
            <p className="text-red-500 text-sm">{errorMessage}</p>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onCloseEditModal}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default EditDeviceModal;
