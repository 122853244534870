import { Pin } from "components";
import { mapboxConfig } from "configs/mapbox";
import Map, { Marker } from "react-map-gl";

interface NetworkMapProps {
  latitude: number;
  longitude: number;
  Charger?: any[];
  Battery?: any[];
  Meter?: any[];
  SwapStation?: any[];
  [key: string]: any;
}

const deviceColors: { [key: string]: string } = {
  Charger: "#5ea15f",
  Battery: "#6db5d1",
  Meter: "#6db5d1",
  SwapStation: "#f2aa3c",
};

const NetworkMap = ({ latitude, longitude, ...devices }: NetworkMapProps) => {
  const isValidCoordinates = (lat: number, lng: number) => {
    if (typeof lat !== 'number' || typeof lng !== 'number' || isNaN(lat) || isNaN(lng)) {
      return false;
    }
    return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
  };

  const calculateCenterFromDevices = () => {
    const validCoords = [];
    
    Object.values(devices).forEach((deviceList: any[]) => {
      if (!deviceList) return;
      
      deviceList.forEach(device => {
        if (isValidCoordinates(device.latitude, device.longitude)) {
          validCoords.push({
            latitude: Number.parseFloat(device.latitude),
            longitude: Number.parseFloat(device.longitude)
          });
        }
      });
    });

    if (validCoords.length === 0) return null;

    const center = validCoords.reduce((acc, curr) => {
      return {
        latitude: acc.latitude + curr.latitude / validCoords.length,
        longitude: acc.longitude + curr.longitude / validCoords.length
      };
    }, { latitude: 0, longitude: 0 });

    return center;
  };

  const getMapCenter = () => {
    if (isValidCoordinates(latitude, longitude)) {
      return { latitude, longitude };
    }
    
    // If coordinates are invalid, try device coordinates
    const deviceCenter = calculateCenterFromDevices();
    if (deviceCenter) {
      return deviceCenter;
    }

    return null;
  };

  const Markers = (devices: any[], deviceType: string) => {
    if (!devices) return null;
    
    return devices.map((device) => {
      if (!isValidCoordinates(device.latitude, device.longitude)) return null;
      
      return (
        <Marker
          longitude={Number.parseFloat(device.longitude)}
          latitude={Number.parseFloat(device.latitude)}
          anchor="center"
          key={device.thingId}
        >
          <Pin color={deviceColors[deviceType]} id={device.thingName.slice(-1)} />
        </Marker>
      );
    });
  };

  const mapCenter = getMapCenter();

  if (!mapCenter) {
    return (
      <div className="flex-1 bg-white rounded-xl overflow-hidden h-full flex items-center justify-center">
        <p className="text-gray-500">No valid coordinates found</p>
      </div>
    );
  }

  return (
    <div className="flex-1 bg-white rounded-xl overflow-hidden h-full">
      <Map
        key={`${mapCenter.latitude}-${mapCenter.longitude}`}
        initialViewState={{
          longitude: mapCenter.longitude,
          latitude: mapCenter.latitude,
          zoom: 13,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/light-v11"
        mapboxAccessToken={mapboxConfig.token}
      >
        {Object.entries(devices).map(([deviceType, deviceList]) => 
          deviceColors[deviceType] ? Markers(deviceList, deviceType) : null
        )}
      </Map>
    </div>
  );
};

export default NetworkMap;