import { useThingsApi } from "api/ingestion/things";

import { useCallback, useState } from "react";

interface PolicyState {
  policyDetails: Policy | null;
  selectedPolicy: PolicyDocument | null;
  error: string | null;
  isLoading: boolean;
}

interface Policy {
  connectionEndpoints: string[];
  subscribeTopics: string[];
  publishTopics: string[];
}

interface PolicyDocument {
  Version: string;
  Statement: Array<{
    Effect: string;
    Action: string | string[];
    Resource: string | string[];
    Condition?: Record<string, Record<string, string>>;
  }>;
}

const INITIAL_STATE: PolicyState = {
  policyDetails: null,
  selectedPolicy: null,
  error: null,
  isLoading: false,
};

export const usePolicy = () => {
  const [state, setState] = useState<PolicyState>(INITIAL_STATE);
  const { getDefaultPolicy, updateThingPolicy } = useThingsApi();

  const getResourceValue = useCallback(
    (resource: string, type: "client" | "topic" | "topicfilter"): string => {
      const parts = resource.split(`${type}/`);
      return parts.length > 1 ? parts[1] : "";
    },
    [],
  );

  const handleViewPolicy = useCallback(
    async (
      placeType: string,
      siteId: string,
      thingId: string,
      policyArn: string,
    ) => {
      setState((prev) => ({ ...prev, isLoading: true, error: null }));

      try {
        if (!placeType || !siteId || !thingId) {
          throw new Error("Missing required parameters");
        }

        const policyResponse = await getDefaultPolicy(
          placeType,
          siteId,
          thingId,
        );

        if (!policyResponse) {
          throw new Error("No policy found");
        }

        if (!policyResponse.Statement) {
          throw new Error("Invalid policy format: missing Statement");
        }

        // Transform the policy into the expected format
        const transformedPolicy = {
          connectionEndpoints: policyResponse.Statement.filter(
            // Only include Connect statements without conditional ThingName
            (stmt) => {
              const isConnectAction =
                stmt.Action === "iot:Connect" ||
                (Array.isArray(stmt.Action) &&
                  stmt.Action.includes("iot:Connect"));
              const resources = Array.isArray(stmt.Resource)
                ? stmt.Resource
                : [stmt.Resource];
              // Check if any resource contains ThingName variable
              const hasThingName = resources.some((r) =>
                r.includes("${iot:Connection.Thing.ThingName}"),
              );
              return isConnectAction && !hasThingName;
            },
          )
            .flatMap((stmt) => {
              const resources = Array.isArray(stmt.Resource)
                ? stmt.Resource
                : [stmt.Resource];
              return resources.map((resource) =>
                getResourceValue(resource, "client"),
              );
            })
            .filter(Boolean),

          // Rest remains the same
          subscribeTopics: policyResponse.Statement.filter(
            (stmt) =>
              (Array.isArray(stmt.Action) &&
                stmt.Action.includes("iot:Subscribe")) ||
              stmt.Action === "iot:Subscribe",
          )
            .flatMap((stmt) =>
              Array.isArray(stmt.Resource) ? stmt.Resource : [stmt.Resource],
            )
            .map((resource) => getResourceValue(resource, "topicfilter"))
            .filter(Boolean),

          publishTopics: policyResponse.Statement.filter(
            (stmt) =>
              (Array.isArray(stmt.Action) &&
                stmt.Action.includes("iot:Publish")) ||
              stmt.Action === "iot:Publish",
          )
            .flatMap((stmt) =>
              Array.isArray(stmt.Resource) ? stmt.Resource : [stmt.Resource],
            )
            .map((resource) => getResourceValue(resource, "topic"))
            .filter(Boolean),
        };

        setState((prev) => ({
          ...prev,
          policyDetails: transformedPolicy,
          selectedPolicy: policyResponse,
          isLoading: false,
          error: null,
        }));
      } catch (error) {
        console.error("Error fetching policy details:", error);
        setState((prev) => ({
          ...prev,
          error: error instanceof Error ? error.message : "An error occurred",
          isLoading: false,
          policyDetails: null,
          selectedPolicy: null,
        }));
      }
    },
    [getDefaultPolicy, getResourceValue],
  );

  const handleDeleteResource = useCallback(
    async (
      placeType: string,
      siteId: string,
      thingId: string,
      type: "connect" | "subscribe" | "publish",
      value: string,
    ) => {
      setState((prev) => ({ ...prev, isLoading: true, error: null }));

      try {
        if (!state.selectedPolicy) {
          throw new Error("No policy selected");
        }

        const updatedPolicy = JSON.parse(JSON.stringify(state.selectedPolicy));

        const anyResource = updatedPolicy.Statement[0]?.Resource;
        if (!anyResource) {
          throw new Error("Invalid policy: no resources found");
        }

        const resource = Array.isArray(anyResource)
          ? anyResource[0]
          : anyResource;
        const match = resource.match(/arn:aws:iot:([^:]+):([^:]+):/);
        if (!match) {
          throw new Error("Could not determine the correct ARN pattern");
        }

        const [, region, account] = match;
        const baseArn = `arn:aws:iot:${region}:${account}`;

        switch (type) {
          case "connect": {
            const connectStmt = updatedPolicy.Statement.find(
              (stmt) => stmt.Action === "iot:Connect" && !stmt.Condition,
            );

            if (connectStmt) {
              if (Array.isArray(connectStmt.Resource)) {
                connectStmt.Resource = connectStmt.Resource.filter(
                  (r: string) => r !== `${baseArn}:client/${value}`,
                );

                if (connectStmt.Resource.length === 0) {
                  updatedPolicy.Statement = updatedPolicy.Statement.filter(
                    (stmt) => stmt !== connectStmt,
                  );
                }
              }
            }
            break;
          }

          case "subscribe": {
            const subscribeStmt = updatedPolicy.Statement.find(
              (stmt) =>
                (Array.isArray(stmt.Action) &&
                  stmt.Action.includes("iot:Subscribe")) ||
                stmt.Action === "iot:Subscribe",
            );

            if (subscribeStmt) {
              if (Array.isArray(subscribeStmt.Resource)) {
                subscribeStmt.Resource = subscribeStmt.Resource.filter(
                  (r: string) => r !== `${baseArn}:topicfilter/${value}`,
                );

                if (subscribeStmt.Resource.length === 0) {
                  updatedPolicy.Statement = updatedPolicy.Statement.filter(
                    (stmt) => stmt !== subscribeStmt,
                  );
                }
              }
            }
            break;
          }

          case "publish": {
            const publishStmt = updatedPolicy.Statement.find(
              (stmt) =>
                (Array.isArray(stmt.Action) &&
                  stmt.Action.includes("iot:Publish")) ||
                stmt.Action === "iot:Publish",
            );

            if (publishStmt) {
              if (Array.isArray(publishStmt.Resource)) {
                publishStmt.Resource = publishStmt.Resource.filter(
                  (r: string) => r !== `${baseArn}:topic/${value}`,
                );

                if (Array.isArray(publishStmt.Action)) {
                  const receiveIndex =
                    publishStmt.Action.indexOf("iot:Receive");
                  if (receiveIndex !== -1) {
                    publishStmt.Action.splice(receiveIndex, 1);
                  }
                }

                if (publishStmt.Resource.length === 0) {
                  updatedPolicy.Statement = updatedPolicy.Statement.filter(
                    (stmt) => stmt !== publishStmt,
                  );
                }
              }
            }
            break;
          }
        }

        updatedPolicy.Statement = updatedPolicy.Statement.filter((stmt) =>
          Array.isArray(stmt.Resource)
            ? stmt.Resource.length > 0
            : stmt.Resource,
        );

        const result = await updateThingPolicy(
          placeType,
          siteId,
          thingId,
          updatedPolicy,
        );

        if (!result.success) {
          throw new Error(result.error || "Failed to update policy");
        }

        await handleViewPolicy(
          placeType,
          siteId,
          thingId,
          updatedPolicy.Statement[0]?.Resource[0] || "",
        );

        setState((prev) => ({ ...prev, isLoading: false, error: null }));
        return true;
      } catch (error) {
        console.error("Error deleting resource:", error);

        setState((prev) => ({
          ...prev,
          error:
            error instanceof Error
              ? error.message
              : "Failed to delete resource",
          isLoading: false,
        }));

        return false;
      }
    },
    [state.selectedPolicy, updateThingPolicy, handleViewPolicy],
  );

  const handleUpdatePolicy = useCallback(
    async (
      placeType: string,
      siteId: string,
      thingId: string,
      policyDocument: PolicyDocument,
    ) => {
      setState((prev) => ({ ...prev, isLoading: true, error: null }));

      try {
        const result = await updateThingPolicy(
          placeType,
          siteId,
          thingId,
          policyDocument,
        );

        if (!result.success) {
          throw new Error(result.error || "Failed to update policy");
        }

        await handleViewPolicy(
          placeType,
          siteId,
          thingId,
          policyDocument.Statement[0]?.Resource[0] || "",
        );

        return true;
      } catch (error) {
        console.error("Error updating policy:", error);

        setState((prev) => ({
          ...prev,
          error:
            error instanceof Error ? error.message : "Failed to update policy",
          isLoading: false,
        }));

        return false;
      }
    },
    [handleViewPolicy, updateThingPolicy],
  );

  const clearError = useCallback(() => {
    setState((prev) => ({ ...prev, error: null }));
  }, []);

  return {
    ...state,
    setPolicyDetails: useCallback((details: Policy | null) => {
      setState((prev) => ({ ...prev, policyDetails: details }));
    }, []),
    setSelectedPolicy: useCallback((policy: PolicyDocument | null) => {
      setState((prev) => ({ ...prev, selectedPolicy: policy }));
    }, []),
    handleViewPolicy,
    handleDeleteResource,
    handleUpdatePolicy,
    clearError,
  };
};
