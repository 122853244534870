import { type AlertEvent, useEnterpriseApi } from "api/enterprise";
import { type Thing, useThingsApi } from "api/ingestion/things";
import { useSelectedDevice } from "context/SelectedDeviceContext";
import type { Dayjs } from "dayjs";

import React, { useEffect, useState } from "react";
import { IoMdBatteryCharging } from "react-icons/io";
import { MdOutlinePower } from "react-icons/md";

export const AlertRow = ({
  alert,
  onSelect,
}: {
  alert: AlertEvent;
  onSelect?: (thingId: string) => void;
}) => {
  const {
    monitorId,
    resourceId,
    resourceType,
    time,
    eventId,
    alertState,
    alertSeverity,
    alertMessage,
    condition,
  } = alert;

  // TODO: should we just get rid of the icon?
  const icon =
    resourceType === "THING.SwapStation" ? (
      <IoMdBatteryCharging fontSize={20} />
    ) : (
      // else "stationAlarm"
      <MdOutlinePower fontSize={20} />
    );

  const timestamp = new Date(time).toLocaleString();

  return (
    <div
      className={`p-2 bg-yellow50 rounded flex flex-row gap-4 text-white text-body ${
        onSelect ? "cursor-pointer hover:bg-yellow60" : ""
      }`}
      onClick={() => onSelect?.(resourceId)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onSelect?.(resourceId);
        }
      }}
    >
      {/* <div>{JSON.stringify(alert)}</div> */}
      <div>{icon}</div>
      <div className="flex-row flex-1">
        <div className="flex-1">
          {alertState} {alertMessage} (Severity: {alertSeverity})
        </div>
        <div className="text-xs opacity-70">Condition: {condition}</div>
        <div className="text-xs opacity-70">{timestamp}</div>
      </div>
    </div>
  );
};

export const PlaceAlerts = ({
  placeType,
  placeId,
  partnerId,
  start,
  end,
  // simulationId,
}: {
  placeType: string;
  placeId: string;
  partnerId?: string; // TODO: technically not optional currently
  start: Dayjs;
  end: Dayjs;
  // simulationId: string | null;
}) => {
  const { getMonitorData } = useEnterpriseApi();
  const { setSelectedDevice } = useSelectedDevice();
  const { getThingsFromPlace } = useThingsApi();

  const [alerts, setAlerts] = useState<AlertEvent[] | undefined>(undefined);
  const [things, setThings] = useState<Thing[]>([]);
  const [error, setError] = useState<Error | undefined>(undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies: adding getMonitorData to the dependency array causes a re-render loop
  useEffect(() => {
    getMonitorData(placeType, placeId, partnerId, start, end)
      .then((data) => {
        setAlerts(
          data
            .flatMap((d) => d.alertEvents)
            .filter((e) => e.alertState !== "HEALTHY"),
        );
      })
      .catch((error) => {
        console.error("Error fetching alerts:", error);
        setError(error);
      });
  }, [placeType, placeId, partnerId, start, end]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: adding getThingsFromPlace to the dependency array causes a re-render loop
  useEffect(() => {
    // pre-load thing details for each alert
    getThingsFromPlace(placeType, placeId).then(setThings);
  }, [placeType, placeId]);

  const handleOnClick = (thingId: string) => {
    console.log("thingId", thingId);
    console.log("things", things);
    const device = things.find((thing) => thing.thingId === thingId);
    console.log("device", device);
    setSelectedDevice(device);
  };

  // TODO: re-enable this
  // if (error) {
  //   return (
  //     <div className="text-caption text-center text-red50 py-4">
  //       Error loading alerts: {error.message}
  //     </div>
  //   );
  // }

  return (
    <div className="overflow-auto h-full">
      <p className="text-space50 text-heading3 mb-4">Alerts</p>
      <div className="flex flex-col gap-2 pt-2">
        {error ? (
          <div className="text-caption text-center text-space70 py-4">
            No alerts for selected timeframe
          </div>
        ) : !alerts ? (
          <div className="text-caption text-center text-space70 py-4">
            Loading...
          </div>
        ) : alerts.length > 0 ? (
          alerts.map((alert) => (
            <AlertRow
              alert={alert}
              key={alert.alertId}
              onSelect={handleOnClick}
            />
          ))
        ) : (
          <div className="text-caption text-center text-space70 py-4">
            No alerts for selected timeframe
          </div>
        )}
      </div>
    </div>
  );
};
