import { useAuthFetch } from "context/AuthContext";

import { BASE_URL, type GenericAttributes } from "./common";

// Base response type excluding dates
export type RawSimulationResponseExcludingDates = {
  simulationId: string;
  partnerId: string;
  siteThingsMap: {
    [key: string]: string[];
  };
  fleetThingsMap: {
    [key: string]: string[];
  };
  isRangeBased: boolean;
  runIntervalMinutes: number;
  isEnabled: boolean;
  policies: {
    [key: string]: string;
  };
  attributes: GenericAttributes;
  dataCursor: string;
  simulationRunSucceeded: boolean;
  s3WriteSucceeded: boolean;
  timestreamWriteSucceeded: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
};

// API response type including dates
export type RawSimulationResponse = RawSimulationResponseExcludingDates & {
  startDate: string;
  endDate: string | null;
};

// FE consumable type
export type SimulationResponse = RawSimulationResponseExcludingDates & {
  startDate: Date;
  endDate: Date | null;
};

const _convertResponse = (
  response: RawSimulationResponse,
): SimulationResponse => {
  return {
    ...response,
    startDate: new Date(response.startDate),
    endDate: response.endDate ? new Date(response.endDate) : null,
  };
};

export const useSimulationsApi = () => {
  const { authFetch } = useAuthFetch();

  const getSimulations = async (): Promise<SimulationResponse[]> => {
    const url = `${BASE_URL}/simulations`;
    return await authFetch(url, {
      method: "GET",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error, status: ${res.status}`);
        }
        return res.json();
      })
      .then((json) => json.map(_convertResponse));
  };

  const getSimulationsForPlace = async (
    placeId: string,
    placeType: "site" | "fleet",
  ): Promise<SimulationResponse[]> => {
    const url = `${BASE_URL}/simulations/${placeType}/${placeId}`;
    return await authFetch(url, {
      method: "GET",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error, status: ${res.status}`);
        }
        return res.json();
      })
      .then((json) => json.map(_convertResponse));
  };

  const getSimulationDetail = async (
    simulationId: string,
  ): Promise<SimulationResponse> => {
    const url = `${BASE_URL}/simulation/${simulationId}`;
    return await authFetch(url, {
      method: "GET",
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error, status: ${res.status}`);
        }
        return res.json();
      })
      .then(_convertResponse);
  };

  return {
    getSimulations,
    getSimulationsForPlace,
    getSimulationDetail,
  };
};
