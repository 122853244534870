import { useAuthFetch } from "context/AuthContext";

import { BASE_URL } from "./common";

export type Role = {
  id: string;
  name: string;
  description: string;
};

export type Invite = {
  id: string;
  organizationId: string;
  inviter: {
    name: string;
  };
  invitee: {
    email: string;
  };
  invitationUrl: string;
  createdAt: string;
  expiresAt: string;
  ticketId: string;
  roles: string[];
};

export type UpdateResponse = {
  message: string;
};

export type InviteUserResponse = UpdateResponse & {
  invitationUrl: string;
};

export type Organization = {
  id: string;
  name: string;
  displayName: string;
  branding?: {
    logoUrl?: string;
  };
};

export type UserDetails = {
  userId: string;
  picture: string;
  name: string;
  email: string;
};

export type UserInformation = {
  createdAt: string;
  lastIpAddress: string;
  lastLogin: string;
  lastPasswordReset: string;
  locale: string | null;
  loginsCount: string;
  updatedAt: string;
  userId: string;
  multifactor: string[];
  appMetadata: string | null;
  email: string;
  emailVerified: boolean;
  phoneNumber: string | null;
  phoneVerified: boolean | null;
  userMetadata: string | null;
  userName: string | null;
  nickName: string;
  firstName: string;
  fullName: string;
  lastName: string;
  picture: string;
  blocked: boolean | null;
};

export const useIdentityApi = () => {
  const { authFetch } = useAuthFetch();

  const getRoles = async (page = 0, pageSize = 50): Promise<Role[]> => {
    const url = `${BASE_URL}/identity/roles?pageNo=${page}&perPage=${pageSize}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => res.json());
  };

  const inviteUser = async (
    email: string,
    inviterName: string,
    roleIds: string[],
  ): Promise<InviteUserResponse> => {
    const url = `${BASE_URL}/identity/user/invitation`;
    const response = await authFetch(url, {
      method: "POST",
      body: JSON.stringify({
        inviteeEmail: email,
        inviterName,
        roles: roleIds,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.message);
    }
    return json;
  };

  const getInvites = async (page = 0, pageSize = 50): Promise<Invite[]> => {
    const url = `${BASE_URL}/identity/user/invitations?pageNo=${page}&perPage=${pageSize}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => res.json());
  };

  const deleteInvite = async (inviteId: string): Promise<UpdateResponse> => {
    const url = `${BASE_URL}/identity/user/invitation/${inviteId}`;
    const response = await authFetch(url, {
      method: "DELETE",
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.message);
    }
    return json;
  };

  const getUserDetails = async (userId: string): Promise<UserInformation> => {
    const url = `${BASE_URL}/identity/user/${userId}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => res.json());
  };

  const updateUserDetails = async (
    userId: string,
    userDetails: Partial<UserInformation>,
  ): Promise<string> => {
    const url = `${BASE_URL}/identity/user/${userId}`;
    return await authFetch(url, {
      method: "PUT",
      body: JSON.stringify(userDetails),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.text();
    });
  };

  const deleteUser = async (userId: string): Promise<boolean> => {
    const url = `${BASE_URL}/identity/user/${userId}`;
    return await authFetch(url, {
      method: "DELETE",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return true;
    });
  };

  const updateEmailForUser = async (
    userId: string,
    email: string,
  ): Promise<boolean> => {
    const url = `${BASE_URL}/identity/user/${userId}/email`;
    return await authFetch(url, {
      method: "PUT",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return true;
    });
  };

  const getRolesForUser = async (
    userId: string,
    page = 0,
    pageSize = 50,
  ): Promise<Role[]> => {
    const url = `${BASE_URL}/identity/user/${userId}/roles?pageNo=${page}&perPage=${pageSize}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => res.json());
  };

  const addRolesForUser = async (
    userId: string,
    roleIds: string[],
  ): Promise<UpdateResponse> => {
    const url = `${BASE_URL}/identity/user/${userId}/roles`;
    const response = await authFetch(url, {
      method: "PATCH",
      body: JSON.stringify({ roleIds }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.message);
    }
    return json;
  };

  const deleteRolesForUser = async (
    userId: string,
    roleIds: string[],
  ): Promise<UpdateResponse> => {
    const url = `${BASE_URL}/identity/user/${userId}/roles`;
    const response = await authFetch(url, {
      method: "DELETE",
      body: JSON.stringify({ roleIds }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      throw new Error(json.message);
    }
    return json;
  };

  // UNTESTED
  const resetPasswordForUser = async (userId: string): Promise<void> => {
    const url = `${BASE_URL}/identity/user/${userId}/password-reset`;
    return await authFetch(url, {
      method: "POST",
    }).then((res) => res.json());
  };

  const getOrganization = async (orgId: string): Promise<Organization> => {
    const url = `${BASE_URL}/identity/organization/${orgId}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => res.json());
  };

  const getUsersForOrganization = async (
    organizationId: string,
    page = 0,
    pageSize = 50,
  ): Promise<UserDetails[]> => {
    const url = `${BASE_URL}/identity/organization/${organizationId}/users?pageNo=${page}&perPage=${pageSize}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => res.json());
  };

  return {
    getRoles,
    inviteUser,
    getInvites,
    deleteInvite,
    getUserDetails,
    updateUserDetails,
    deleteUser,
    updateEmailForUser,
    getRolesForUser,
    addRolesForUser,
    deleteRolesForUser,
    resetPasswordForUser,
    getUsersForOrganization,
    getOrganization,
  };
};
