import { useAuthFetch } from "context/AuthContext";

import { BASE_URL, type GenericAttributes } from "./common";

type PolicyDocument = {
  Version: string;
  Statement: Array<{
    Effect: string;
    Action: string | string[];
    Resource: string | string[];
    Condition?: {
      Bool?: {
        [key: string]: string;
      };
    };
  }>;
};

interface EditThingData {
  thingName?: string;
  thingDescription?: string;
  address?: string;
  thingManufacturerId?: string;
  longitude?: number;
  latitude?: number;
  altitude?: number;
  integrationId?: string;
  levelId?: string;
  placeType?: string;
}

export type Thing = {
  thingId: string;
  placeType: string | null;
  thingName: string;
  thingType: string;
  thingDescription: string;
  model: string;
  isSimulated: boolean;
  longitude: number;
  latitude: number;
  altitude: number;
  integrationId: string;
  partnerId: string;
  siteId: string;
  fleetId?: string;
  levelId: string;
  attributes: GenericAttributes;
  createdAt: string;
  createdBy: string;
};

export const useThingsApi = () => {
  const { authFetch } = useAuthFetch();

  const getThings = async (): Promise<Thing[]> => {
    const url = `${BASE_URL}/things`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingTypes = async (): Promise<string[]> => {
    const url = `${BASE_URL}/things/types`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingsFromSite = async (siteId: string): Promise<Thing[]> => {
    const url = `${BASE_URL}/site/${siteId}/things`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingsFromFleet = async (fleetId: string): Promise<Thing[]> => {
    const url = `${BASE_URL}/fleet/${fleetId}/things`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingsFromPlace = async (
    placeType: string,
    placeId: string,
  ): Promise<Thing[]> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/things`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingFromFleet = async (
    fleetId: string,
    thingId: string,
  ): Promise<Thing> => {
    const url = `${BASE_URL}/fleet/${fleetId}/thing/${thingId}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingFromSite = async (
    siteId: string,
    thingId: string,
  ): Promise<Thing> => {
    const url = `${BASE_URL}/site/${siteId}/thing/${thingId}`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingsFromLevelGroup = async (
    siteId: string,
    levelGroupId: string,
  ): Promise<Thing[]> => {
    const url = `${BASE_URL}/site/${siteId}/levelGroup/${levelGroupId}/things?onlyIds=false`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingsForLevel = async (
    siteId: string,
    levelId: string,
  ): Promise<Thing[]> => {
    const url = `${BASE_URL}/site/${siteId}/level/${levelId}/things?onlyIds=false`;
    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const addDevice = async (
    placeType: string,
    placeId: string,
    deviceData: Thing,
  ): Promise<{ success: boolean; message?: string; error?: string }> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing`;

    const requestBody = {
      thingName: deviceData.thingName,
      thingType: deviceData.thingType,
      thingDescription: deviceData.thingDescription,
      address: deviceData.address || "",
      model: deviceData.model || "",
      manufacturer: deviceData.manufacturer || "",
      isFirstParty: deviceData.isFirstParty || false,
      thingManufacturerId: deviceData.thingManufacturerId || "",
      isSimulated: deviceData.isSimulated || false,
      longitude: deviceData.longitude ? Number(deviceData.longitude) : 0,
      latitude: deviceData.latitude ? Number(deviceData.latitude) : 0,
      altitude: deviceData.altitude ? Number(deviceData.altitude) : 0,
      levelId: deviceData.levelId || "",
      attributes: deviceData.attributes || {},
      properties: deviceData.properties || {},
      primaryGroup: deviceData.primaryGroup || "",
    };

    try {
      const response = await authFetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 201) {
        return { success: true, message: "Device added successfully" };
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.title || "Failed to add device");
      }
      return { success: true };
    } catch (error) {
      console.error("Error adding device:", error);
      return { success: false, error: error.message };
    }
  };

  const editThing = async (
    placeType: string,
    placeId: string,
    thingId: string,
    data: EditThingData,
  ): Promise<{ success: boolean; message?: string; error?: string }> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}`;

    // Remove levelId if placeType is Fleet
    const requestData =
      placeType === "Fleet" ? { ...data, levelId: undefined } : data;

    try {
      const response = await authFetch(url, {
        method: "PATCH",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        return { success: true, message: "Thing updated successfully" };
      }
      const errorData = await response.text();
      console.error("Error updating thing:", errorData);
      return { success: false, error: errorData };
    } catch (error) {
      console.error("Error updating thing:", error);
      return { success: false, error: error.message };
    }
  };

  const editThingPrimaryGroup = async (
    placeType: string,
    placeId: string,
    thingId: string,
    groupName: string,
  ): Promise<{ success: boolean; message?: string; error?: string }> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/primary-group`;

    try {
      const response = await authFetch(url, {
        method: "PATCH",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(groupName),
      });

      if (response.ok) {
        return { success: true, message: "Primary group updated successfully" };
      }
      const errorData = await response.text();
      console.error("Error updating primary group:", errorData);
      return { success: false, error: errorData };
    } catch (error) {
      console.error("Error updating primary group:", error);
      return { success: false, error: error.message };
    }
  };

  const setDefaultPolicy = async (
    placeType: string,
    placeId: string,
    thingId: string,
  ): Promise<{ success: boolean; message?: string; error?: string }> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/policy/default`;

    try {
      const response = await authFetch(url, {
        method: "POST",
      });

      if (response.status === 200) {
        return { success: true };
      }
      const errorText = await response.text();
      let errorMessage: string;
      try {
        const errorData = JSON.parse(errorText);
        errorMessage = errorData.message || errorData.error || errorText;
      } catch {
        errorMessage = errorText;
      }
      return { success: false, error: errorMessage };
    } catch (error) {
      console.error("Error setting default policy:", error);
      return { success: false, error: error.message };
    }
  };

  const getDefaultPolicy = async (
    placeType: string,
    placeId: string,
    thingId: string,
  ): Promise<PolicyDocument> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/policy/default`;

    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const getThingCertificates = async (
    placeType: string,
    placeId: string,
    thingId: string,
  ): Promise<string[]> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/certificates`;

    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const updateThingPolicy = async (
    placeType: string,
    placeId: string,
    thingId: string,
    policyDocument: PolicyDocument,
  ): Promise<{ success: boolean; message?: string; error?: string }> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/policy`;

    try {
      const response = await authFetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(policyDocument),
      });

      if (response.ok) {
        return { success: true, message: "Policy updated successfully" };
      }
      const errorData = await response.json();
      console.error("Error updating policy:", errorData);
      return { success: false, error: errorData };
    } catch (error) {
      console.error("Error updating policy:", error);
      return { success: false, error: error.message };
    }
  };

  const getCertificatePolicies = async (
    placeType: string,
    placeId: string,
    thingId: string,
    certId: string,
  ): Promise<string[]> => {
    const encodedCertId = encodeURIComponent(certId);
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/certificate/${encodedCertId}/policies`;

    return await authFetch(url, {
      method: "GET",
    }).then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error, status: ${res.status}`);
      }
      return res.json();
    });
  };

  const createDefaultPolicyCertificate = async (
    placeType: string,
    placeId: string,
    thingId: string,
  ): Promise<{ success: boolean; message?: string; error?: string }> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/policy/default/certificate`;

    try {
      const response = await authFetch(url, {
        method: "POST",
      });

      if (response.status === 200) {
        const responseText = await response.text();
        try {
          const parsedResponse = JSON.parse(responseText);
          return { success: true, message: parsedResponse };
        } catch (parseError) {
          return { success: true, message: responseText };
        }
      } else {
        const errorData = await response.text();
        console.error("Error creating default policy certificate:", errorData);
        return { success: false, error: errorData };
      }
    } catch (error) {
      console.error("Error creating default policy certificate:", error);
      return { success: false, error: error.message };
    }
  };

  const deleteCertificate = async (
    placeType: string,
    placeId: string,
    thingId: string,
    certId: string,
  ): Promise<{ success: boolean; message?: string; error?: string }> => {
    const encodedCertId = encodeURIComponent(certId);
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}/certificate/${encodedCertId}`;

    try {
      const response = await authFetch(url, {
        method: "DELETE",
        headers: {
          accept: "*/*",
        },
      });

      if (response.ok) {
        return { success: true, message: "Certificate deleted successfully" };
      }
      const errorData = await response.text();
      console.error("Error deleting certificate:", errorData);
      return { success: false, error: errorData };
    } catch (error) {
      console.error("Error deleting certificate:", error);
      return { success: false, error: error.message };
    }
  };

  const deleteThing = async (
    placeType: string,
    placeId: string,
    thingId: string,
  ): Promise<{ success: boolean; message?: string; error?: string }> => {
    const url = `${BASE_URL}/${placeType}/${placeId}/thing/${thingId}`;

    try {
      const response = await authFetch(url, {
        method: "DELETE",
        headers: {
          accept: "*/*",
        },
      });

      if (response.ok) {
        return { success: true, message: "Device deleted successfully" };
      }
      const errorData = await response.text();
      console.error("Error deleting device:", errorData);
      return { success: false, error: errorData };
    } catch (error) {
      console.error("Error deleting device:", error);
      return { success: false, error: error.message };
    }
  };

  return {
    getThings,
    getThingTypes,
    getThingsFromPlace,
    getThingsFromSite,
    getThingFromSite,
    getThingsFromFleet,
    getThingFromFleet,
    getThingsFromLevelGroup,
    getThingsForLevel,
    addDevice,
    editThing,
    deleteThing,
    editThingPrimaryGroup,
    setDefaultPolicy,
    getDefaultPolicy,
    getThingCertificates,
    updateThingPolicy,
    getCertificatePolicies,
    createDefaultPolicyCertificate,
    deleteCertificate,
  };
};
