import {
  type DatapointMap,
  type Timeseries,
  convertToTimeseries,
  datapointsToMap,
  useDataApi,
} from "api/data";
import type { FleetResponse, SiteResponse } from "api/ingestion/places";
import { type Thing, useThingsApi } from "api/ingestion/things";
import { MicroHorizontalTimeseriesChart } from "components/charts/horizontalTimeSeries";
import { dayjs } from "utils/dayjs";

import React, { useEffect, useState, useMemo } from "react";

import { Trend } from "components/common/Trend";
import { ReactComponent as BatteriesIcon } from "images/icons/batteries.svg";
import { ReactComponent as ChargersIcon } from "images/icons/chargers.svg";
import { ReactComponent as GridsIcon } from "images/icons/grids.svg";

type PlaceType = "site" | "fleet";
type Place = SiteResponse | FleetResponse;

interface PlaceTileProps {
  place: Place;
  placeType: PlaceType;
}

const TimeseriesPlaceholder = () => (
  <div className="p-3 h-[52px]">
    <p className="text-center">No data for the selected timeframe</p>
  </div>
);

const StatDisplay = ({
  value,
  label,
  units,
}: {
  value: number | null;
  label: string;
  units: string;
}) => (
  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
    <div className="self-stretch justify-between items-center inline-flex">
      <div className="h-7 justify-start items-center gap-1 flex">
        <div className="text-space50 text-xl font-medium leading-7">
          {value?.toLocaleString() ?? "-"}
        </div>
        <div className="text-space70 text-xs font-normal">{units}</div>
      </div>
      <Trend percent={0} />
    </div>
    <div className="text-space70 text-xs font-normal">{label}</div>
  </div>
);

const StatDisplaySkeleton = () => (
  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex pb-2">
    <div className="self-stretch justify-between items-center inline-flex w-full">
      <div className="h-7 justify-start items-center gap-1 flex">
        <div className="h-5 bg-gray-200 w-16 animate-pulse"></div>
        <div className="h-4 bg-gray-200 w-8 animate-pulse"></div>
      </div>
      <div className="h-4 bg-gray-200 w-12 animate-pulse"></div>
    </div>
    <div className="h-3 bg-gray-200 w-20 animate-pulse mt-1"></div>
  </div>
);

export const PlaceTile = ({ place, placeType }: PlaceTileProps) => {
  const [things, setThings] = useState<Thing[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<string[]>([]);
  const [summary, setSummary] = useState<DatapointMap>();
  const [timeseries, setTimeseries] = useState<Timeseries>();

  const { getThingsFromSite, getThingsFromFleet, getThingTypes } =
    useThingsApi();
  const {
    getSummaryForSite,
    getSummaryForFleet,
    getTimeseriesForSite,
    getTimeseriesForFleet,
  } = useDataApi();

  // TODO: add more types as needed
  const ALLOWED_DEVICE_TYPES = ["Meter", "Battery", "Charger"] as const;

  const [deviceTypesLoading, setDeviceTypesLoading] = useState(true);
  const [thingsLoading, setThingsLoading] = useState(true);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [timeseriesLoading, setTimeseriesLoading] = useState(true);

  useEffect(() => {
    getThingTypes()
      .then((types) => {
        // Filter to only include allowed device types
        const filteredTypes = types.filter((type) =>
          ALLOWED_DEVICE_TYPES.includes(type),
        );
        setDeviceTypes(filteredTypes);
        setDeviceTypesLoading(false);
      })
      .catch((e) => {
        console.error("Unable to fetch device types:", e);
        setDeviceTypes([]);
        setDeviceTypesLoading(false);
      });
  }, []);

  useEffect(() => {
    const end = dayjs();
    const start = end.subtract(7, "day");
    const placeId =
      placeType === "site"
        ? (place as SiteResponse).siteId
        : (place as FleetResponse).fleetId;

    // Get things
    const getThings =
      placeType === "site" ? getThingsFromSite : getThingsFromFleet;
    getThings(placeId)
      .then((response) => {
        setThings(response);
        setThingsLoading(false);
      })
      .catch((e) => {
        console.error(`Unable to fetch things for ${placeId}`, e);
        setThings([]);
        setThingsLoading(false);
      });

    // Get summary
    const getSummary =
      placeType === "site" ? getSummaryForSite : getSummaryForFleet;
    getSummary(placeId, start, end)
      .then((response) => {
        return datapointsToMap(response);
      })
      .then((mapped) => {
        setSummary(mapped || {});
        setSummaryLoading(false);
      })
      .catch((e) => {
        console.error(`Unable to fetch summary for ${placeId}`, e);
        setSummary({});
        setSummaryLoading(false);
      });

    // Get timeseries
    const getTimeseries =
      placeType === "site" ? getTimeseriesForSite : getTimeseriesForFleet;
    getTimeseries(placeId, start, end, "h", 2)
      .then((response) => {
        return convertToTimeseries(response);
      })
      .then((converted) => {
        setTimeseries(converted || null);
        setTimeseriesLoading(false);
      })
      .catch((e) => {
        console.error(`Unable to fetch timeseries for ${placeId}`, e);
        setTimeseries(null);
        setTimeseriesLoading(false);
      });
  }, [place, placeType]);

  const deviceCounts = useMemo(() => {
    const counts: Record<string, number> = {};

    deviceTypes.forEach((type) => {
      counts[type] = things.filter((thing) => thing.thingType === type).length;
    });

    return counts;
  }, [things, deviceTypes]);

  const stats = {
    stored: summary?.stored?.value ?? null,
    drawn: summary?.fwd?.value ?? null,
    discharged: summary?.discharged?.value ?? null,
    demand: summary?.demand?.value ?? null,
  };

  const name =
    placeType === "site"
      ? (place as SiteResponse).siteName
      : (place as FleetResponse).fleetName;

  const shortCode = name.substring(0, 3).toUpperCase();
  const address =
    placeType === "site" ? (place as SiteResponse).siteAddress : undefined;

  return (
    <div className="w-full mb-4 bg-white rounded-md shadow border border-zinc-300 flex-col justify-start items-start gap-4 inline-flex transition-transform hover:-translate-y-0.5 hover:shadow-md">
      <div className="self-stretch justify-between items-center inline-flex px-4 pt-4">
        <div className="justify-start items-center gap-4 flex">
          <div className="px-[11px] py-1.5 bg-gray95 rounded-sm justify-start items-center flex">
            <div className="text-space50 text-sm font-medium leading-tight">
              {shortCode}
            </div>
          </div>
          <div className="flex-col justify-start items-start inline-flex">
            <div className="text-space50 text-sm font-normal">{name}</div>
            {address && (
              <div className="text-space70 text-xs font-normal">{address}</div>
            )}
          </div>
        </div>
        <div className="justify-end items-center gap-1 flex">
          {deviceTypesLoading || thingsLoading ? (
            <div className="flex gap-2">
              <div className="h-6 bg-gray-200 w-16 animate-pulse"></div>
              <div className="h-6 bg-gray-200 w-16 animate-pulse"></div>
              <div className="h-6 bg-gray-200 w-16 animate-pulse"></div>
            </div>
          ) : (
            deviceTypes.map((type) => (
              <div
                key={type}
                className="px-2 py-1.5 rounded-sm justify-start items-start gap-0.5 text-space50"
              >
                <p className="text-sm font-medium">
                  {deviceCounts[type]}{" "}
                  {type === "Battery" && (
                    <BatteriesIcon className="inline-block align-middle mb-1" />
                  )}
                  {type === "Charger" && (
                    <ChargersIcon className="inline-block align-middle mb-1" />
                  )}
                  {type === "Meter" && (
                    <GridsIcon className="inline-block align-middle mb-1" />
                  )}
                </p>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="self-stretch justify-center items-center gap-4 inline-flex px-4">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
          <div className="self-stretch justify-start items-start gap-4 inline-flex">
            {summaryLoading ? (
              <>
                <StatDisplaySkeleton />
                <StatDisplaySkeleton />
              </>
            ) : (
              <>
                <StatDisplay
                  value={stats.stored}
                  label="Currently Stored"
                  units="kwh"
                />
                <StatDisplay
                  value={stats.drawn}
                  label="Drawn from Grid"
                  units="kwh"
                />
              </>
            )}
          </div>
          <div className="self-stretch justify-start items-start gap-4 inline-flex">
            {summaryLoading ? (
              <>
                <StatDisplaySkeleton />
                <StatDisplaySkeleton />
              </>
            ) : (
              <>
                <StatDisplay
                  value={stats.discharged}
                  label="Discharged to Vehicles"
                  units="kwh"
                />
                <StatDisplay
                  value={stats.demand}
                  label="Forecasted Remaining Demand"
                  units="kwh"
                />
              </>
            )}
          </div>

          {timeseriesLoading ? (
            <div className="h-[52px] w-full bg-gray-200 rounded animate-pulse mb-2"></div>
          ) : timeseries ? (
            <MicroHorizontalTimeseriesChart timeseries={timeseries} />
          ) : (
            <TimeseriesPlaceholder />
          )}
        </div>
      </div>
    </div>
  );
};
