import { type Organization, useIdentityApi } from "api/ingestion/identity";
import { useAuth } from "context/AuthContext";

import React, { useEffect, useState } from "react";

import { AddUserModal } from "./addModal";
import { InvitesSection } from "./invites";
import { PrimaryButton } from "./shared";
import { UsersSection } from "./users";

export const OrganizationPage = () => {
  const { user, permissions } = useAuth();
  const { getOrganization } = useIdentityApi();

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const readAuthorized = permissions.includes("read:ingest_admin");
  const writeAuthorized = permissions.includes("write:ingest_admin");

  useEffect(() => {
    getOrganization(user.org_id).then(setOrganization);
  }, [user.org_id]);

  return (
    <div className="flex flex-col pl-4 pr-8 mb-16 gap-8">
      <div className="flex pt-4 pb-3 justify-between">
        <div className="flex items-center gap-6">
          {organization?.branding?.logoUrl && (
            <img
              src={organization.branding.logoUrl}
              alt="logo"
              className="h-20 w-20"
            />
          )}
          <div className="flex flex-col">
            <div className="text-space50">My Organization</div>
            <div className="text-heading1 text-space50">
              {organization?.displayName}
            </div>
          </div>
        </div>
        {writeAuthorized && (
          <div className="flex-shrink-0">
            <PrimaryButton
              onClick={() => setAddUserModalOpen(true)}
              disabled={addUserModalOpen}
            >
              Invite Member
            </PrimaryButton>
            <AddUserModal
              open={addUserModalOpen}
              setOpen={setAddUserModalOpen}
            />
          </div>
        )}
      </div>

      {readAuthorized ? (
        <div className="flex w-full flex-col lg:flex-row gap-8">
          <div className="lg:basis-1/3">
            <UsersSection editable={writeAuthorized} />
          </div>
          <div className="lg:basis-2/3">
            <InvitesSection refreshKey={addUserModalOpen} />
          </div>
        </div>
      ) : (
        <div className="text-center text-space50 pt-4">
          You are not authorized to view this page.
        </div>
      )}
    </div>
  );
};
