import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";

interface DeleteCertificateModalProps {
  certId: string | null;
  onClose: () => void;
  onDelete: (certId: string) => void;
  placeType: string;
  siteId: string;
  thingId: string;
}

export const DeleteCertificateModal: React.FC<DeleteCertificateModalProps> = ({
  certId,
  onClose,
  onDelete,
  placeType,
  siteId,
  thingId,
}) => (
  <div
    className={`fixed inset-0 bg-black/50 flex items-center justify-center z-50 ${certId ? "" : "hidden"}`}
    onClick={onClose}
  >
    <div
      className="bg-white rounded-lg p-6 max-w-lg w-full mx-4 shadow-xl"
      onClick={(e) => e.stopPropagation()}
    >
      <h2 className="text-2xl text-gray-900 font-medium mb-4">
        Delete certificate?
      </h2>

      <div className="space-y-4">
        <div className="bg-gray-50 p-3 rounded-md border border-gray-200">
          <div className="text-sm text-gray-700 font-bold mb-1">
            Certificate ID
          </div>
          <p className="font-mono text-gray-600 break-all text-sm">{certId}</p>
        </div>

        <p className="text-red-500">
          Warning: deleting this certificate will result in all authentication
          attempts leveraging this certificate to fail immediately. Any device
          using this certificate will be unable to connect to Spectra.
        </p>
      </div>

      <div className="mt-6 flex justify-end gap-3">
        <button
          onClick={onClose}
          className="px-4 py-2 text-gray-600 hover:text-gray-800"
        >
          Cancel
        </button>

        <button
          onClick={() => certId && onDelete(certId)}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center gap-2"
        >
          Delete
          <DeleteIcon />
        </button>
      </div>
    </div>
  </div>
);
