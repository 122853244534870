import { type AlertEvent, useEnterpriseApi } from "api/enterprise";
import type { Dayjs } from "dayjs";

import React, { useEffect, useState } from "react";

import { AlertRow } from "./PlaceAlerts";

export const DeviceAlerts = ({
  placeType,
  placeId,
  thingId,
  partnerId,
  start,
  end,
  // simulationId,
}: {
  placeType: string;
  placeId: string;
  thingId: string;
  partnerId?: string; // TODO: technically not optional currently
  start: Dayjs;
  end: Dayjs;
  // simulationId: string | null;
}) => {
  const { getMonitorData } = useEnterpriseApi();

  const [alerts, setAlerts] = useState<AlertEvent[] | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies: adding getMonitorData to the dependency array causes a re-render loop
  useEffect(() => {
    getMonitorData(placeType, placeId, partnerId, start, end, thingId)
      .then((data) => {
        setAlerts(
          data
            .flatMap((d) => d.alertEvents)
            .filter((e) => e.alertState !== "HEALTHY"),
        );
      })
      .catch((error) => {
        console.error("Error fetching alerts:", error);
        setError(error);
      });
  }, [placeType, placeId, partnerId, start, end, thingId]);

  // TODO: re-enable this
  // if (error) {
  //   return (
  //     <div className="text-caption text-center text-red50 py-4">
  //       Error loading alerts: {error.message}
  //     </div>
  //   );
  // }

  return (
    <div className="overflow-auto h-full">
      <p className="text-space50 text-heading3 mb-4">Faults</p>
      <div className="flex flex-col gap-2 pt-2">
        {error ? (
          <div className="text-caption text-center text-space70 py-4">
            No alerts for selected timeframe
          </div>
        ) : !alerts ? (
          <div className="text-caption text-center text-space70 py-4">
            Loading...
          </div>
        ) : alerts.length > 0 ? (
          alerts.map((alert) => <AlertRow alert={alert} key={alert.alertId} />)
        ) : (
          <div className="text-caption text-center text-space70 py-4">
            No alerts for selected timeframe
          </div>
        )}
      </div>
    </div>
  );
};
