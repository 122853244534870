import React, { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const CHARGE_DATA = [
  { name: "1", value: 60 },
  { name: "2", value: 70 },
  { name: "3", value: 80 },
  { name: "4", value: 75 },
  { name: "5", value: 85 },
  { name: "6", value: 90 },
  { name: "7", value: 86 },
];

const CYCLES_DATA = [
  { name: "1", value: 230 },
  { name: "2", value: 232 },
  { name: "3", value: 234 },
  { name: "4", value: 236 },
  { name: "5", value: 235 },
  { name: "6", value: 237 },
  { name: "7", value: 236 },
];

const CHARGE_CYCLES_DATA = [
  {
    date: "08/17/2022",
    startTime: "14:25:32",
    endTime: "14:25:32",
    id: "XX123457",
    site: "HUB_00_1234",
    initialCharge: "12%",
    charge: "978",
    revenue: "Rp 345",
  },
  {
    date: "08/17/2022",
    startTime: "14:25:32",
    endTime: "14:25:32",
    id: "XX123456",
    site: "HUB_00_1243",
    initialCharge: "54%",
    charge: "537",
    revenue: "Rp 345",
  },
  {
    date: "08/17/2022",
    startTime: "14:25:32",
    endTime: "14:25:32",
    id: "XX123455",
    site: "HUB_00_1156",
    initialCharge: "34%",
    charge: "475",
    revenue: "Rp 143",
  },
  {
    date: "08/17/2022",
    startTime: "12:45:32",
    endTime: "12:45:32",
    id: "XX123454",
    site: "HUB_00_1724",
    initialCharge: "7%",
    charge: "1,103",
    revenue: "Rp 504",
  },
  {
    date: "08/17/2022",
    startTime: "11:06:12",
    endTime: "11:06:12",
    id: "XX123453",
    site: "HUB_00_1257",
    initialCharge: "36%",
    charge: "465",
    revenue: "Rp 123",
  },
  {
    date: "08/17/2022",
    startTime: "11:06:12",
    endTime: "11:06:12",
    id: "XX123453",
    site: "HUB_00_1257",
    initialCharge: "36%",
    charge: "465",
    revenue: "Rp 123",
  },
  {
    date: "08/17/2022",
    startTime: "11:06:12",
    endTime: "11:06:12",
    id: "XX123453",
    site: "HUB_00_1257",
    initialCharge: "36%",
    charge: "465",
    revenue: "Rp 123",
  },
  {
    date: "08/17/2022",
    startTime: "11:06:12",
    endTime: "11:06:12",
    id: "XX123453",
    site: "HUB_00_1257",
    initialCharge: "36%",
    charge: "465",
    revenue: "Rp 123",
  },
];

const POWER_DRAW_DATA = [
  { name: "1", value: 80 },
  { name: "2", value: 85 },
  { name: "3", value: 82 },
  { name: "4", value: 87 },
  { name: "5", value: 84 },
  { name: "6", value: 86 },
  { name: "7", value: 86 },
];

const HEALTH_DATA = [
  { name: "1", value: 98 },
  { name: "2", value: 98 },
  { name: "3", value: 97 },
  { name: "4", value: 97 },
  { name: "5", value: 97 },
  { name: "6", value: 97 },
  { name: "7", value: 97 },
];

const DeviceDashboard = () => {
  const [sortConfig, setSortConfig] = useState(null);
  const [activePanel, setActivePanel] = useState(null);

  const sortedData = React.useMemo(() => {
    const sortableItems = [...CHARGE_CYCLES_DATA];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="flex w-full min-h-screen bg-gray-100 p-4 relative overflow-hidden">
      <div className="w-full bg-white shadow-sm rounded-lg overflow-hidden">
        <div className="p-6">
          <h1 className="text-2xl font-semibold text-gray-800 mb-4">Devices</h1>

          <div className="mb-4">
            <p className="text-sm text-gray-500">
              StationName - Charger Bay #ID-sgt-2384-df
            </p>
            <h2 className="text-xl font-semibold">Battery #12345</h2>
            <p className="text-xs text-gray-500 text-left">
              UPDATED 08/17/2022 14:25:32
            </p>
          </div>

          <button className="bg-yellow-400 text-white px-4 py-2 rounded-md flex items-center mb-6">
            3 Active Alerts
            <svg
              className="w-4 h-4 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          <div className="grid grid-cols-6 gap-4 mb-6">
            {[
              { label: "VOLTAGE", value: "60 V", sub: "=" },
              { label: "CURRENT", value: "52 A", icon: "up" },
              { label: "POWER", value: "60 W", sub: "=" },
              { label: "TEMPERATURE", value: "41 °C", icon: "up" },
              { label: "CYCLE COUNT", value: "236", icon: "up" },
              { label: "CAPACITY", value: "1,167 Wh", icon: "up" },
            ].map((item, index) => (
              <div key={index} className="text-center">
                <div className="flex items-center justify-center">
                  <span className="text-2xl font-semibold">{item.value}</span>
                  {item.sub && <span className="ml-1">{item.sub}</span>}
                  {item.icon === "up" && (
                    <svg
                      className="w-4 h-4 text-green-500 ml-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 10l7-7m0 0l7 7m-7-7v18"
                      ></path>
                    </svg>
                  )}
                </div>
                <p className="text-xs text-gray-500 mt-1">{item.label}</p>
              </div>
            ))}
          </div>

          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold">
                Performance & Utilization
              </h3>
              <div className="flex space-x-2 border border-gray-300 rounded-full">
                <button className="bg-gray-200 px-3 py-1 rounded-full text-sm font-medium">
                  DAY
                </button>
                <button className="px-3 py-1 rounded-full text-sm text-gray-500">
                  WEEK
                </button>
                <button className="px-3 py-1 rounded-full text-sm text-gray-500">
                  MTD
                </button>
                <button className="px-3 py-1 rounded-full text-sm text-gray-500">
                  YTD
                </button>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-4">
              <PerformanceCard
                title="CHARGE"
                value="86%"
                change="2%"
                data={CHARGE_DATA}
                onClick={() => setActivePanel("charge")}
              />
              <PerformanceCard
                title="CYCLES"
                value="236"
                change="2%"
                data={CYCLES_DATA}
                onClick={() => setActivePanel("cycles")}
              />
              <PerformanceCard
                title="POWER DRAW"
                value="120 kW"
                change="2%"
                data={POWER_DRAW_DATA}
                onClick={() => setActivePanel("powerDraw")}
              />
              <PerformanceCard
                title="HEALTH"
                value="97%"
                change="2%"
                data={HEALTH_DATA}
                onClick={() => setActivePanel("health")}
              />
            </div>
          </div>

          <ChargeCyclesTable
            data={sortedData}
            requestSort={requestSort}
            sortConfig={sortConfig}
          />
        </div>
      </div>
      {/* Slide-in Panel */}
      <SlideInPanel
        activePanel={activePanel}
        onClose={() => setActivePanel(null)}
        chargeData={CHARGE_DATA}
        cyclesData={CYCLES_DATA}
        powerDrawData={POWER_DRAW_DATA}
        healthData={HEALTH_DATA}
        chargeCyclesData={CHARGE_CYCLES_DATA}
      />
    </div>
  );
};

const PerformanceCard = ({ title, value, change, data, onClick }) => (
  <div
    className="bg-gray-100 p-4 rounded-lg transition-colors duration-200 ease-in-out hover:bg-gray-200 cursor-pointer"
    onClick={onClick}
  >
    <div className="flex justify-between items-center mb-2">
      <span className="text-sm text-gray-500">{title}</span>
      <span className="text-xs text-green-500 bg-green-100 px-2 py-1 rounded-full">
        {change}
        <svg
          className="w-3 h-3 inline-block ml-0.5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M5 10l7-7m0 0l7 7m-7-7v18"
          ></path>
        </svg>
      </span>
    </div>
    <p className="text-2xl font-semibold">{value}</p>
    <div className="h-16 mt-2">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <Bar dataKey="value" fill="#4A90E2" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
);

const ChargeCyclesTable = ({ data, requestSort, sortConfig }) => (
  <div>
    <div className="flex items-center justify-between mb-4">
      <h3 className="text-lg font-semibold">
        Charge Cycles <span className="text-gray-400">236</span>
      </h3>
      <div className="flex items-center space-x-2">
        <svg
          className="h-5 w-5 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
        <svg
          className="h-5 w-5 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
          ></path>
        </svg>
        <svg
          className="h-5 w-5 text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </div>
    </div>
    <table className="w-full">
      <thead>
        <tr className="text-left text-sm text-gray-500">
          <th
            className="pb-2 cursor-pointer"
            onClick={() => requestSort("date")}
          >
            Date{" "}
            {sortConfig?.key === "date" && (
              <ArrowUpDown direction={sortConfig.direction} />
            )}
          </th>
          <th
            className="pb-2 cursor-pointer"
            onClick={() => requestSort("startTime")}
          >
            Start Time{" "}
            {sortConfig?.key === "startTime" && (
              <ArrowUpDown direction={sortConfig.direction} />
            )}
          </th>
          <th className="pb-2">End Time</th>
          <th className="pb-2 cursor-pointer" onClick={() => requestSort("id")}>
            Charge Cycle ID{" "}
            {sortConfig?.key === "id" && (
              <ArrowUpDown direction={sortConfig.direction} />
            )}
          </th>
          <th className="pb-2">Site</th>
          <th className="pb-2">Initial Charge</th>
          <th className="pb-2">Charge (Wh)</th>
          <th className="pb-2">Revenue (Rp)</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index} className="border-t border-gray-200">
            <td className="py-2">{row.date}</td>
            <td>{row.startTime}</td>
            <td>{row.endTime}</td>
            <td>{row.id}</td>
            <td>{row.site}</td>
            <td>{row.initialCharge}</td>
            <td>{row.charge}</td>
            <td>{row.revenue}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="text-right mt-2">
      <a href="#" className="text-blue-500 text-sm">
        See all
      </a>
    </div>
  </div>
);

const SlideInPanel = ({
  activePanel,
  onClose,
  chargeData,
  cyclesData,
  powerDrawData,
  healthData,
  chargeCyclesData,
}) => {
  const getPanelContent = () => {
    switch (activePanel) {
      case "charge":
        return <ChargePanel data={chargeData} cyclesData={chargeCyclesData} />;
      case "cycles":
        return <CyclesPanel data={cyclesData} cyclesData={chargeCyclesData} />;
      case "powerDraw":
        return (
          <PowerDrawPanel data={powerDrawData} cyclesData={chargeCyclesData} />
        );
      case "health":
        return <HealthPanel data={healthData} cyclesData={chargeCyclesData} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`fixed top-0 right-0 w-96 h-full bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
        activePanel ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">BATTERY #12345</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        {getPanelContent()}
      </div>
    </div>
  );
};

const ChargePanel = ({ data, cyclesData }) => (
  <>
    <h3 className="text-lg font-semibold mb-2">Charge Level</h3>
    <ChartSection data={data} />
    <StatsSection value="86%" label="CHARGE" connectedDevices={8} />
    <CyclesTable data={cyclesData} title="Charge Cycles" />
  </>
);

const CyclesPanel = ({ data, cyclesData }) => (
  <>
    <h3 className="text-lg font-semibold mb-2">Cycles</h3>
    <ChartSection data={data} />
    <StatsSection value="236" label="CYCLES" connectedDevices={8} />
    <CyclesTable data={cyclesData} title="Connected Devices" />
  </>
);

const PowerDrawPanel = ({ data, cyclesData }) => (
  <>
    <h3 className="text-lg font-semibold mb-2">Power Draw</h3>
    <ChartSection data={data} />
    <StatsSection value="120 kW" label="POWER DRAW" connectedDevices={8} />
    <CyclesTable data={cyclesData} title="Charge Cycles" />
  </>
);

const HealthPanel = ({ data, cyclesData }) => (
  <>
    <h3 className="text-lg font-semibold mb-2">Health</h3>
    <ChartSection data={data} />
    <StatsSection value="97%" label="HEALTH" connectedDevices={8} />
    <CyclesTable data={cyclesData} title="Charge Cycles" />
  </>
);

const ChartSection = ({ data }) => (
  <div className="mb-4">
    <div className="bg-gray-100 p-4 rounded-lg mb-4">
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={data}>
          <Bar dataKey="value" fill="#4A90E2" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
        </BarChart>
      </ResponsiveContainer>
    </div>
    <div className="mb-4">
      <h4 className="text-sm font-semibold text-gray-500 mb-1">
        LAST 7 DAYS CHARGE LEVEL
      </h4>
      <div className="bg-gray-200 h-24 rounded-lg"></div>
    </div>
    <div>
      <h4 className="text-sm font-semibold text-gray-500 mb-1">
        ALL-TIME CHARGE LEVEL
      </h4>
      <div className="bg-gray-200 h-24 rounded-lg"></div>
    </div>
  </div>
);

const StatsSection = ({ value, label, connectedDevices }) => (
  <div className="flex justify-between items-center mb-4">
    <div>
      <p className="text-3xl font-bold">{value}</p>
      <p className="text-sm text-gray-500">{label}</p>
    </div>
    <div>
      <p className="text-3xl font-bold">{connectedDevices}</p>
      <p className="text-sm text-gray-500">CONNECTED DEVICES</p>
    </div>
  </div>
);

const CyclesTable = ({ data, title }) => (
  <div>
    <h4 className="text-lg font-semibold mb-2">
      {title} <span className="text-gray-400">{data.length}</span>
    </h4>
    <table className="w-full text-sm">
      <thead>
        <tr className="text-left text-gray-500">
          <th className="pb-2">Date</th>
          <th className="pb-2">Start Time</th>
          <th className="pb-2">End Time</th>
        </tr>
      </thead>
      <tbody>
        {data.slice(0, 5).map((row, index) => (
          <tr key={index} className="border-t border-gray-200">
            <td className="py-2">{row.date}</td>
            <td>{row.startTime}</td>
            <td>{row.endTime}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ArrowUpDown = ({ direction }) => (
  <svg
    className="w-4 h-4 inline-block ml-1"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d={
        direction === "ascending"
          ? "M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
          : "M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
      }
    ></path>
  </svg>
);

export default DeviceDashboard;
