import { ReactComponent as DownloadIcon } from "images/icons/download.svg";

export const RootCertificateSection = () => (
  <div className="mt-6 flex flex-col items-center space-y-2">
    <a
      href="https://www.amazontrust.com/repository/AmazonRootCA1.pem"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 px-4 py-2 text-blue-700 bg-white border border-blue-700 rounded hover:bg-blue-50"
    >
      <DownloadIcon />
      Download Root Certificate (RSA)
    </a>
    <a
      href="https://www.amazontrust.com/repository/AmazonRootCA3.pem"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 px-4 py-2 text-blue-700 bg-white border border-blue-700 rounded hover:bg-blue-50"
    >
      <DownloadIcon />
      Download Root Certificate (ECC)
    </a>
    <span className="text-center text-sm text-gray-600">
      Note: Spectra only supports MQTT ingestion over TLS. Please download the
      appropriate Root CA Certificate for the Cipher Suite supported by your
      device, and configure it for verifying TLS connections.
    </span>
  </div>
);
