import { type SiteResponse, usePlacesApi } from "api/ingestion/places";
import { type Thing, useThingsApi } from "api/ingestion/things";
import { DeviceDetail } from "components";
import { SingleCategoryFilter } from "components/controls/SingleCategoryFilter";
import {
  SelectedDeviceProvider,
  useSelectedDevice,
} from "context/SelectedDeviceContext";
import { SelectedSimulationProvider } from "context/SelectedSimulationContext";
import { TimeRangeSelector } from "context/SelectedTimeRangeContext.tsx";

import { useEffect, useState } from "react";

import { useAuth } from "../../context/AuthContext.tsx";
import AddDeviceModal from "./AddDeviceModal";
import { DevicesByPlace } from "./DevicesByPlace";
import { DevicesByType } from "./DevicesByType";
import DevicesTable from "./DevicesTable";

const DeviceDetailWrapper = () => {
  const { selectedDevice, setSelectedDevice } = useSelectedDevice();
  const placeType = selectedDevice?.placeType?.toLowerCase() || "site";
  return (
    <DeviceDetail
      selectedDevice={selectedDevice}
      setSelectedDevice={setSelectedDevice}
      placeType={placeType}
    />
  );
};

const PageContent = () => {
  const [viewMode, setViewMode] = useState("tile");
  const [showByPlace, setShowByPlace] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sites, setSites] = useState<SiteResponse[]>([]);
  const [fleets, setFleets] = useState<FleetResponse[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<string[]>([]);
  const [devices, setDevices] = useState<Thing[]>([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>("All");

  const { permissions } = useAuth();
  const { addDevice, getThingTypes, getThings } = useThingsApi();
  const { getSites, getFleets } = usePlacesApi();

  // biome-ignore lint/correctness/useExhaustiveDependencies: adding getSites, getFleets, getThingTypes, getThings to the dependency array causes a re-render loop
  useEffect(() => {
    const fetchData = async () => {
      const [sites, fleets, allDeviceTypes, allDevices] = await Promise.all([
        getSites(),
        getFleets(),
        getThingTypes(),
        getThings(),
      ]);

      setSites(sites);
      setFleets(fleets);
      setDeviceTypes(["All", ...allDeviceTypes]);
      setDevices(allDevices);
    };

    fetchData();
  }, []);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleDeviceAdded = (siteId, deviceData) => {
    return addDevice(siteId, deviceData).then((result) => {
      if (result.success) {
        return { success: true };
      }
      return { success: false, error: result.error };
    });
  };

  const filteredDevices =
    selectedDeviceType === "All"
      ? devices
      : devices.filter((device) => device.thingType === selectedDeviceType);

  const deviceTypesWithoutAll = deviceTypes.filter((type) => type !== "All");

  const renderActionButtons = () => {
    if (permissions.includes("write:ingest_things")) {
      return (
        <button
          type="button"
          onClick={handleOpenModal}
          className="px-3.5 py-2 rounded-full border border-space80 justify-end items-center gap-1 cursor-pointer hover:bg-gray95 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue90 focus:ring-blue90 flex"
        >
          <div className="text-space70 text-xs font-medium leading-[14px]">
            + Add Device
          </div>
        </button>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-row w-full min-h-screen">
      <SelectedDeviceProvider>
        <div className="flex-1 h-screen overflow-y-auto">
          <div className="flex pt-4 pb-5 justify-between">
            <p className="text-heading1 text-space50">Devices</p>
            <div className="flex gap-2">
              <TimeRangeSelector />
              <SingleCategoryFilter
                options={["Tile View", "Table View"]}
                defaultSelected={
                  viewMode === "tile" ? "Tile View" : "Table View"
                }
                onChange={(value) =>
                  setViewMode(value === "Tile View" ? "tile" : "table")
                }
              />
              {viewMode === "tile" && (
                <SingleCategoryFilter
                  options={["Group by Place", "Group by Type"]}
                  defaultSelected={
                    showByPlace ? "Group by Place" : "Group by Type"
                  }
                  onChange={(value) =>
                    setShowByPlace(value === "Group by Place")
                  }
                />
              )}
              {viewMode === "table" && (
                <SingleCategoryFilter
                  options={deviceTypes}
                  defaultSelected={selectedDeviceType}
                  onChange={setSelectedDeviceType}
                />
              )}
              {renderActionButtons()}
              <AddDeviceModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onDeviceAdded={handleDeviceAdded}
              />
            </div>
          </div>

          {viewMode === "tile" ? (
            showByPlace ? (
              <DevicesByPlace
                sites={sites}
                fleets={fleets}
                devices={devices}
                deviceTypes={deviceTypesWithoutAll}
              />
            ) : (
              <DevicesByType
                places={[...sites, ...fleets]}
                devices={devices}
                deviceTypes={deviceTypesWithoutAll}
              />
            )
          ) : (
            <DevicesTable devices={filteredDevices} />
          )}
        </div>
        <DeviceDetailWrapper />
      </SelectedDeviceProvider>
    </div>
  );
};

const DevicesPage = () => {
  return (
    <SelectedSimulationProvider simulations={[]}>
      <PageContent />
    </SelectedSimulationProvider>
  );
};

export { DevicesPage };
