import { useThingsApi } from "api/ingestion/things.ts";

import { useEffect, useState } from "react";

import type { Certificate, CertificateDetails } from "../types/certificate";

export const useCertificates = (
  placeType: string,
  siteId: string,
  thingId: string,
) => {
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [certificateDetails, setCertificateDetails] =
    useState<CertificateDetails | null>(null);

  const {
    getThingCertificates,
    getCertificatePolicies,
    createDefaultPolicyCertificate,
    setDefaultPolicy,
  } = useThingsApi();

  const fetchCertificates = async () => {
    try {
      setIsLoading(true);
      const certIds = await getThingCertificates(placeType, siteId, thingId);

      if (certIds.length > 0) {
        const certsWithPolicies = await Promise.all(
          certIds.map(async (certId) => {
            const policies = await getCertificatePolicies(
              placeType,
              siteId,
              thingId,
              certId,
            );

            return {
              id: certId,
              policy: policies[0], // Take first policy since there's only one
              expirationDate: "N/A", // TODO: fetch expiration date when we have it
            };
          }),
        );
        setCertificates(certsWithPolicies);
      } else {
        setCertificates([]);
      }
    } catch (error) {
      console.error("Error fetching certificates:", error);
      setError(
        "An error occurred while fetching certificates. Please try again.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCertificate = async () => {
    try {
      setIsGenerating(true);
      setError(null);

      // First create the default policy
      const policyResult = await setDefaultPolicy(placeType, siteId, thingId);

      if (!policyResult.success) {
        throw new Error(
          policyResult.error || "Failed to create default policy",
        );
      }

      // Then create the certificate
      const result = await createDefaultPolicyCertificate(
        placeType,
        siteId,
        thingId,
      );

      if (result.success && result.message) {
        const certResponse = result.message;

        setCertificateDetails({
          certificateId: certResponse.CertificateId,
          certificatePem: certResponse.CertificatePem,
          privateKey: certResponse.KeyPair.privateKey,
          publicKey: certResponse.KeyPair.publicKey,
        });

        await fetchCertificates();
      } else {
        setError(
          result.error || "Failed to generate certificate. Please try again.",
        );
      }
    } catch (error) {
      console.error("Error generating certificate:", error);
      setError(
        typeof error === "string"
          ? error
          : error.message ||
              "Failed to generate certificate. Please try again.",
      );
    } finally {
      setIsGenerating(false);
    }
  };

  useEffect(() => {
    let mounted = true;
    setError(null);

    const loadData = async () => {
      if (!mounted) return;
      await fetchCertificates();
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, [placeType, siteId, thingId]);

  return {
    certificates,
    isLoading,
    error,
    certificateDetails,
    setCertificateDetails,
    isGenerating,
    handleCreateCertificate,
    refreshCertificates: fetchCertificates,
  };
};
