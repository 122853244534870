import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { dayjs } from "utils/dayjs";

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ReactComponent as ChevronDown } from "images/icons/chevron.down.svg";
import ButtonComponent from "../components/uikit/button";

const USER_TIME_ZONE_DISPLAY = dayjs().format("z");
const USER_TIME_ZONE = dayjs.tz.guess();

type SelectedTimeRangeContextType = {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  setStart: (start: dayjs.Dayjs) => void;
  setEnd: (end: dayjs.Dayjs) => void;
  isUTC: boolean;
  setIsUTC: (isUTC: boolean) => void;
};

const SelectedTimeRangeContext = createContext<
  SelectedTimeRangeContextType | undefined
>(undefined);

export const useSelectedTimeRange = () => {
  const context = useContext(SelectedTimeRangeContext);
  if (!context) {
    throw new Error(
      "useSelectedTimeRange must be used within a SelectedTimeRangeProvider",
    );
  }
  return context;
};

export const SelectedTimeRangeProvider: React.FC = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [start, setStart] = useState(dayjs().subtract(1, "week"));
  const [end, setEnd] = useState(dayjs());
  const [isUTC, setIsUTC] = useState(false);

  return (
    <SelectedTimeRangeContext.Provider
      value={{ start, end, setStart, setEnd, isUTC, setIsUTC }}
    >
      {children}
    </SelectedTimeRangeContext.Provider>
  );
};

enum TimeRangeOptions {
  Past24Hours = "Past Day",
  Today = "Today",
  Yesterday = "Yesterday",
  Past7d = "Past Week",
  CustomRange = "Custom Range",
}

const getFormattedTime = (
  timeRange: TimeRangeOptions,
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
  isUTC: boolean,
) => {
  if (timeRange !== TimeRangeOptions.CustomRange) {
    return timeRange;
  }

  // create a string in format [start.day] [start.time] - [end.day]? [end.time]

  const timezone: string = isUTC ? "UTC" : USER_TIME_ZONE_DISPLAY;
  if (start.date() === end.date()) {
    return `${start.format("lll")} - ${end.format("LT")} ${timezone}`;
  }

  return `${start.format("lll")} - ${end.format("lll")} ${timezone}`;
};

export const TimeRangeSelector = () => {
  const DEFAULT_TIME_OPTION = TimeRangeOptions.Past7d;

  const { start, end, setStart, setEnd, isUTC, setIsUTC } =
    useSelectedTimeRange();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedTimeRangeDisplay, setSelectedTimeRangeDisplay] =
    useState<string>("");

  const menuRef = useRef<HTMLDivElement>(null);

  const determineTimeRange = useCallback(() => {
    const now = dayjs();

    if (
      end.isSame(now, "hour") &&
      start.isSame(now.subtract(1, "day"), "hour")
    ) {
      return TimeRangeOptions.Past24Hours;
    }

    if (start.isSame(now.startOf("day")) && end.isSame(now.endOf("day"))) {
      return TimeRangeOptions.Today;
    }

    const yesterday = now.subtract(1, "day");
    if (
      start.isSame(yesterday.startOf("day")) &&
      end.isSame(yesterday.endOf("day"))
    ) {
      return TimeRangeOptions.Yesterday;
    }

    if (
      end.isSame(now, "hour") &&
      start.isSame(now.subtract(1, "week"), "hour")
    ) {
      return TimeRangeOptions.Past7d;
    }

    return TimeRangeOptions.CustomRange;
  }, [start, end]);

  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRangeOptions>(
    () => determineTimeRange(),
  );

  useEffect(() => {
    setSelectedTimeRange(determineTimeRange());
  }, [determineTimeRange]);

  useEffect(() => {
    const initialDisplay = getFormattedTime(
      selectedTimeRange,
      start,
      end,
      isUTC,
    );
    setSelectedTimeRangeDisplay(initialDisplay);
  }, [start, end, isUTC, selectedTimeRange]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      !event.target.closest(".MuiPickersPopper-root") // Check if the click is inside the DateTimePicker
    ) {
      setIsMenuOpen(false);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: adding handleClickOutside to the dependency array causes a re-render loop
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const setTimeRange = (
    timeOption: TimeRangeOptions,
    overrides: {
      isUTC?: boolean;
      autoclose?: boolean;
      start?: dayjs.Dayjs;
      end?: dayjs.Dayjs;
    } = {},
  ) => {
    const defaults = { isUTC: isUTC, autoclose: true, start: start, end: end };
    const options = Object.assign(defaults, overrides);

    setSelectedTimeRange(timeOption);

    if (options.autoclose) {
      setIsMenuOpen(false);
    }

    // update the time(s) if necessary
    const now = dayjs();
    switch (timeOption) {
      case TimeRangeOptions.Past24Hours: {
        setIsUTC(options.isUTC);
        setStart(now.subtract(1, "day"));
        setEnd(now);
        break;
      }
      case TimeRangeOptions.Today: {
        setIsUTC(options.isUTC);
        setStart(now.startOf("day"));
        setEnd(now.endOf("day"));
        break;
      }
      case TimeRangeOptions.Yesterday: {
        setIsUTC(options.isUTC);
        const yesterday = now.subtract(1, "day");
        setStart(yesterday.startOf("day"));
        setEnd(yesterday.endOf("day"));
        break;
      }
      case TimeRangeOptions.Past7d: {
        setIsUTC(options.isUTC);
        setStart(now.subtract(1, "week"));
        setEnd(now);
        break;
      }
      case TimeRangeOptions.CustomRange: {
        setIsUTC(options.isUTC);

        const newStart = options.isUTC
          ? options.start.tz("UTC")
          : options.start.tz(USER_TIME_ZONE);
        const newEnd = options.isUTC
          ? options.end.tz("UTC")
          : options.end.tz(USER_TIME_ZONE);
        setStart(newStart);
        setEnd(newEnd);

        break;
      }
    }

    // set formatted string
    const customRangeString = getFormattedTime(
      timeOption,
      options.start,
      options.end,
      options.isUTC,
    );
    setSelectedTimeRangeDisplay(customRangeString);
  };

  return (
    <div className="relative" ref={menuRef}>
      <ButtonComponent.Pill
        iconAfter={<ChevronDown />}
        onClick={toggleMenu}
        className={`${selectedTimeRange === DEFAULT_TIME_OPTION ? "border border-space80 hover:bg-gray95 text-space70" : "!bg-blue50 text-white !border-0"}`}
      >
        {selectedTimeRangeDisplay}
      </ButtonComponent.Pill>
      <div
        className={`origin-top-right w-[250px] absolute right-0 py-1 mt-2 border border-gray95 rounded-md shadow-lg bg-white divide-y focus:outline-none z-20 ${isMenuOpen ? "" : "hidden"}`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* Past 24h */}
          <ButtonComponent.Menu
            className={`${selectedTimeRange === TimeRangeOptions.Past24Hours ? "bg-blue90" : "hover:bg-gray95"}`}
            onClick={() => setTimeRange(TimeRangeOptions.Past24Hours)}
          >
            Past Day
          </ButtonComponent.Menu>

          {/* Today */}
          <ButtonComponent.Menu
            className={`${selectedTimeRange === TimeRangeOptions.Today ? "bg-blue90" : "hover:bg-gray95"}`}
            onClick={() => setTimeRange(TimeRangeOptions.Today)}
          >
            Today
          </ButtonComponent.Menu>
          {/* Yesterday */}
          <ButtonComponent.Menu
            className={`${selectedTimeRange === TimeRangeOptions.Yesterday ? "bg-blue90" : "hover:bg-gray95"}`}
            onClick={() => setTimeRange(TimeRangeOptions.Yesterday)}
          >
            Yesterday
          </ButtonComponent.Menu>
          {/* Past 7d */}
          <ButtonComponent.Menu
            className={`${selectedTimeRange === TimeRangeOptions.Past7d ? "bg-blue90" : "hover:bg-gray95"}`}
            onClick={() => setTimeRange(TimeRangeOptions.Past7d)}
          >
            Past Week
          </ButtonComponent.Menu>
          {/* Custom Range */}
          <div
            className={`px-4 w-full py-3 text-xs text-left ${selectedTimeRange === TimeRangeOptions.CustomRange ? "bg-blue90" : "hover:bg-gray95"}`}
          >
            <p>Custom Range</p>
            <div className="my-1">
              {isMenuOpen && (
                <DateTimePicker
                  value={start}
                  onChange={(value) => {
                    setTimeRange(TimeRangeOptions.CustomRange, {
                      autoclose: false,
                      start: value,
                    });
                  }}
                  maxDateTime={end}
                  slotProps={{ textField: { size: "small" } }}
                  closeOnSelect={true}
                  timezone={isUTC ? "UTC" : "system"}
                />
              )}
            </div>
            <p className="text-xs w-fill text-center">to</p>
            <div className="my-1">
              {isMenuOpen && (
                <DateTimePicker
                  value={end}
                  onChange={(value) => {
                    setTimeRange(TimeRangeOptions.CustomRange, {
                      autoclose: false,
                      end: value,
                    });
                  }}
                  onClose={() => setIsMenuOpen(false)}
                  minDateTime={start}
                  slotProps={{ textField: { size: "small" } }}
                  closeOnSelect={true}
                  timezone={isUTC ? "UTC" : "system"}
                />
              )}
            </div>
            <div className="inline-flex w-full divide-y mt-2 border border-gray90 rounded">
              <ButtonComponent.Option
                className={`${isUTC ? "bg-blue90" : "hover:bg-gray95 bg-white"}`}
                onClick={() => {
                  setTimeRange(TimeRangeOptions.CustomRange, { isUTC: true });
                }}
              >
                UTC
              </ButtonComponent.Option>
              <ButtonComponent.Option
                className={`${!isUTC ? "bg-blue90" : "hover:bg-gray95 bg-white"}`}
                onClick={() => {
                  setTimeRange(TimeRangeOptions.CustomRange, { isUTC: false });
                }}
              >
                Local
              </ButtonComponent.Option>
            </div>
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
};
