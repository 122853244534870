import { type Thing, useThingsApi } from "api/ingestion/things.ts";
import { DeviceTile } from "components";
import { useSelectedSiteLevel } from "context/SelectedSiteLevelContext.tsx";

import { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "images/icons/close.svg";

const FilteredDevices = ({ siteId }: { siteId: string }) => {
  const { selectedSiteLevel, setSelectedSiteLevel } = useSelectedSiteLevel();
  const [filteredDevices, setFilteredDevices] = useState<Thing[]>([]);

  const { getThingsForLevel } = useThingsApi();
  useEffect(() => {
    if (!selectedSiteLevel) return;

    getThingsForLevel(siteId, selectedSiteLevel.levelId)
      .then(setFilteredDevices)
      .catch(console.warn);
  }, [selectedSiteLevel, siteId]);

  if (filteredDevices.length === 0) {
    return (
      <div className="p-4 text-space70">
        No devices found for the selected level.
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <p className="text-space50 text-heading3">
          Devices for {selectedSiteLevel?.levelName}
        </p>
        <div
          className="text-space50 text-base cursor-pointer"
          onClick={() => setSelectedSiteLevel(null)}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        {filteredDevices.map((device) => (
          <DeviceTile device={device} key={device.thingId} />
        ))}
      </div>
    </div>
  );
};

export default FilteredDevices;
