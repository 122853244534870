import { useDataApi } from "api/data";
import { usePlacesApi } from "api/ingestion/places";
import { FleetResponse, SiteResponse } from "api/ingestion/places";
import { useThingsApi } from "api/ingestion/things";
import { ReactComponent as DeleteIcon } from "images/icons/delete.svg";

import type { SiteLevel } from "api/ingestion/places";
import React, { useEffect, useState } from "react";
import ButtonComponent from "../../components/uikit/button";

type Place = {
  id: string;
  name: string;
  type: "site" | "fleet";
  address: string;
  longitude: number;
  latitude: number;
  altitude: number;
};

const AddDeviceModal = ({ isOpen, onClose, onDeviceAdded }) => {
  const [places, setPlaces] = useState<Place[]>([]);
  const { getThingTypes } = useThingsApi();
  const [availableThingTypes, setAvailableThingTypes] = useState<string[]>([]);
  const { getThingPropertyKeys, getThingManufacturerModels } = useDataApi();
  const [formData, setFormData] = useState({
    thingName: "",
    thingType: "",
    thingDescription: "",
    address: "",
    thingManufacturerId: "",
    primaryGroup: "",
    manufacturer: "",
    model: "",
    isFirstParty: false,
    longitude: "",
    latitude: "",
    altitude: "",
    levelId: "",
    placeId: "",
    placeType: "",
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const { addDevice, getThings } = useThingsApi();
  const { getSites, getFleets, getSiteLevels } = usePlacesApi();
  const [manufacturerModels, setManufacturerModels] =
    useState<ThingManufacturerModels>({});
  const [availableManufacturers, setAvailableManufacturers] = useState<
    string[]
  >([]);
  const [availableModels, setAvailableModels] = useState<string[]>([]);
  const [properties, setProperties] = useState<Record<string, string>>({});
  const [newPropertyKey, setNewPropertyKey] = useState("");
  const [newPropertyValue, setNewPropertyValue] = useState("");
  const [availableProperties, setAvailableProperties] = useState<
    string[] | null
  >(null);

  const [attributes, setAttributes] = useState<Record<string, string>>({});
  const [newAttributeKey, setNewAttributeKey] = useState("");
  const [newAttributeValue, setNewAttributeValue] = useState("");

  const [levels, setLevels] = useState<SiteLevel[]>([]);

  const [isLocationPopulated, setIsLocationPopulated] = useState(false);

  useEffect(() => {
    const fetchPlaces = async () => {
      const [sites, fleets, things] = await Promise.all([
        getSites(),
        getFleets(),
        getThings(),
      ]);
      const combinedPlaces: Place[] = [
        ...sites.map((site) => ({
          latitude: site.latitude || 0,
          longitude: site.longitude || 0,
          altitude: site.altitude || 0,
          address: site.address,
          id: site.siteId,
          name: site.siteName,
          type: "site",
        })),
        ...fleets.map((fleet) => {
          const matchingThing = things.find(
            (thing) => thing.siteId === fleet.fleetId,
          );
          return {
            id: fleet.fleetId,
            name: fleet.fleetName,
            type: "fleet",
            address: matchingThing?.address || "",
            longitude: matchingThing?.longitude || 0,
            latitude: matchingThing?.latitude || 0,
            altitude: matchingThing?.altitude || 0,
          };
        }),
      ];
      setPlaces(combinedPlaces);
    };

    fetchPlaces();
  }, []);

  useEffect(() => {
    const fetchThingTypes = async () => {
      try {
        const types = await getThingTypes();
        setAvailableThingTypes(types);
        if (types.length > 0) {
          setFormData((prev) => ({
            ...prev,
            thingType: types[0],
          }));
        }
      } catch (error) {
        console.error("Error fetching thing types:", error);
      }
    };

    fetchThingTypes();
  }, []);

  useEffect(() => {
    const fetchManufacturerModels = async () => {
      try {
        const data = await getThingManufacturerModels();
        setManufacturerModels(data);
        if (formData.thingType && data[formData.thingType]) {
          setAvailableManufacturers(
            Object.keys(data[formData.thingType] || {}),
          );
        }
      } catch (error) {
        console.error("Error fetching manufacturer models:", error);
      }
    };

    fetchManufacturerModels();
  }, []);

  useEffect(() => {
    if (formData.thingType && manufacturerModels[formData.thingType]) {
      setAvailableManufacturers(
        Object.keys(manufacturerModels[formData.thingType] || {}),
      );
    } else {
      setAvailableManufacturers([]);
    }
  }, [formData.thingType, manufacturerModels]);

  useEffect(() => {
    if (
      formData.thingType &&
      formData.manufacturer &&
      manufacturerModels[formData.thingType]
    ) {
      setAvailableModels(
        manufacturerModels[formData.thingType][formData.manufacturer] || [],
      );
    } else {
      setAvailableModels([]);
    }
  }, [formData.thingType, formData.manufacturer, manufacturerModels]);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const keys = await getThingPropertyKeys();
        setAvailableProperties(keys[formData.thingType] || []);
      } catch (error) {
        console.error("Error fetching property keys:", error);
      }
    };

    if (formData.thingType) {
      fetchProperties();
    }
  }, [formData.thingType]);

  useEffect(() => {
    const fetchLevels = async () => {
      if (formData.placeType === "site" && formData.placeId) {
        try {
          const siteLevels = await getSiteLevels(formData.placeId);
          setLevels(siteLevels);
        } catch (error) {
          console.error("Error fetching levels:", error);
        }
      } else {
        setLevels([]);
      }
    };

    fetchLevels();
  }, [formData.placeType, formData.placeId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "placeId") {
      setIsLocationPopulated(true);
      const selectedPlace = places.find((place) => place.id === value);
      setFormData((prev) => ({
        ...prev,
        placeId: value,
        placeType: selectedPlace?.type || "",
        address: selectedPlace?.address || "",
        longitude: `${selectedPlace?.longitude}` || "",
        latitude: `${selectedPlace?.latitude}` || "",
        altitude: `${selectedPlace?.altitude}` || "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.thingName) {
      newErrors.thingName = "Name is required";
      valid = false;
    }

    if (!formData.thingType) {
      newErrors.thingType = "Type is required";
      valid = false;
    }

    if (!formData.thingDescription) {
      newErrors.thingDescription = "Description is required";
      valid = false;
    }
    if (!formData.placeId) {
      newErrors.placeId = "Place is required";
      valid = false;
    }
    if (formData.latitude === "") {
      newErrors.latitude = "Latitude is required";
      valid = false;
    }
    if (formData.longitude === "") {
      newErrors.longitude = "Longitude is required";
      valid = false;
    }

    if (formData.placeType === "site" && !formData.levelId) {
      newErrors.levelId = "Level ID is required for site devices";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const renderLevelIdField = () => {
    if (formData.placeType === "site") {
      return (
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-medium mb-1"
            htmlFor="levelId"
          >
            Level ID*
          </label>
          <select
            name="levelId"
            id="levelId"
            value={formData.levelId}
            onChange={handleChange}
            className={`border rounded w-full py-2 px-3 text-gray-700 ${
              errors.levelId ? "border-red-500" : ""
            }`}
          >
            <option value="">Select a level</option>
            {levels.map((level) => (
              <option key={level.levelId} value={level.levelId}>
                {level.levelName} ({level.levelId})
              </option>
            ))}
          </select>
          {errors.levelId && (
            <p className="text-red-500 text-xs italic">{errors.levelId}</p>
          )}
        </div>
      );
    }
    return null;
  };

  const handleAddDevice = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const deviceData = {
      ...formData,
      properties,
      attributes,
    };

    if (deviceData.placeType === "fleet") {
      delete deviceData.levelId;
    }

    try {
      const result = await addDevice(
        formData.placeType,
        formData.placeId,
        deviceData,
      );
      if (result.success) {
        onDeviceAdded(result);
        handleCloseModal();
      } else {
        setErrorMessage(
          result.error?.message ||
            (typeof result.error === "string"
              ? result.error
              : "Failed to add device. Please try again."),
        );

        if (result.error?.errors) {
          setErrors((prev) => ({
            ...prev,
            ...result.error.errors,
          }));
        }
      }
    } catch (error) {
      setErrorMessage(
        error?.message || "An unexpected error occurred. Please try again.",
      );
    }
  };

  const handleCloseModal = () => {
    setIsLocationPopulated(false);
    onClose();
    setFormData({
      thingName: "",
      thingType: "",
      thingDescription: "",
      address: "",
      thingManufacturerId: "",
      primaryGroup: "",
      manufacturer: "",
      model: "",
      isFirstParty: false,
      longitude: "",
      latitude: "",
      altitude: "",
      levelId: "",
      placeId: "",
      placeType: "",
    });
    setErrors({});
    setErrorMessage("");
  };

  const handlePropertyChange = (key: string, value: string) => {
    setProperties((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleDeleteProperty = (key: string) => {
    setProperties((prev) => {
      const newProps = { ...prev };
      delete newProps[key];
      return newProps;
    });
  };

  const handleAddProperty = () => {
    if (!newPropertyKey || !newPropertyValue) return;

    setProperties((prev) => ({
      ...prev,
      [newPropertyKey]: newPropertyValue,
    }));
    setNewPropertyKey("");
    setNewPropertyValue("");
  };

  const handleAttributeChange = (key: string, value: string) => {
    setAttributes((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleDeleteAttribute = (key: string) => {
    setAttributes((prev) => {
      const newAttrs = { ...prev };
      delete newAttrs[key];
      return newAttrs;
    });
  };

  const handleAddAttribute = () => {
    if (!newAttributeKey || !newAttributeValue) return;

    if (Object.keys(attributes).includes(newAttributeKey)) {
      setErrorMessage("Attribute key already exists");
      return;
    }

    setAttributes((prev) => ({
      ...prev,
      [newAttributeKey]: newAttributeValue,
    }));
    setNewAttributeKey("");
    setNewAttributeValue("");
    setErrorMessage("");
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
      <div className="bg-white p-6 rounded-lg shadow-lg w-[800px]">
        <h2 className="text-xl mb-4">Add Device</h2>
        <form onSubmit={handleAddDevice}>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Place*
              </label>
              <select
                name="placeId"
                value={formData.placeId}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.placeId ? "border-red-500" : "border-gray-300"
                }`}
              >
                <option value="">Select a place</option>
                <optgroup label="Sites">
                  {places
                    .filter((p) => p.type === "site")
                    .map((place) => (
                      <option key={place.id} value={place.id}>
                        {place.name}
                      </option>
                    ))}
                </optgroup>
                <optgroup label="Fleets">
                  {places
                    .filter((p) => p.type === "fleet")
                    .map((place) => (
                      <option key={place.id} value={place.id}>
                        {place.name}
                      </option>
                    ))}
                </optgroup>
              </select>
              {errors.placeId && (
                <p className="text-red-500 text-xs mt-1">{errors.placeId}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type*
              </label>
              <select
                name="thingType"
                value={formData.thingType}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.thingType ? "border-red-500" : "border-gray-300"
                }`}
              >
                <option value="">Select Type</option>
                {availableThingTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              {errors.thingType && (
                <p className="text-red-500 text-xs mt-1">{errors.thingType}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name*
              </label>
              <input
                type="text"
                name="thingName"
                value={formData.thingName}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.thingName ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.thingName && (
                <p className="text-red-500 text-xs mt-1">{errors.thingName}</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description*
              </label>
              <input
                type="text"
                name="thingDescription"
                value={formData.thingDescription}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.thingDescription ? "border-red-500" : "border-gray-300"
                }`}
              />
              {errors.thingDescription && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.thingDescription}
                </p>
              )}
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address
              </label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm border-gray-300 ${isLocationPopulated ? "bg-gray-50" : ""}`}
              />
            </div>
          </div>

          <div className="flex gap-6 mb-4">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                name="isFirstParty"
                checked={formData.isFirstParty}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: "isFirstParty",
                      value: e.target.checked,
                    },
                  });
                  if (e.target.checked) {
                    setFormData((prev) => ({
                      ...prev,
                      manufacturer: "",
                      model: "",
                    }));
                  }
                }}
                className="h-4 w-4 text-blue-600 rounded border-gray-300"
              />
              <span className="text-sm font-medium text-gray-700">
                First Party
              </span>
            </label>

            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                name="isSimulated"
                checked={formData.isSimulated}
                onChange={handleChange}
                className="h-4 w-4 text-blue-600 rounded border-gray-300"
              />
              <span className="text-sm font-medium text-gray-700">
                Simulated
              </span>
            </label>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Manufacturer
              </label>
              {formData.isFirstParty &&
              formData.thingType &&
              manufacturerModels[formData.thingType] &&
              Object.keys(manufacturerModels[formData.thingType]).length > 0 ? (
                <select
                  name="manufacturer"
                  value={formData.manufacturer}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2 text-sm border-gray-300"
                >
                  <option value="">Select Manufacturer</option>
                  {availableManufacturers.map((manufacturer) => (
                    <option key={manufacturer} value={manufacturer}>
                      {manufacturer}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  name="manufacturer"
                  value={formData.manufacturer}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2 text-sm border-gray-300"
                />
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Model
              </label>
              {formData.isFirstParty &&
              formData.thingType &&
              manufacturerModels[formData.thingType] &&
              Object.keys(manufacturerModels[formData.thingType]).length > 0 ? (
                <select
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2 text-sm border-gray-300 disabled:bg-gray-100"
                  disabled={!formData.manufacturer}
                >
                  <option value="">Select Model</option>
                  {availableModels.map((model) => (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  name="model"
                  value={formData.model}
                  onChange={handleChange}
                  className="w-full border rounded px-3 py-2 text-sm border-gray-300"
                />
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Manufacturer ID
              </label>
              <input
                type="text"
                name="thingManufacturerId"
                value={formData.thingManufacturerId}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.thingManufacturerId
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
              />
              {errors.thingManufacturerId && (
                <p className="text-red-500 text-xs mt-1">
                  {errors.thingManufacturerId}
                </p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <label
                className="block text-sm font-medium text-gray-700 mb-1"
                htmlFor="latitude"
              >
                Latitude*
              </label>
              <input
                type="number"
                name="latitude"
                id="latitude"
                value={formData.latitude}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.latitude ? "border-red-500" : "border-gray-300"
                } ${isLocationPopulated ? "bg-gray-50" : ""}`}
              />
              {errors.latitude && (
                <p className="text-red-500 text-xs mt-1">{errors.latitude}</p>
              )}
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700 mb-1"
                htmlFor="longitude"
              >
                Longitude*
              </label>
              <input
                type="number"
                name="longitude"
                id="longitude"
                value={formData.longitude}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.longitude ? "border-red-500" : "border-gray-300"
                } ${isLocationPopulated ? "bg-gray-50" : ""}`}
              />
              {errors.longitude && (
                <p className="text-red-500 text-xs mt-1">{errors.longitude}</p>
              )}
            </div>

            <div>
              <label
                className="block text-sm font-medium text-gray-700 mb-1"
                htmlFor="altitude"
              >
                Altitude*
              </label>
              <input
                type="number"
                name="altitude"
                id="altitude"
                value={formData.altitude}
                onChange={handleChange}
                className={`w-full border rounded px-3 py-2 text-sm ${
                  errors.altitude ? "border-red-500" : "border-gray-300"
                } ${isLocationPopulated ? "bg-gray-50" : ""}`}
              />
              {errors.altitude && (
                <p className="text-red-500 text-xs mt-1">{errors.altitude}</p>
              )}
            </div>
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-1"
              htmlFor="primaryGroup"
            >
              Primary Group
            </label>
            <input
              type="text"
              name="primaryGroup"
              id="primaryGroup"
              value={formData.primaryGroup}
              onChange={handleChange}
              className="w-full border rounded px-3 py-2 text-sm"
            />
          </div>

          {renderLevelIdField()}

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Properties
              </label>
              <div className="border rounded-lg">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50 border-b">
                      <th className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Property
                      </th>
                      <th className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Value
                      </th>
                      <th className="w-10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(properties).map(([key, value]) => (
                      <tr key={key} className="border-b">
                        <td className="py-1.5 px-3 text-sm">{key}</td>
                        <td className="py-1.5 px-3">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handlePropertyChange(key, e.target.value)
                            }
                            className="w-full border rounded px-2 py-1 text-sm"
                          />
                        </td>
                        <td className="py-1.5 px-2 text-center">
                          <ButtonComponent
                            type="button"
                            onClick={() => handleDeleteProperty(key)}
                            className="text-red-600"
                          >
                            <DeleteIcon className="h-4 w-4" />
                          </ButtonComponent>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="py-1.5 px-3">
                        <select
                          value={newPropertyKey}
                          onChange={(e) => setNewPropertyKey(e.target.value)}
                          className="w-full border rounded px-2 py-1 text-sm"
                          disabled={!availableProperties}
                        >
                          <option value="">
                            {!availableProperties
                              ? "Loading..."
                              : "Select Property"}
                          </option>
                          {availableProperties
                            ?.filter(
                              (key) => !Object.keys(properties).includes(key),
                            )
                            .map((key) => (
                              <option key={key} value={key}>
                                {key}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td className="py-1.5 px-3">
                        <input
                          type="text"
                          value={newPropertyValue}
                          onChange={(e) => setNewPropertyValue(e.target.value)}
                          placeholder="Value"
                          className="w-full border rounded px-2 py-1 text-sm"
                        />
                      </td>
                      <td className="py-1.5 px-2 text-center">
                        <ButtonComponent.Default
                          type="button"
                          variant="filled"
                          buttonStyle="primary"
                          onClick={handleAddProperty}
                          disabled={!newPropertyKey || !newPropertyValue}
                          className="disabled:bg-gray-300 !px-2 !py-1 !text-sm !rounded border-0 focus:!ring-0"
                        >
                          Add
                        </ButtonComponent.Default>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Attributes
              </label>
              <div className="border rounded-lg">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50 border-b">
                      <th className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Attribute
                      </th>
                      <th className="py-2 px-3 text-left text-xs font-medium text-gray-500 uppercase">
                        Value
                      </th>
                      <th className="w-10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(attributes).map(([key, value]) => (
                      <tr key={key} className="border-b">
                        <td className="py-1.5 px-3 text-sm">{key}</td>
                        <td className="py-1.5 px-3">
                          <input
                            type="text"
                            value={value}
                            onChange={(e) =>
                              handleAttributeChange(key, e.target.value)
                            }
                            className="w-full border rounded px-2 py-1 text-sm"
                          />
                        </td>
                        <td className="py-1.5 px-2 text-center">
                          <ButtonComponent
                            type="button"
                            onClick={() => handleDeleteAttribute(key)}
                            className="text-red-600"
                          >
                            <DeleteIcon className="h-4 w-4" />
                          </ButtonComponent>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="py-1.5 px-3">
                        <input
                          type="text"
                          value={newAttributeKey}
                          onChange={(e) => setNewAttributeKey(e.target.value)}
                          placeholder="Enter attribute name"
                          className="w-full border rounded px-2 py-1 text-sm"
                        />
                      </td>
                      <td className="py-1.5 px-3">
                        <input
                          type="text"
                          value={newAttributeValue}
                          onChange={(e) => setNewAttributeValue(e.target.value)}
                          placeholder="Value"
                          className="w-full border rounded px-2 py-1 text-sm"
                        />
                      </td>
                      <td className="py-1.5 px-2 text-center">
                        <ButtonComponent.Default
                          variant="filled"
                          buttonStyle="primary"
                          onClick={handleAddAttribute}
                          disabled={!newAttributeKey || !newAttributeValue}
                          className="disabled:bg-gray-300 !px-2 !py-1 !text-sm !rounded border-0 focus:!ring-0"
                        >
                          Add
                        </ButtonComponent.Default>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {errorMessage && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
              <p className="text-red-600 text-sm">{errorMessage}</p>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <ButtonComponent.Default
              type="button"
              variant="outline"
              buttonStyle="default"
              onClick={onClose}
              className="text-sm font-medium focus:!ring-0"
            >
              Cancel
            </ButtonComponent.Default>
            <ButtonComponent.Default
              type="submit"
              variant="filled"
              buttonStyle="primary"
              className="text-sm font-medium focus:!ring-0"
            >
              Add Device
            </ButtonComponent.Default>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default AddDeviceModal;
