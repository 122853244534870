import type { FleetResponse, SiteResponse } from "api/ingestion/places";
import type { Thing } from "api/ingestion/things";
import { DeviceTile } from "components";

type PlaceDetails = (SiteResponse | FleetResponse) & { devices: Thing[] };

const PlaceInformation = ({
  id,
  name,
  address,
  devices,
}: {
  id: string;
  name: string;
  address?: string;
  devices: Thing[];
}) => (
  <div className="flex flex-col gap-2">
    <div>
      <p className="text-heading3 text-space50">{name}</p>
      {address && <p className="text-caption mt-0 text-space70">{address}</p>}
    </div>
    <div className="flex flex-wrap gap-4">
      {devices.map((device) => (
        <DeviceTile device={device} key={device.thingId} />
      ))}
    </div>
  </div>
);

const DeviceTypeSection = ({
  thingType,
  devices,
}: {
  thingType: string;
  devices: Thing[];
}) => (
  <>
    <p className="text-heading2 text-space50">{thingType}</p>
    <div className="flex flex-col p-4 gap-4 elevation-1 rounded-md mb-2">
      <div className="flex flex-wrap gap-4">
        {devices.map((device) => (
          <DeviceTile device={device} key={device.thingId} />
        ))}
      </div>
    </div>
  </>
);

const DevicesByType = ({
  deviceTypes = [],
  devices = [],
}: {
  places: (SiteResponse | FleetResponse)[];
  deviceTypes: string[];
  devices: Thing[];
}) => {
  if (!devices.length || !deviceTypes.length) return null;

  const groupedDevices = deviceTypes.map((deviceType) => {
    const devicesOfType = devices.filter(
      (device) => device.thingType === deviceType,
    );

    return {
      thingType: deviceType,
      devices: devicesOfType,
    };
  });

  return (
    <div className="flex flex-col gap-3">
      {groupedDevices
        .filter((group) => group.devices.length > 0)
        .map((group) => (
          <DeviceTypeSection {...group} key={group.thingType} />
        ))}
    </div>
  );
};

export { DevicesByType };
