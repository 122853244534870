import { usePlacesApi } from "api/ingestion/places.ts";
import React, { useState, useEffect, useMemo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MapView } from "../locationSelection/mapView";

const FLEET_STATUS_DATA = [
  { name: "Deployed", value: 691, percentage: 56, color: "#1E3A8A" },
  { name: "Charging", value: 320, percentage: 26, color: "#93C5FD" },
  { name: "Out Of Service", value: 148, percentage: 12, color: "#FCA5A5" },
  { name: "Offline", value: 74, percentage: 6, color: "#D1D5DB" },
];

const SITES_STATUS_DATA = [
  { name: "Nominal", value: 789, percentage: 59, color: "#1E3A8A" },
  { name: "Out Of Service", value: 789, percentage: 7, color: "#FCA5A5" },
];

const REVENUE_DATA = [
  { name: "1", value: 2000 },
  { name: "2", value: 2200 },
  { name: "3", value: 2400 },
  { name: "4", value: 2600 },
  { name: "5", value: 2800 },
  { name: "6", value: 3000 },
  { name: "7", value: 3200 },
  { name: "8", value: 3400 },
  { name: "9", value: 3600 },
  { name: "10", value: 3800 },
  { name: "11", value: 4000 },
  { name: "12", value: 4200 },
];

const TRANSACTION_DATA = [
  { name: "1", chargingRevenue: 1800, chargingCost: 1200 },
  { name: "2", chargingRevenue: 2000, chargingCost: 1300 },
  { name: "3", chargingRevenue: 2200, chargingCost: 1400 },
  { name: "4", chargingRevenue: 2400, chargingCost: 1500 },
  { name: "5", chargingRevenue: 2600, chargingCost: 1600 },
  { name: "6", chargingRevenue: 2800, chargingCost: 1700 },
  { name: "7", chargingRevenue: 3000, chargingCost: 1800 },
  { name: "8", chargingRevenue: 3200, chargingCost: 1900 },
  { name: "9", chargingRevenue: 3400, chargingCost: 2000 },
  { name: "10", chargingRevenue: 3600, chargingCost: 2100 },
  { name: "11", chargingRevenue: 3800, chargingCost: 2200 },
  { name: "12", chargingRevenue: 4000, chargingCost: 2300 },
];

const TRANSACTIONS = [
  {
    transactionId: "XX123456",
    userId: "US987654",
    site: "HUB_DL1234",
    removedBatteryId: "BAT_R98765",
    installedBatteryId: "BAT_I12345",
    installedBatteryChargeState: "92%",
    revenue: "345 Rp",
    date: "08/17/2022",
    time: "14:25:32",
    paymentMethod: "Credit Card",
  },
  {
    transactionId: "XX789012",
    userId: "US456789",
    site: "HUB_NY5678",
    removedBatteryId: "BAT_R54321",
    installedBatteryId: "BAT_I67890",
    installedBatteryChargeState: "87%",
    revenue: "420 Rp",
    date: "08/18/2022",
    time: "09:15:47",
    paymentMethod: "Debit Card",
  },
  {
    transactionId: "XX345678",
    userId: "US123789",
    site: "HUB_SF9012",
    removedBatteryId: "BAT_R13579",
    installedBatteryId: "BAT_I24680",
    installedBatteryChargeState: "95%",
    revenue: "380 Rp",
    date: "08/19/2022",
    time: "16:40:18",
    paymentMethod: "Mobile Wallet",
  },
  {
    transactionId: "XX901234",
    userId: "US567890",
    site: "HUB_CH3456",
    removedBatteryId: "BAT_R24680",
    installedBatteryId: "BAT_I13579",
    installedBatteryChargeState: "89%",
    revenue: "400 Rp",
    date: "08/20/2022",
    time: "11:30:55",
    paymentMethod: "Cash",
  },
  {
    transactionId: "XX567890",
    userId: "US234567",
    site: "HUB_LA7890",
    removedBatteryId: "BAT_R11111",
    installedBatteryId: "BAT_I22222",
    installedBatteryChargeState: "91%",
    revenue: "360 Rp",
    date: "08/21/2022",
    time: "13:20:40",
    paymentMethod: "Credit Card",
  },
  {
    transactionId: "XX234567",
    userId: "US890123",
    site: "HUB_BOS678",
    removedBatteryId: "BAT_R33333",
    installedBatteryId: "BAT_I44444",
    installedBatteryChargeState: "88%",
    revenue: "390 Rp",
    date: "08/22/2022",
    time: "10:05:22",
    paymentMethod: "Debit Card",
  },
  {
    transactionId: "XX890123",
    userId: "US345678",
    site: "HUB_SEA901",
    removedBatteryId: "BAT_R55555",
    installedBatteryId: "BAT_I66666",
    installedBatteryChargeState: "93%",
    revenue: "410 Rp",
    date: "08/23/2022",
    time: "15:50:33",
    paymentMethod: "Mobile Wallet",
  },
  {
    transactionId: "XX456789",
    userId: "US901234",
    site: "HUB_ATL234",
    removedBatteryId: "BAT_R77777",
    installedBatteryId: "BAT_I88888",
    installedBatteryChargeState: "86%",
    revenue: "370 Rp",
    date: "08/24/2022",
    time: "08:45:10",
    paymentMethod: "Credit Card",
  },
  {
    transactionId: "XX012345",
    userId: "US678901",
    site: "HUB_MIA567",
    removedBatteryId: "BAT_R99999",
    installedBatteryId: "BAT_I00000",
    installedBatteryChargeState: "90%",
    revenue: "430 Rp",
    date: "08/25/2022",
    time: "17:30:05",
    paymentMethod: "Cash",
  },
  {
    transactionId: "XX678901",
    userId: "US012345",
    site: "HUB_DEN890",
    removedBatteryId: "BAT_R12121",
    installedBatteryId: "BAT_I23232",
    installedBatteryChargeState: "94%",
    revenue: "385 Rp",
    date: "08/26/2022",
    time: "12:10:15",
    paymentMethod: "Debit Card",
  },
];

const Dashboard = () => {
  const [locations, setLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { getSites, getFleets } = usePlacesApi();
  useEffect(() => {
    getSites().then(setLocations);
  }, []);

  // const handleAddSite = async (siteData) => {
  //   try {
  //     const result = await addSite(siteData);
  //     return { success: true };
  //   } catch (error) {
  //     return { success: false, error: error.message };
  //   }
  // };

  return (
    <div className="flex w-full min-h-screen bg-gray-100 p-4">
      <div className="flex-1">
        <div className="w-full bg-yellow-50 rounded-lg shadow-sm p-4 mb-6">
          <p className="text-space50 text-heading3">
            Demo Environment - Synthetic Financial Data Only
          </p>
        </div>
        <PageHeader />
        <SummarySection locations={locations} />
        <StatisticsSection />
        <div className="flex space-x-4 mt-6">
          <div className="w-1/2">
            <RevenueChart data={REVENUE_DATA} />
          </div>
          <div className="w-1/2">
            <TransactionChart data={TRANSACTION_DATA} />
          </div>
        </div>
        <TransactionsTable
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
    </div>
  );
};

const PageHeader = () => (
  <div className="flex justify-between items-center pt-4 pb-5">
    <div className="text-heading1 text-space50">Performance Overview</div>
  </div>
);

const SummarySection = ({ locations }) => (
  <div className="flex space-x-4">
    <FleetSummaryCard
      title="Fleet Summary"
      data={FLEET_STATUS_DATA}
      total={1234}
    />
    <SitesSummaryCard
      title="Sites Summary"
      data={SITES_STATUS_DATA}
      total={1234}
    />
    <div className="flex-1 bg-white rounded-lg shadow-md p-6">
      <MapView locations={locations} />
    </div>
  </div>
);

const FleetSummaryCard = ({ title, data, total }) => (
  <div className="flex-1 bg-white rounded-lg shadow-md p-6">
    <span className="mb-4 text-lg font-semibold">{title}</span>
    <div className="flex">
      <div className="w-1/3">
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Pie
              dataKey="value"
              data={data}
              outerRadius={"90%"}
              innerRadius={"70%"}
              startAngle={90}
              endAngle={-270}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="central"
              className="text-xl font-semibold"
            >
              {total}
            </text>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-2/3 pl-8">
        {data.map((entry, index) => (
          <div key={index} className="flex items-center mb-2">
            <div
              className="w-4 h-4 rounded-full mr-2"
              style={{ backgroundColor: entry.color }}
            ></div>
            <span className="flex-grow">{entry.name}</span>
            <span className="mr-4">{entry.percentage}%</span>
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const SitesSummaryCard = ({ title, data, total }) => (
  <div className="flex-1 bg-white rounded-lg shadow-md p-6">
    <span className="mb-4 text-lg font-semibold">{title}</span>
    <div className="flex">
      <div className="w-1/3">
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Pie
              dataKey="value"
              data={data}
              outerRadius={"90%"}
              innerRadius={"70%"}
              startAngle={90}
              endAngle={-270}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <text
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="central"
              className="text-xl font-semibold"
            >
              {total}
            </text>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="w-2/3 pl-8">
        {data.map((entry, index) => (
          <div key={index} className="flex items-center mb-2">
            <div
              className="w-4 h-4 rounded-full mr-2"
              style={{ backgroundColor: entry.color }}
            ></div>
            <span className="flex-grow">{entry.name}</span>
            <span className="mr-4">{entry.percentage}%</span>
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const RevenueChart = ({ data }) => (
  <div className="h-full p-6 bg-white rounded-lg shadow-md">
    <div className="flex justify-between items-start mb-4">
      <div className="flex items-center space-x-2">
        <h2 className="text-xl font-semibold text-gray-700">Revenue</h2>
      </div>
      <div className="flex items-center space-x-2">
        <div className="text-3xl font-bold text-gray-800">34,567 Rp</div>
        <ChangeIndicator change={2} />
      </div>
    </div>
    <div className="mt-4 h-64">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip />
          <Bar dataKey="value" fill="#60A5FA" radius={[4, 4, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
);

const TransactionChart = ({ data }) => (
  <div className="h-full bg-white rounded-lg shadow-md p-6">
    <div className="flex justify-between items-start mb-4">
      <h2 className="text-xl font-semibold text-gray-700">Transactions</h2>
      <div className="flex items-center space-x-2">
        <div className="text-3xl font-bold text-gray-800">34,567 Rp</div>
        <ChangeIndicator change={2} />
      </div>
    </div>
    <div className="h-64">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} barGap={0}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip />
          <Bar dataKey="chargingRevenue" fill="#60A5FA" radius={[4, 4, 0, 0]} />
          <Bar dataKey="chargingCost" fill="#1E3A8A" radius={[4, 4, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
);

const StatCard = ({ title, value, unit, change }) => (
  <div className="p-6">
    <div className="flex justify-between items-start">
      <div>
        <div className="text-3xl font-bold text-gray-800">
          {value} {unit && <span className="text-lg font-normal">{unit}</span>}
        </div>
        <div className="text-sm text-gray-500 mt-1">{title}</div>
      </div>
      <ChangeIndicator change={change} />
    </div>
  </div>
);

const ChangeIndicator = ({ change }) => (
  <div
    className={`inline-flex items-center text-xs px-2 py-0.5 rounded ${change >= 0 ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"}`}
  >
    {Math.abs(change)}%
    <svg className="w-3 h-3 ml-0.5" fill="currentColor" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d={
          change >= 0
            ? "M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
            : "M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z"
        }
        clipRule="evenodd"
      ></path>
    </svg>
  </div>
);

const StatisticsSection = () => (
  <div className="mt-6">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-semibold text-gray-800">
        Summary vs. Previous Cycle
      </h2>
      <div className="flex space-x-1">
        <button className="px-3 py-1 text-xs font-medium bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50">
          1D
        </button>
        <button className="px-3 py-1 text-xs font-medium bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50">
          1W
        </button>
        <button className="px-3 py-1 text-xs font-medium bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50">
          1M
        </button>
        <button className="px-3 py-1 text-xs font-medium bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50">
          3M
        </button>
        <button className="px-3 py-1 text-xs font-medium bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50">
          6M
        </button>
        <button className="px-3 py-1 text-xs font-medium bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50">
          YTD
        </button>
        <button className="px-3 py-1 text-xs font-medium bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50">
          1Y
        </button>
        <button className="px-3 py-1 text-xs font-medium bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50">
          ALL
        </button>
      </div>
    </div>
    <div className="text-sm text-gray-600 mb-4">Summary</div>
    <div className="bg-white rounded-lg shadow-sm">
      <div className="grid grid-cols-4 divide-x">
        <StatCard title="Battery Swaps" value="2,912" change={2} />
        <StatCard title="Distributed" value="34,567" unit="kwh" change={2} />
        <StatCard
          title="Average Charge time to 100%"
          value="184"
          unit="min"
          change={2}
        />
        <StatCard
          title="Unique Active Customers"
          value="13,678"
          unit="persons"
          change={2}
        />
      </div>
    </div>
  </div>
);

const TransactionsTable = ({ searchTerm, setSearchTerm }) => {
  const filteredTransactions = useMemo(() => {
    return TRANSACTIONS.filter((transaction) =>
      Object.values(transaction).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm]);

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Transactions</h2>
        <div className="relative">
          <input
            type="text"
            placeholder="Search"
            className="pl-8 pr-2 py-1 border rounded-md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg
            className="w-4 h-4 absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Transaction ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                User ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Site
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Removed Battery ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Installed Battery ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Installed Battery Charge State
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Revenue (Rp)
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Time
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Payment Method
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredTransactions.map((transaction, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.transactionId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.userId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.site}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.removedBatteryId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.installedBatteryId}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.installedBatteryChargeState}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.revenue}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.date}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.time}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {transaction.paymentMethod}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
